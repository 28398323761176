var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "laskutus-nayta" },
    [
      _c(
        "ViewTopBarBase",
        { attrs: { title: _vm.routeTitle } },
        [
          _c("v-menu", {
            attrs: { bottom: "", left: "", "nudge-bottom": "40" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        { attrs: { color: "success", depressed: "" } },
                        on
                      ),
                      [
                        _vm._v(" Toiminnot "),
                        _c("v-icon", { attrs: { right: "" } }, [
                          _vm._v(" more_vert "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function () {
                  return [
                    _c(
                      "v-list",
                      [
                        _vm.$permission.checkSinglePerm(
                          "onlinerestapi.onlinerestapi_lasku_ohisuoritus"
                        )
                          ? _c(
                              "v-list-item",
                              {
                                attrs: { disabled: !_vm.ohisuoritusEnabled },
                                on: { click: _vm.avaaDialogOhisuoritus },
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("redo")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(" Ilmoita ohisuoritus "),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$permission.checkSinglePerm(
                          "onlinerestapi.onlinerestapi_viesti_lahetys"
                        )
                          ? _c(
                              "v-list-item",
                              { on: { click: _vm.avaaDialogViestit } },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("comment")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(" Lähetä viesti "),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item",
                          { on: { click: _vm.avaaDialogMuistiinpanot } },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("anitta-icon-note")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(" Kirjoita muistiinpano "),
                            ]),
                          ],
                          1
                        ),
                        _vm.$permission.checkSinglePerm(
                          "onlinerestapi.onlinerestapi_lasku_laskukopio"
                        )
                          ? _c(
                              "v-list-item",
                              { on: { click: _vm.avaaDialogLaskukopio } },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [
                                    _c("v-icon", [
                                      _vm._v("anitta-icon-invoice"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(" Lähetä laskukopio "),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item",
                          { on: { click: _vm.avaaDialogLiitteet } },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("attach_file")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(" Lisää liitteitä "),
                            ]),
                          ],
                          1
                        ),
                        _vm.$permission.checkSinglePerm(
                          "onlinerestapi.onlinerestapi_lasku_muokkaa"
                        )
                          ? _c(
                              "v-list-item",
                              {
                                attrs: { disabled: !_vm.laskuOnMuokattavissa },
                                on: { click: _vm.siirryLaskunMuokkaukseen },
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("edit")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(" Muokkaa laskua "),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item",
                          {
                            attrs: { disabled: !_vm.laskuOnHyvitettavissa },
                            on: { click: _vm.avaaDialogHyvita },
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("euro")])],
                              1
                            ),
                            _c("v-list-item-title", [_vm._v(" Hyvitä ")]),
                          ],
                          1
                        ),
                        _vm.$permission.checkSinglePerm(
                          "onlinerestapi.onlinerestapi_lasku_mitatoi"
                        )
                          ? _c(
                              "v-list-item",
                              {
                                attrs: { disabled: !_vm.laskuOnMitatoitavissa },
                                on: { click: _vm.avaaDialogMitatoi },
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("cancel")])],
                                  1
                                ),
                                _c("v-list-item-title", [_vm._v(" Mitätöi ")]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$permission.checkSinglePerm(
                          "onlinerestapi.onlinerestapi_lasku_perinnanesto"
                        )
                          ? [
                              _vm.laskutusNayta.lasku.onko_perinnanesto_paalla
                                ? _c(
                                    "v-list-item",
                                    { on: { click: _vm.salliPerinta } },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        [_c("v-icon", [_vm._v("forward")])],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v(" Salli perintä "),
                                      ]),
                                    ],
                                    1
                                  )
                                : _c("v-tooltip", {
                                    attrs: {
                                      "open-delay": "100",
                                      bottom: "",
                                      disabled:
                                        !_vm.laskutusNayta.lasku
                                          .esta_perinta_tarkistukset.length,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                _vm._g({}, on),
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      attrs: {
                                                        disabled: Boolean(
                                                          _vm.laskutusNayta
                                                            .lasku
                                                            .esta_perinta_tarkistukset
                                                            .length
                                                        ),
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.avaaDialogPerinnanEsto,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-avatar",
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("block"),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          " Estä perintä "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "default",
                                          fn: function () {
                                            return [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "tooltip-hover",
                                                },
                                                [
                                                  _vm._v(
                                                    " Laskua ei voi siirtää perintään, koska: "
                                                  ),
                                                  _c(
                                                    "ul",
                                                    _vm._l(
                                                      _vm.laskutusNayta.lasku
                                                        .esta_perinta_tarkistukset,
                                                      function (
                                                        estosyy,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "li",
                                                          { key: index },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  estosyy
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      723820243
                                    ),
                                  }),
                            ]
                          : _vm._e(),
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              disabled:
                                !_vm.laskutusNayta.lasku
                                  .onko_erapaivan_siirto_mahdollinen,
                            },
                            on: { click: _vm.avaaDialogErapaivansiirto },
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("date_range")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(" Siirrä eräpäivää "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm.tietueLataamassa
        ? _c("Spinner")
        : _vm.tietueLatausEpaonnistunut
        ? _c("CardPahoittelu")
        : [
            _c(
              "v-container",
              { staticClass: "pa-2", attrs: { fluid: "" } },
              [
                _c(
                  "div",
                  { staticClass: "pa-4" },
                  [
                    _c("TietueenPerustiedotCard", {
                      attrs: {
                        "card-class": _vm.laskutusNayta.lasku.onko_myohassa
                          ? "yellow lighten-5"
                          : null,
                        paamies: _vm.laskutusNayta.lasku.paamies,
                        vastapuoli: _vm.laskutusNayta.lasku.asiakas,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "vastaanottaja",
                          fn: function () {
                            return [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-0 font-weight-bold grey--text darken-2",
                                },
                                [_vm._v(" Asiakas ")]
                              ),
                              _vm.eiOikeuttaNahdaAsiakastaLasku(
                                _vm.laskutusNayta.lasku
                              )
                                ? [
                                    _c("p", { staticClass: "font-italic" }, [
                                      _vm._v(
                                        " Ei voida näyttää tietosuojasyistä "
                                      ),
                                    ]),
                                  ]
                                : _vm.trafiLupaNaytaVainNimiLasku(
                                    _vm.laskutusNayta.lasku
                                  )
                                ? [
                                    _c("h3", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.laskutusNayta.lasku.asiakas.nimi
                                        ) + " "
                                      ),
                                      _vm.laskutusNayta.lasku.asiakas.nro
                                        ? _c("span", [
                                            _vm._v(
                                              "(" +
                                                _vm._s(
                                                  _vm.laskutusNayta.lasku
                                                    .asiakas.nro
                                                ) +
                                                ")"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]
                                : [
                                    _c(
                                      "h3",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "inlineList__link",
                                            attrs: {
                                              to: {
                                                name: "laskutus-asiakas-nayta",
                                                params: {
                                                  id: _vm.laskutusNayta.lasku
                                                    .asiakas.id,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.laskutusNayta.lasku
                                                    .asiakas.nimi
                                                ) +
                                                " "
                                            ),
                                            _vm.laskutusNayta.lasku.asiakas.nro
                                              ? _c("span", [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm.laskutusNayta.lasku
                                                          .asiakas.nro
                                                      ) +
                                                      ")"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.laskutusNayta.lasku.asiakas.postiosoite
                                      ? _c("OsoiteKappale", {
                                          class: _vm.tiivisNakyma
                                            ? "mb-0"
                                            : "mb-2",
                                          attrs: {
                                            postiosoite:
                                              _vm.laskutusNayta.lasku.asiakas
                                                .postiosoite.osoite,
                                            postinumero:
                                              _vm.laskutusNayta.lasku.asiakas
                                                .postiosoite.numero,
                                            postitoimipaikka:
                                              _vm.laskutusNayta.lasku.asiakas
                                                .postiosoite.toimipaikka,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.laskutusNayta.lasku.asiakas.tunnus
                                      ? _c("p", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.tunnustyyppi) +
                                              ": " +
                                              _vm._s(
                                                _vm.laskutusNayta.lasku.asiakas
                                                  .tunnus
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.laskutusNayta.lasku.asiakas
                                      .emailosoite &&
                                    _vm.laskutusNayta.lasku.asiakas.emailosoite
                                      .email
                                      ? _c("p", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.laskutusNayta.lasku.asiakas
                                                  .emailosoite.email
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.laskutusNayta.lasku.asiakas
                                      .verkkolaskuosoite &&
                                    _vm.laskutusNayta.lasku.asiakas
                                      .verkkolaskuosoite.verkkolaskuosoite
                                      ? _c("p", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            " Verkkolaskuosoite: " +
                                              _vm._s(
                                                _vm.laskutusNayta.lasku.asiakas
                                                  .verkkolaskuosoite
                                                  .verkkolaskuosoite
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "col2",
                          fn: function () {
                            return [
                              _c(
                                "DefList",
                                { attrs: { bordered: true } },
                                [
                                  _c("DefListItem", {
                                    attrs: {
                                      "label-class":
                                        "defList__label--emphasized",
                                      "value-class": "font-weight-bold",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [_vm._v(" Laskunumero ")]
                                          },
                                          proxy: true,
                                        },
                                        _vm.laskutusNayta.lasku.nro
                                          ? {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.laskutusNayta.lasku
                                                          .nro
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("DefListItem", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [_vm._v(" Tila ")]
                                          },
                                          proxy: true,
                                        },
                                        _vm.laskutusNayta.lasku.tila &&
                                        _vm.laskutusNayta.lasku.ikoni_ja_vari
                                          ? {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _c("StatusText", {
                                                    attrs: {
                                                      color:
                                                        _vm.laskutusNayta.lasku
                                                          .ikoni_ja_vari.vari,
                                                      text: _vm.laskutusNayta
                                                        .lasku.tila.selite,
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm.laskutusNayta.lasku.toimeksianto
                                    ? _c("DefListItem", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _vm._v(" Toimeksianto "),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "inlineList__link",
                                                      attrs: {
                                                        to: {
                                                          name: "perinta-nayta",
                                                          params: {
                                                            id: _vm
                                                              .laskutusNayta
                                                              .lasku
                                                              .toimeksianto,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          on: {
                                                            contextmenu:
                                                              function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.avaaMenuContext(
                                                                  $event,
                                                                  {
                                                                    id: _vm
                                                                      .laskutusNayta
                                                                      .lasku
                                                                      .toimeksianto,
                                                                  },
                                                                  "perinta-nayta"
                                                                )
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.laskutusNayta
                                                                .lasku
                                                                .toimeksianto
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3649122482
                                        ),
                                      })
                                    : _vm._e(),
                                  _c("DefListItem", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [_vm._v(" Laskupäivä ")]
                                          },
                                          proxy: true,
                                        },
                                        _vm.laskutusNayta.lasku.laskupaiva
                                          ? {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$date(
                                                          _vm.laskutusNayta
                                                            .lasku.laskupaiva
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("DefListItem", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [_vm._v(" Eräpäivä ")]
                                          },
                                          proxy: true,
                                        },
                                        _vm.laskutusNayta.lasku.erapaiva
                                          ? {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _vm.laskutusNayta.lasku
                                                    .onko_myohassa
                                                    ? _c("strong", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$date(
                                                                _vm
                                                                  .laskutusNayta
                                                                  .lasku
                                                                  .erapaiva
                                                              )
                                                            ) +
                                                            " (myöhässä) "
                                                        ),
                                                      ])
                                                    : [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$date(
                                                                _vm
                                                                  .laskutusNayta
                                                                  .lasku
                                                                  .erapaiva
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _vm.laskutusNayta.lasku
                                                          .maksuehto
                                                          ? [
                                                              _vm._v(
                                                                " (" +
                                                                  _vm._s(
                                                                    _vm.muotoileMaksuehto
                                                                  ) +
                                                                  ") "
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                      ],
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("DefListItem", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [_vm._v(" Lähetystapa ")]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "value",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.laskutusNayta.lasku
                                                    .lahetystapa_ui
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "col3",
                          fn: function () {
                            return [
                              _c(
                                "DefList",
                                { attrs: { bordered: true } },
                                [
                                  _c("DefListItem", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$sovellusIdOtsake) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "value",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.laskutusNayta.lasku.id
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                  _c("DefListItem", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [_vm._v(" Viite ")]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "value",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.laskutusNayta.lasku.viite
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                  _c("DefListItem", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [_vm._v(" PM-viite ")]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "value",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.laskutusNayta.lasku
                                                    .pm_viite
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                  _c("DefListItem", {
                                    attrs: { "value-class": "break-all" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [_vm._v(" Viitteemme ")]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "value",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.laskutusNayta.lasku
                                                    .viitteemme
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                  _c("DefListItem", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [_vm._v(" Viitteenne ")]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "value",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.laskutusNayta.lasku
                                                    .viitteenne
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                  _vm.laskutusNayta.lasku.sopimus
                                    ? _c("DefListItem", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [_vm._v(" Sopimus ")]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "value",
                                              fn: function () {
                                                return _vm._l(
                                                  _vm.laskutusNayta.lasku
                                                    .sopimus_set,
                                                  function (sopimus) {
                                                    return _c(
                                                      "RouterLinkEhdollinen",
                                                      {
                                                        key: sopimus.id,
                                                        attrs: {
                                                          ehto: _vm.$permission.checkSinglePerm(
                                                            "onlinerestapi.onlinerestapi_sopimus"
                                                          ),
                                                          to: {
                                                            name: "sopimus-nayta",
                                                            params: {
                                                              id: sopimus.id,
                                                            },
                                                          },
                                                          "ei-alleviivausta": true,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              sopimus.nro
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                )
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1994575153
                                        ),
                                      })
                                    : _vm._e(),
                                  _vm.laskutusNayta.lasku.myyja &&
                                  _vm.laskutusNayta.lasku.myyja.naytettava_nimi
                                    ? _c("DefListItem", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [_vm._v(" Myyjä ")]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.laskutusNayta.lasku
                                                          .myyja.naytettava_nimi
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          4213341758
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "col4",
                          fn: function () {
                            return [
                              _c(
                                "DefList",
                                { attrs: { bordered: true } },
                                [
                                  _c("DefListItem", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [_vm._v(" Summa ")]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "value",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("formatSumToFixed2")(
                                                    _vm.laskutusNayta.lasku
                                                      .summa
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                  _c("DefListItem", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [_vm._v(" Veroton ")]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "value",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("formatSumToFixed2")(
                                                    _vm.laskutusNayta.lasku
                                                      .veroton
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                  _c("DefListItem", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [_vm._v(" Vero ")]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "value",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("formatSumToFixed2")(
                                                    _vm.laskutusNayta.lasku.vero
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                  _vm.laskutusNayta.lasku.viivastyskorko
                                    ? _c("DefListItem", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _vm._v(" Viivästyskorko "),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$muotoileDesimaaliluku(
                                                          _vm.laskutusNayta
                                                            .lasku
                                                            .viivastyskorko
                                                        )
                                                      ) +
                                                      " % "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1770925797
                                        ),
                                      })
                                    : _vm._e(),
                                  _c("DefListItem", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [_vm._v(" Avoinna ")]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "value",
                                        fn: function () {
                                          return [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatSumToFixed2")(
                                                    _vm.laskutusNayta.lasku
                                                      .avoinna
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "v-alert",
                  {
                    staticClass: "ma-4",
                    attrs: {
                      value: Boolean(
                        _vm.laskutusNayta.lasku.onko_perinnanesto_paalla
                      ),
                      type: "warning",
                    },
                  },
                  [_vm._v(" Laskun siirtyminen perintään on estetty. ")]
                ),
                _c(
                  "div",
                  { staticClass: "pa-4" },
                  [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { cols: "12", lg: "8" } }, [
                          _c(
                            "div",
                            { staticClass: "pb-4" },
                            [
                              _c(
                                "v-card",
                                { staticClass: "pa-6" },
                                [
                                  _c("CardTitle", {
                                    attrs: { text: "Laskurivit" },
                                  }),
                                  _c("v-data-table", {
                                    attrs: {
                                      items: _vm.laskurivitItems,
                                      headers: _vm.tableHeadersLaskurivit,
                                      "hide-default-footer":
                                        _vm.laskurivitItems.length <= 10,
                                      "no-data-text": "Ei laskurivejä",
                                      dense: _vm.user.tiivisNakyma,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c("TableRow", [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-no-wrap",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.koodi) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "max-width" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.selite) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-no-wrap text-right",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$muotoileDesimaaliluku(
                                                            item.alvp
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-no-wrap text-right",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("formatSum")(
                                                            item.ahinta
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-no-wrap text-right",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$muotoileDesimaaliluku(
                                                            item.maara
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-no-wrap text-right",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatSumToFixed2"
                                                          )(item.veroton)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-no-wrap text-right",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatSumToFixed2"
                                                          )(item.vero)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-no-wrap text-right",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatSumToFixed2"
                                                          )(item.verollinen)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                        _vm.laskurivitItems.length
                                          ? {
                                              key: "body.append",
                                              fn: function () {
                                                return [
                                                  _c("TableRow", [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                        attrs: { colspan: "5" },
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v("Yhteensä"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-right",
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatSumToFixed2"
                                                              )(
                                                                _vm
                                                                  .laskutusNayta
                                                                  .lasku.veroton
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatSumToFixed2"
                                                              )(
                                                                _vm
                                                                  .laskutusNayta
                                                                  .lasku.vero
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatSumToFixed2"
                                                              )(
                                                                _vm
                                                                  .laskutusNayta
                                                                  .lasku.summa
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "py-4" },
                            [
                              _c(
                                "v-card",
                                { staticClass: "pa-6" },
                                [
                                  _c("CardTitle", {
                                    attrs: { text: "Kirjaukset" },
                                  }),
                                  _c("v-data-table", {
                                    attrs: {
                                      items: _vm.kirjauksetItems,
                                      headers: _vm.tableHeadersLaskuKirjaukset,
                                      "hide-default-footer":
                                        _vm.kirjauksetItems.length <= 10,
                                      "no-data-text": "Ei kirjauksia",
                                      dense: _vm.user.tiivisNakyma,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c("TableRow", [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-no-wrap text-right",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$date(
                                                            item.maksupaiva
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-no-wrap text-right",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$date(
                                                            item.kirjauspaiva
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c("td", {
                                                  staticClass:
                                                    "text-no-wrap text-right",
                                                }),
                                              ]),
                                              _vm._l(
                                                item.subRows,
                                                function (subRow) {
                                                  return _c(
                                                    "TableRow",
                                                    {
                                                      key: subRow.id,
                                                      attrs: {
                                                        "sub-row": true,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "max-width",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                subRow.viesti
                                                              ) +
                                                              " "
                                                          ),
                                                          subRow.vastakirjauslaskut &&
                                                          subRow
                                                            .vastakirjauslaskut
                                                            .length
                                                            ? _c(
                                                                "span",
                                                                [
                                                                  _vm._v(
                                                                    " (" +
                                                                      _vm._s(
                                                                        subRow
                                                                          .vastakirjauslaskut
                                                                          .length >
                                                                          1
                                                                          ? "Laskut"
                                                                          : "Lasku"
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _vm._l(
                                                                    subRow.vastakirjauslaskut,
                                                                    function (
                                                                      vk,
                                                                      index
                                                                    ) {
                                                                      return [
                                                                        _c(
                                                                          "router-link",
                                                                          {
                                                                            key:
                                                                              "vk-" +
                                                                              vk.id,
                                                                            attrs:
                                                                              {
                                                                                to: {
                                                                                  name: "laskutus-nayta",
                                                                                  params:
                                                                                    {
                                                                                      id: vk.id,
                                                                                    },
                                                                                },
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                vk.nro
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            index <
                                                                              subRow
                                                                                .vastakirjauslaskut
                                                                                .length -
                                                                                1
                                                                              ? ", "
                                                                              : ""
                                                                          )
                                                                        ),
                                                                      ]
                                                                    }
                                                                  ),
                                                                  _vm._v(") "),
                                                                ],
                                                                2
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-no-wrap text-right",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$date(
                                                                  subRow.kirjauspaiva
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-no-wrap text-right",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "formatSumToFixed2"
                                                                )(subRow.summa)
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ]
                                          },
                                        },
                                        _vm.kirjauksetItems.length
                                          ? {
                                              key: "body.append",
                                              fn: function () {
                                                return [
                                                  _c("TableRow", [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                        attrs: { colspan: "2" },
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v("Yhteensä"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatSumToFixed2"
                                                              )(
                                                                _vm
                                                                  .laskutusNayta
                                                                  .lasku
                                                                  .kirjattu
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.laskuliitteetLkm
                            ? _c(
                                "div",
                                { staticClass: "py-4" },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "pa-6" },
                                    [
                                      _c("CardTitle", {
                                        attrs: {
                                          text: "Liitteet ja tiedostot",
                                        },
                                      }),
                                      _c("DataTableLaskuliite", {
                                        attrs: {
                                          items: _vm.laskuliiteItems,
                                          lasku: _vm.laskutusNayta.lasku,
                                        },
                                        on: {
                                          "tiedosto-poistettu": function (
                                            $event
                                          ) {
                                            return _vm.loadItem(true)
                                          },
                                          "lasku-paivitetty": _vm.paivitaLasku,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.lisatiedotItems.length ||
                          _vm.laskutusNayta.lasku.toimitusosoite
                            ? _c(
                                "div",
                                { staticClass: "py-4" },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "pa-6" },
                                    [
                                      _c("CardTitle", {
                                        attrs: { text: "Lisätiedot" },
                                      }),
                                      _vm.lisatiedotItems.length
                                        ? [
                                            _c("v-data-table", {
                                              attrs: {
                                                items: _vm.lisatiedotItems,
                                                headers:
                                                  _vm.tableHeadersLisatiedot,
                                                "hide-default-footer": true,
                                                "items-per-page": 999,
                                                "no-data-text":
                                                  "Ei lisätietoja",
                                                dense: "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "item",
                                                    fn: function (ref) {
                                                      var item = ref.item
                                                      return [
                                                        _c("TableRow", [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-no-wrap",
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.key
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "max-width",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.value
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1355340415
                                              ),
                                            }),
                                          ]
                                        : _vm._e(),
                                      _vm.laskutusNayta.lasku.toimitusosoite
                                        ? [
                                            _c(
                                              "h3",
                                              { staticClass: "pt-4 pb-2" },
                                              [_vm._v(" Toimitusosoite ")]
                                            ),
                                            _vm.laskutusNayta.lasku
                                              .toimitusosoite
                                              ? _c("OsoiteKappale", {
                                                  staticClass: "mb-2",
                                                  attrs: {
                                                    saaja: _vm.laskutusNayta
                                                      .lasku.toimitusosoite
                                                      .saaja
                                                      ? _vm.laskutusNayta.lasku
                                                          .toimitusosoite.saaja
                                                      : "",
                                                    postiosoite:
                                                      _vm.laskutusNayta.lasku
                                                        .toimitusosoite.osoite,
                                                    postinumero:
                                                      _vm.laskutusNayta.lasku
                                                        .toimitusosoite.numero,
                                                    postitoimipaikka:
                                                      _vm.laskutusNayta.lasku
                                                        .toimitusosoite
                                                        .toimipaikka,
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.laskutusNayta.lasku.toimitustapa
                                              ? _c("span", [
                                                  _vm._v(
                                                    "Toimitustapa: " +
                                                      _vm._s(
                                                        _vm.laskutusNayta.lasku
                                                          .toimitustapa
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.kassaAlennuksetItems.length
                            ? _c(
                                "div",
                                { staticClass: "pa-y" },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "pa-6" },
                                    [
                                      _c("CardTitle", {
                                        attrs: { text: "Kassa-alennukset" },
                                      }),
                                      _c("v-data-table", {
                                        attrs: {
                                          items: _vm.kassaAlennuksetItems,
                                          headers:
                                            _vm.tableHeadersKassaAlennukset,
                                          "hide-default-footer": true,
                                          "items-per-page": 999,
                                          "no-data-text": "Ei kassa-alennuksia",
                                          dense: _vm.user.tiivisNakyma,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _c("TableRow", [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$date(
                                                                item.voimassa
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.alennusp
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap max-width",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatSumToFixed2"
                                                              )(item.alennus)
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          583486118
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", lg: "4" } },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pa-6" },
                              [
                                _c("Timeline", {
                                  attrs: {
                                    items: _vm.laskutusNayta.lasku.aikajana,
                                    "enable-reply": true,
                                  },
                                  on: {
                                    "msg-mark-as-unread": function ($event) {
                                      return _vm.loadItem(true)
                                    },
                                    "msg-mark-as-read": function ($event) {
                                      return _vm.loadItem(true)
                                    },
                                    reply: _vm.avaaDialogViestit,
                                    "muistiinpano-muokkaa-submit": function (
                                      $event
                                    ) {
                                      return _vm.loadItem(true)
                                    },
                                    "muistiinpano-poistettu": function (
                                      $event
                                    ) {
                                      return _vm.loadItem(true)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Ilmoita ohisuoritus",
            "dialog-auki": _vm.ohisuoritusDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.ohisuoritusDialogAuki = false
            },
          },
        },
        [
          _c("FormOhisuoritus", {
            staticClass: "pa-4",
            attrs: { loading: _vm.ohisuoritusFormLoading },
            on: {
              cancel: function ($event) {
                _vm.ohisuoritusDialogAuki = false
              },
              submit: _vm.ohisuoritusSubmit,
              close: function ($event) {
                _vm.ohisuoritusDialogAuki = false
              },
            },
            model: {
              value: _vm.laskutusNayta.ohisuoritusFormData,
              callback: function ($$v) {
                _vm.$set(_vm.laskutusNayta, "ohisuoritusFormData", $$v)
              },
              expression: "laskutusNayta.ohisuoritusFormData",
            },
          }),
        ],
        1
      ),
      _c("CardDialog", {
        attrs: {
          title: _vm.$t("common:sendMessageHeader", {
            asiaa: "laskunumeroa " + _vm.laskutusNayta.lasku.nro,
          }),
          "dialog-auki": _vm.uusiViestiDialogAuki,
          "scroll-to-bottom": true,
          "content-class": "px-4 py-2",
        },
        on: {
          close: function ($event) {
            _vm.uusiViestiDialogAuki = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _vm.viestit.length
                  ? _c("Timeline", {
                      staticClass: "px-4",
                      attrs: {
                        items: _vm.viestit,
                        "show-title": false,
                        "show-item-titles": false,
                        "show-dates": true,
                        "enable-zoom": false,
                        "enable-direction-toggle": false,
                      },
                      on: {
                        "msg-mark-as-unread": function ($event) {
                          return _vm.loadItem(true)
                        },
                        "msg-mark-as-read": function ($event) {
                          return _vm.loadItem(true)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("FormUusiViesti", {
                  attrs: {
                    loading: _vm.uusiViestiFormLoading,
                    "focus-on-update": true,
                    painikkeet: "",
                  },
                  on: {
                    submit: _vm.viestiSubmit,
                    close: function ($event) {
                      _vm.uusiViestiDialogAuki = false
                    },
                  },
                  model: {
                    value: _vm.laskutusNayta.uusiViestiFormData,
                    callback: function ($$v) {
                      _vm.$set(_vm.laskutusNayta, "uusiViestiFormData", $$v)
                    },
                    expression: "laskutusNayta.uusiViestiFormData",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("CardDialog", {
        attrs: {
          title: "Muistiinpanot",
          "dialog-auki": _vm.uusiMuistiinpanoDialogAuki,
          "scroll-to-bottom": true,
          "content-class": "px-4 py-2",
        },
        on: {
          close: function ($event) {
            _vm.uusiMuistiinpanoDialogAuki = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _vm.muistiinpanot.length
                  ? _c("Timeline", {
                      staticClass: "px-4",
                      attrs: {
                        items: _vm.muistiinpanot,
                        "show-title": false,
                        "show-item-titles": false,
                        "show-dates": true,
                        "enable-zoom": false,
                        "enable-direction-toggle": false,
                      },
                      on: {
                        "muistiinpano-muokkaa-submit": function ($event) {
                          return _vm.loadItem(true)
                        },
                        "muistiinpano-poistettu": function ($event) {
                          return _vm.loadItem(true)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("FormUusiViesti", {
                  attrs: {
                    loading: _vm.uusiMuistiinpanoFormLoading,
                    "focus-on-update": true,
                    icon: "note",
                    "msg-input-placeholder": "Uusi muistiinpano",
                    "submit-btn-tooltip-text": "Kirjoita muistiinpano",
                    painikkeet: "",
                  },
                  on: {
                    submit: _vm.muistiinpanoSubmit,
                    close: function ($event) {
                      _vm.uusiMuistiinpanoDialogAuki = false
                    },
                  },
                  model: {
                    value: _vm.laskutusNayta.uusiMuistiinpanoFormData,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.laskutusNayta,
                        "uusiMuistiinpanoFormData",
                        $$v
                      )
                    },
                    expression: "laskutusNayta.uusiMuistiinpanoFormData",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Lähetä laskukopio",
            "dialog-auki": _vm.lahetaLaskukopioDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.lahetaLaskukopioDialogAuki = false
            },
          },
        },
        [
          _c("FormLaskukopioLaheta", {
            staticClass: "pa-4",
            attrs: {
              loading: _vm.lahetaLaskukopioFormLoading,
              lasku: _vm.laskutusNayta.lasku,
              kirjepohjat: _vm.laskutusNayta.lahetaLaskukopioLaskupohjat,
            },
            on: {
              cancel: function ($event) {
                _vm.lahetaLaskukopioDialogAuki = false
              },
              submit: _vm.lahetaLaskukopioSubmit,
              close: function ($event) {
                _vm.lahetaLaskukopioDialogAuki = false
              },
            },
            model: {
              value: _vm.laskutusNayta.lahetaLaskukopioFormData,
              callback: function ($$v) {
                _vm.$set(_vm.laskutusNayta, "lahetaLaskukopioFormData", $$v)
              },
              expression: "laskutusNayta.lahetaLaskukopioFormData",
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: _vm.$t("common:attachmentAdd"),
            "dialog-auki": _vm.liitteetDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.liitteetDialogAuki = false
            },
          },
        },
        [
          _c("FormLiitteet", {
            staticClass: "pa-4",
            attrs: {
              loading: _vm.liitteetFormLoading,
              "file-input-hint":
                "Huom. Tiedoston tulee olla tyyppiä jpeg, gif, png tai pdf. Kuvatiedostot muunnetaan pdf:ksi.",
            },
            on: {
              cancel: function ($event) {
                _vm.liitteetDialogAuki = false
              },
              submit: _vm.liitteetSubmit,
              close: function ($event) {
                _vm.liitteetDialogAuki = false
              },
            },
            model: {
              value: _vm.laskutusNayta.liitteetFormData,
              callback: function ($$v) {
                _vm.$set(_vm.laskutusNayta, "liitteetFormData", $$v)
              },
              expression: "laskutusNayta.liitteetFormData",
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Hyvitä laskua",
            "dialog-auki": _vm.hyvitaDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.hyvitaDialogAuki = false
            },
          },
        },
        [
          _c("FormLaskuHyvita", {
            staticClass: "pa-4",
            attrs: {
              lasku: _vm.laskutusNayta.lasku,
              loading: _vm.hyvitaFormLoading,
            },
            on: {
              cancel: function ($event) {
                _vm.hyvitaDialogAuki = false
              },
              submit: _vm.hyvita,
              close: function ($event) {
                _vm.hyvitaDialogAuki = false
              },
            },
            model: {
              value: _vm.laskutusNayta.hyvitaFormData,
              callback: function ($$v) {
                _vm.$set(_vm.laskutusNayta, "hyvitaFormData", $$v)
              },
              expression: "laskutusNayta.hyvitaFormData",
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Mitätöi lasku",
            "dialog-auki": _vm.mitatoiDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.mitatoiDialogAuki = false
            },
          },
        },
        [
          _c("FormLaskuMitatoi", {
            staticClass: "pa-4",
            attrs: {
              lasku: _vm.laskutusNayta.lasku,
              loading: _vm.mitatoiFormLoading,
            },
            on: {
              cancel: function ($event) {
                _vm.mitatoiDialogAuki = false
              },
              submit: _vm.mitatoi,
              close: function ($event) {
                _vm.mitatoiDialogAuki = false
              },
            },
            model: {
              value: _vm.laskutusNayta.mitatoiFormData,
              callback: function ($$v) {
                _vm.$set(_vm.laskutusNayta, "mitatoiFormData", $$v)
              },
              expression: "laskutusNayta.mitatoiFormData",
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Aseta perinnän esto",
            "dialog-auki": _vm.perinnanEstoDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.perinnanEstoDialogAuki = false
            },
          },
        },
        [
          _c("FormPerinnanEsto", {
            staticClass: "pa-4",
            attrs: { loading: _vm.perinnanEstoFormLoading },
            on: {
              cancel: function ($event) {
                _vm.perinnanEstoDialogAuki = false
              },
              submit: _vm.perinnanEstoSubmit,
              close: function ($event) {
                _vm.perinnanEstoDialogAuki = false
              },
            },
            model: {
              value: _vm.laskutusNayta.perinnanEstoFormData,
              callback: function ($$v) {
                _vm.$set(_vm.laskutusNayta, "perinnanEstoFormData", $$v)
              },
              expression: "laskutusNayta.perinnanEstoFormData",
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Siirrä eräpäivää",
            "dialog-auki": _vm.erapaivanSiirtoDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.erapaivanSiirtoDialogAuki = false
            },
          },
        },
        [
          _c("FormErapaivanSiirto", {
            staticClass: "pa-4",
            attrs: { loading: _vm.erapaivanSiirtoFormLoading },
            on: {
              cancel: function ($event) {
                _vm.erapaivanSiirtoDialogAuki = false
              },
              submit: _vm.erapaivanSiirtoSubmit,
              close: function ($event) {
                _vm.erapaivanSiirtoDialogAuki = false
              },
            },
            model: {
              value: _vm.laskutusNayta.erapaivanSiirtoFormData,
              callback: function ($$v) {
                _vm.$set(_vm.laskutusNayta, "erapaivanSiirtoFormData", $$v)
              },
              expression: "laskutusNayta.erapaivanSiirtoFormData",
            },
          }),
        ],
        1
      ),
      _c("MenuContext", {
        attrs: {
          "onko-nakyvissa": _vm.menuContextNakyvissa,
          "position-x": _vm.menuContextX,
          "position-y": _vm.menuContextY,
          vaihtoehdot: _vm.menuContextVaihtoehdot,
        },
        on: {
          avaaValilehteen: _vm.avaaUudessaValilehdessa,
          "update:return-value": _vm.suljeMenuContext,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }