<template>
  <div class="laskutus-nayta">
    <ViewTopBarBase
      :title="routeTitle"
    >
      <v-menu
        bottom
        left
        nudge-bottom="40"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="success"
            depressed
            v-on="on"
          >
            Toiminnot
            <v-icon right>
              more_vert
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <v-list>
            <v-list-item
              v-if="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku_ohisuoritus')"
              :disabled="!ohisuoritusEnabled"
              @click="avaaDialogOhisuoritus"
            >
              <v-list-item-avatar>
                <v-icon>redo</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Ilmoita ohisuoritus
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_viesti_lahetys')"
              @click="avaaDialogViestit"
            >
              <v-list-item-avatar>
                <v-icon>comment</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Lähetä viesti
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="avaaDialogMuistiinpanot"
            >
              <v-list-item-avatar>
                <v-icon>anitta-icon-note</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Kirjoita muistiinpano
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku_laskukopio')"
              @click="avaaDialogLaskukopio"
            >
              <v-list-item-avatar>
                <v-icon>anitta-icon-invoice</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Lähetä laskukopio
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="avaaDialogLiitteet"
            >
              <v-list-item-avatar>
                <v-icon>attach_file</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Lisää liitteitä
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku_muokkaa')"
              :disabled="!laskuOnMuokattavissa"
              @click="siirryLaskunMuokkaukseen"
            >
              <v-list-item-avatar>
                <v-icon>edit</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Muokkaa laskua
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="!laskuOnHyvitettavissa"
              @click="avaaDialogHyvita"
            >
              <v-list-item-avatar>
                <v-icon>euro</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Hyvitä
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku_mitatoi')"
              :disabled="!laskuOnMitatoitavissa"
              @click="avaaDialogMitatoi"
            >
              <v-list-item-avatar>
                <v-icon>cancel</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Mitätöi
              </v-list-item-title>
            </v-list-item>
            <template
              v-if="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku_perinnanesto')"
            >
              <v-list-item
                v-if="laskutusNayta.lasku.onko_perinnanesto_paalla"
                @click="salliPerinta"
              >
                <v-list-item-avatar>
                  <v-icon>forward</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  Salli perintä
                </v-list-item-title>
              </v-list-item>
              <v-tooltip
                v-else
                open-delay="100"
                bottom
                :disabled="!laskutusNayta.lasku.esta_perinta_tarkistukset.length"
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-list-item
                      :disabled="Boolean(laskutusNayta.lasku.esta_perinta_tarkistukset.length)"
                      @click="avaaDialogPerinnanEsto"
                    >
                      <v-list-item-avatar>
                        <v-icon>block</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title>
                        Estä perintä
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                </template>
                <template
                  v-slot:default
                >
                  <p class="tooltip-hover">
                    Laskua ei voi siirtää perintään, koska:
                    <ul>
                      <li
                        v-for="(estosyy, index) of laskutusNayta.lasku.esta_perinta_tarkistukset"
                        :key="index"
                      >
                        {{ estosyy }}
                      </li>
                    </ul>
                  </p>
                </template>
              </v-tooltip>
            </template>
            <v-list-item
              :disabled="!laskutusNayta.lasku.onko_erapaivan_siirto_mahdollinen"
              @click="avaaDialogErapaivansiirto"
            >
              <v-list-item-avatar>
                <v-icon>date_range</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Siirrä eräpäivää
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </ViewTopBarBase>

    <Spinner v-if="tietueLataamassa" />
    <CardPahoittelu v-else-if="tietueLatausEpaonnistunut" />
    <template v-else>
      <v-container
        class="pa-2"
        fluid
      >
        <!-- Tiedot -->
        <div class="pa-4">
          <TietueenPerustiedotCard
            :card-class="(laskutusNayta.lasku.onko_myohassa ? 'yellow lighten-5' : null)"
            :paamies="laskutusNayta.lasku.paamies"
            :vastapuoli="laskutusNayta.lasku.asiakas"
          >
            <template v-slot:vastaanottaja>
              <p class="mb-0 font-weight-bold grey--text darken-2">
                Asiakas
              </p>
              <template v-if="eiOikeuttaNahdaAsiakastaLasku(laskutusNayta.lasku)">
                <p class="font-italic">
                  Ei voida näyttää tietosuojasyistä
                </p>
              </template>
              <template v-else-if="trafiLupaNaytaVainNimiLasku(laskutusNayta.lasku)">
                <h3>{{ laskutusNayta.lasku.asiakas.nimi }} <span v-if="laskutusNayta.lasku.asiakas.nro">({{ laskutusNayta.lasku.asiakas.nro }})</span></h3>
              </template>
              <template v-else>
                <h3>
                  <router-link
                    :to="{ name: 'laskutus-asiakas-nayta', params: { id: laskutusNayta.lasku.asiakas.id } }"
                    class="inlineList__link"
                  >
                    {{ laskutusNayta.lasku.asiakas.nimi }} <span v-if="laskutusNayta.lasku.asiakas.nro">({{ laskutusNayta.lasku.asiakas.nro }})</span>
                  </router-link>
                </h3>
                <OsoiteKappale
                  v-if="laskutusNayta.lasku.asiakas.postiosoite"
                  :postiosoite="laskutusNayta.lasku.asiakas.postiosoite.osoite"
                  :postinumero="laskutusNayta.lasku.asiakas.postiosoite.numero"
                  :postitoimipaikka="laskutusNayta.lasku.asiakas.postiosoite.toimipaikka"
                  :class="tiivisNakyma ? 'mb-0' : 'mb-2'"
                />
                <p
                  v-if="laskutusNayta.lasku.asiakas.tunnus"
                  class="mb-0"
                >
                  {{ tunnustyyppi }}: {{ laskutusNayta.lasku.asiakas.tunnus }}
                </p>
                <p
                  v-if="laskutusNayta.lasku.asiakas.emailosoite && laskutusNayta.lasku.asiakas.emailosoite.email"
                  class="mb-0"
                >
                  {{ laskutusNayta.lasku.asiakas.emailosoite.email }}
                </p>
                <p
                  v-if="laskutusNayta.lasku.asiakas.verkkolaskuosoite && laskutusNayta.lasku.asiakas.verkkolaskuosoite.verkkolaskuosoite"
                  class="mb-0"
                >
                  Verkkolaskuosoite: {{ laskutusNayta.lasku.asiakas.verkkolaskuosoite.verkkolaskuosoite }}
                </p>
              </template>
            </template>
            <template v-slot:col2>
              <DefList :bordered="(true)">
                <DefListItem
                  label-class="defList__label--emphasized"
                  value-class="font-weight-bold"
                >
                  <template v-slot:label>
                    Laskunumero
                  </template>
                  <template
                    v-if="laskutusNayta.lasku.nro"
                    v-slot:value
                  >
                    {{ laskutusNayta.lasku.nro }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    Tila
                  </template>
                  <template
                    v-if="laskutusNayta.lasku.tila && laskutusNayta.lasku.ikoni_ja_vari"
                    v-slot:value
                  >
                    <StatusText
                      :color="laskutusNayta.lasku.ikoni_ja_vari.vari"
                      :text="laskutusNayta.lasku.tila.selite"
                    />
                  </template>
                </DefListItem>
                <DefListItem v-if="laskutusNayta.lasku.toimeksianto">
                  <template v-slot:label>
                    Toimeksianto
                  </template>
                  <template v-slot:value>
                    <router-link
                      :to="{ name: 'perinta-nayta', params: { id: laskutusNayta.lasku.toimeksianto } }"
                      class="inlineList__link"
                    >
                      <span @contextmenu.prevent="avaaMenuContext($event, {id : laskutusNayta.lasku.toimeksianto }, 'perinta-nayta')">{{ laskutusNayta.lasku.toimeksianto }}</span>
                    </router-link>
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    Laskupäivä
                  </template>
                  <template
                    v-if="laskutusNayta.lasku.laskupaiva"
                    v-slot:value
                  >
                    {{ $date(laskutusNayta.lasku.laskupaiva) }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    Eräpäivä
                  </template>
                  <template
                    v-if="laskutusNayta.lasku.erapaiva"
                    v-slot:value
                  >
                    <strong v-if="laskutusNayta.lasku.onko_myohassa">
                      {{ $date(laskutusNayta.lasku.erapaiva) }} (myöhässä)
                    </strong>
                    <template v-else>
                      {{ $date(laskutusNayta.lasku.erapaiva) }} <template v-if="laskutusNayta.lasku.maksuehto">
                        ({{ muotoileMaksuehto }})
                      </template>
                    </template>
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    Lähetystapa
                  </template>
                  <template v-slot:value>
                    {{ laskutusNayta.lasku.lahetystapa_ui }}
                  </template>
                </DefListItem>
              </DefList>
            </template>
            <template v-slot:col3>
              <DefList :bordered="(true)">
                <DefListItem>
                  <template v-slot:label>
                    {{ $sovellusIdOtsake }}
                  </template>
                  <template v-slot:value>
                    {{ laskutusNayta.lasku.id }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    Viite
                  </template>
                  <template v-slot:value>
                    {{ laskutusNayta.lasku.viite }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    PM-viite
                  </template>
                  <template v-slot:value>
                    {{ laskutusNayta.lasku.pm_viite }}
                  </template>
                </DefListItem>
                <DefListItem value-class="break-all">
                  <template v-slot:label>
                    Viitteemme
                  </template>
                  <template v-slot:value>
                    {{ laskutusNayta.lasku.viitteemme }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    Viitteenne
                  </template>
                  <template v-slot:value>
                    {{ laskutusNayta.lasku.viitteenne }}
                  </template>
                </DefListItem>
                <DefListItem v-if="laskutusNayta.lasku.sopimus">
                  <template v-slot:label>
                    Sopimus
                  </template>
                  <template v-slot:value>
                    <RouterLinkEhdollinen
                      v-for="sopimus in laskutusNayta.lasku.sopimus_set"
                      :key="sopimus.id"
                      :ehto="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_sopimus')"
                      :to="{ name: 'sopimus-nayta', params: { id: sopimus.id }}"
                      :ei-alleviivausta="(true)"
                    >
                      {{ sopimus.nro }}
                    </RouterLinkEhdollinen>
                  </template>
                </DefListItem>
                <DefListItem v-if="(laskutusNayta.lasku.myyja && laskutusNayta.lasku.myyja.naytettava_nimi)">
                  <template v-slot:label>
                    Myyjä
                  </template>
                  <template v-slot:value>
                    {{ laskutusNayta.lasku.myyja.naytettava_nimi }}
                  </template>
                </DefListItem>
              </DefList>
            </template>
            <template v-slot:col4>
              <DefList :bordered="(true)">
                <DefListItem>
                  <template v-slot:label>
                    Summa
                  </template>
                  <template v-slot:value>
                    {{ laskutusNayta.lasku.summa | formatSumToFixed2 }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    Veroton
                  </template>
                  <template v-slot:value>
                    {{ laskutusNayta.lasku.veroton | formatSumToFixed2 }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    Vero
                  </template>
                  <template v-slot:value>
                    {{ laskutusNayta.lasku.vero | formatSumToFixed2 }}
                  </template>
                </DefListItem>
                <DefListItem v-if="laskutusNayta.lasku.viivastyskorko">
                  <template v-slot:label>
                    Viivästyskorko
                  </template>
                  <template v-slot:value>
                    {{ $muotoileDesimaaliluku(laskutusNayta.lasku.viivastyskorko) }} %
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    Avoinna
                  </template>
                  <template v-slot:value>
                    <strong>{{ laskutusNayta.lasku.avoinna | formatSumToFixed2 }}</strong>
                  </template>
                </DefListItem>
              </DefList>
            </template>
          </TietueenPerustiedotCard>
        </div>

        <v-alert
          :value="Boolean(laskutusNayta.lasku.onko_perinnanesto_paalla)"
          type="warning"
          class="ma-4"
        >
          Laskun siirtyminen perintään on estetty.
        </v-alert>

        <div class="pa-4">
          <v-row>
            <!-- Left column -->
            <v-col
              cols="12"
              lg="8"
            >
              <!-- Laskurivit -->
              <div class="pb-4">
                <v-card class="pa-6">
                  <CardTitle text="Laskurivit" />

                  <v-data-table
                    :items="laskurivitItems"
                    :headers="tableHeadersLaskurivit"
                    :hide-default-footer="laskurivitItems.length <= 10"
                    no-data-text="Ei laskurivejä"
                    :dense="user.tiivisNakyma"
                  >
                    <template v-slot:item="{ item }">
                      <TableRow>
                        <td class="text-no-wrap">
                          {{ item.koodi }}
                        </td>
                        <td class="max-width">
                          {{ item.selite }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $muotoileDesimaaliluku(item.alvp) }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.ahinta | formatSum }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $muotoileDesimaaliluku(item.maara) }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.veroton | formatSumToFixed2 }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.vero | formatSumToFixed2 }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.verollinen | formatSumToFixed2 }}
                        </td>
                      </TableRow>
                    </template>
                    <template
                      v-if="laskurivitItems.length"
                      v-slot:body.append
                    >
                      <TableRow>
                        <td
                          colspan="5"
                          class="text-no-wrap text-right"
                        >
                          <strong>Yhteensä</strong>
                        </td>
                        <td class="text-right">
                          <strong>{{ laskutusNayta.lasku.veroton | formatSumToFixed2 }}</strong>
                        </td>
                        <td class="text-no-wrap text-right">
                          <strong>{{ laskutusNayta.lasku.vero | formatSumToFixed2 }}</strong>
                        </td>
                        <td class="text-no-wrap text-right">
                          <strong>{{ laskutusNayta.lasku.summa | formatSumToFixed2 }}</strong>
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>

              <!-- Kirjaukset -->
              <div class="py-4">
                <v-card class="pa-6">
                  <CardTitle text="Kirjaukset" />

                  <v-data-table
                    :items="kirjauksetItems"
                    :headers="tableHeadersLaskuKirjaukset"
                    :hide-default-footer="kirjauksetItems.length <= 10"
                    no-data-text="Ei kirjauksia"
                    :dense="user.tiivisNakyma"
                  >
                    <template v-slot:item="{ item }">
                      <TableRow>
                        <td class="text-no-wrap text-right">
                          {{ $date(item.maksupaiva) }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $date(item.kirjauspaiva) }}
                        </td>
                        <td class="text-no-wrap text-right">
                        </td>
                      </TableRow>
                      <TableRow
                        v-for="subRow in item.subRows"
                        :key="subRow.id"
                        :sub-row="(true)"
                      >
                        <td class="max-width">
                          {{ subRow.viesti }}
                          <span v-if="subRow.vastakirjauslaskut && subRow.vastakirjauslaskut.length">
                            ({{ subRow.vastakirjauslaskut.length > 1 ? 'Laskut' : 'Lasku' }}&nbsp;
                            <template
                              v-for="(vk, index) in subRow.vastakirjauslaskut">
                              <router-link
                                :key="`vk-${vk.id}`"
                                :to="{ name: 'laskutus-nayta', params: { id: vk.id } }"
                              >{{ vk.nro }}</router-link>{{ (index < (subRow.vastakirjauslaskut.length - 1)) ? ', ' : '' }}</template>)
                          </span>
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $date(subRow.kirjauspaiva) }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ subRow.summa | formatSumToFixed2 }}
                        </td>
                      </TableRow>
                    </template>
                    <template
                      v-if="kirjauksetItems.length"
                      v-slot:body.append
                    >
                      <TableRow>
                        <td
                          colspan="2"
                          class="text-no-wrap text-right"
                        >
                          <strong>Yhteensä</strong>
                        </td>
                        <td class="text-no-wrap text-right">
                          <strong>{{ laskutusNayta.lasku.kirjattu | formatSumToFixed2 }}</strong>
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>

              <!-- Liitteet ja tiedostot -->
              <div
                v-if="laskuliitteetLkm"
                class="py-4"
              >
                <v-card class="pa-6">
                  <CardTitle text="Liitteet ja tiedostot" />

                  <DataTableLaskuliite
                    :items="laskuliiteItems"
                    :lasku="laskutusNayta.lasku"
                    @tiedosto-poistettu="loadItem(true)"
                    @lasku-paivitetty="paivitaLasku"
                  />
                </v-card>
              </div>

              <!-- Lisätiedot -->
              <div
                v-if="lisatiedotItems.length || laskutusNayta.lasku.toimitusosoite"
                class="py-4"
              >
                <v-card class="pa-6">
                  <CardTitle text="Lisätiedot" />

                  <template v-if="lisatiedotItems.length">
                    <v-data-table
                      :items="lisatiedotItems"
                      :headers="tableHeadersLisatiedot"
                      :hide-default-footer="(true)"
                      :items-per-page="999"
                      no-data-text="Ei lisätietoja"
                      dense
                    >
                      <template v-slot:item="{ item }">
                        <TableRow>
                          <td class="text-no-wrap">
                            <strong>{{ item.key }}</strong>
                          </td>
                          <td class="max-width">
                            {{ item.value }}
                          </td>
                        </TableRow>
                      </template>
                    </v-data-table>
                  </template>

                  <template v-if="laskutusNayta.lasku.toimitusosoite">
                    <h3 class="pt-4 pb-2">
                      Toimitusosoite
                    </h3>
                    <OsoiteKappale
                      v-if="laskutusNayta.lasku.toimitusosoite"
                      :saaja="laskutusNayta.lasku.toimitusosoite.saaja ? laskutusNayta.lasku.toimitusosoite.saaja : ''"
                      :postiosoite="laskutusNayta.lasku.toimitusosoite.osoite"
                      :postinumero="laskutusNayta.lasku.toimitusosoite.numero"
                      :postitoimipaikka="laskutusNayta.lasku.toimitusosoite.toimipaikka"
                      class="mb-2"
                    />
                    <span v-if="laskutusNayta.lasku.toimitustapa">Toimitustapa: {{ laskutusNayta.lasku.toimitustapa }}</span>
                  </template>
                </v-card>
              </div>

              <!-- Kassa-alennukset -->
              <div
                v-if="kassaAlennuksetItems.length"
                class="pa-y"
              >
                <v-card class="pa-6">
                  <CardTitle text="Kassa-alennukset" />

                  <v-data-table
                    :items="kassaAlennuksetItems"
                    :headers="tableHeadersKassaAlennukset"
                    :hide-default-footer="(true)"
                    :items-per-page="999"
                    no-data-text="Ei kassa-alennuksia"
                    :dense="user.tiivisNakyma"
                  >
                    <template v-slot:item="{ item }">
                      <TableRow>
                        <td class="text-no-wrap">
                          {{ $date(item.voimassa) }}
                        </td>
                        <td class="text-no-wrap">
                          {{ item.alennusp }}
                        </td>
                        <td class="text-no-wrap max-width">
                          {{ item.alennus | formatSumToFixed2 }}
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>
            </v-col>

            <!-- Right column -->
            <v-col
              cols="12"
              lg="4"
            >
              <!-- Timeline -->
              <v-card class="pa-6">
                <Timeline
                  :items="laskutusNayta.lasku.aikajana"
                  :enable-reply="(true)"
                  @msg-mark-as-unread="loadItem(true)"
                  @msg-mark-as-read="loadItem(true)"
                  @reply="avaaDialogViestit"
                  @muistiinpano-muokkaa-submit="loadItem(true)"
                  @muistiinpano-poistettu="loadItem(true)"
                />
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </template>

    <!-- Dialog: Ohisuoritus -->
    <CardDialog
      title="Ilmoita ohisuoritus"
      :dialog-auki="ohisuoritusDialogAuki"
      @close="(ohisuoritusDialogAuki = false)"
    >
      <FormOhisuoritus
        v-model="laskutusNayta.ohisuoritusFormData"
        class="pa-4"
        :loading="ohisuoritusFormLoading"
        @cancel="ohisuoritusDialogAuki = false"
        @submit="ohisuoritusSubmit"
        @close="(ohisuoritusDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Viestit -->
    <CardDialog
      :title="$t('common:sendMessageHeader', { asiaa: 'laskunumeroa ' + laskutusNayta.lasku.nro })"
      :dialog-auki="uusiViestiDialogAuki"
      :scroll-to-bottom="(true)"
      content-class="px-4 py-2"
      @close="(uusiViestiDialogAuki = false)"
    >
      <template v-slot:default>
        <Timeline
          v-if="viestit.length"
          class="px-4"
          :items="viestit"
          :show-title="(false)"
          :show-item-titles="(false)"
          :show-dates="(true)"
          :enable-zoom="(false)"
          :enable-direction-toggle="(false)"
          @msg-mark-as-unread="loadItem(true)"
          @msg-mark-as-read="loadItem(true)"
        />
      </template>
      <template v-slot:footer>
        <FormUusiViesti
          v-model="laskutusNayta.uusiViestiFormData"
          :loading="uusiViestiFormLoading"
          :focus-on-update="(true)"
          painikkeet
          @submit="viestiSubmit"
          @close="(uusiViestiDialogAuki = false)"
        />
      </template>
    </CardDialog>

    <!-- Dialog: Muistiinpanot -->
    <CardDialog
      title="Muistiinpanot"
      :dialog-auki="uusiMuistiinpanoDialogAuki"
      :scroll-to-bottom="(true)"
      content-class="px-4 py-2"
      @close="(uusiMuistiinpanoDialogAuki = false)"
    >
      <template v-slot:default>
        <Timeline
          v-if="muistiinpanot.length"
          class="px-4"
          :items="muistiinpanot"
          :show-title="(false)"
          :show-item-titles="(false)"
          :show-dates="(true)"
          :enable-zoom="(false)"
          :enable-direction-toggle="(false)"
          @muistiinpano-muokkaa-submit="loadItem(true)"
          @muistiinpano-poistettu="loadItem(true)"
        />
      </template>
      <template v-slot:footer>
        <FormUusiViesti
          v-model="laskutusNayta.uusiMuistiinpanoFormData"
          :loading="uusiMuistiinpanoFormLoading"
          :focus-on-update="(true)"
          icon="note"
          msg-input-placeholder="Uusi muistiinpano"
          submit-btn-tooltip-text="Kirjoita muistiinpano"
          painikkeet
          @submit="muistiinpanoSubmit"
          @close="(uusiMuistiinpanoDialogAuki = false)"
        />
      </template>
    </CardDialog>

    <!-- Dialog: Lähetä laskukopio -->
    <CardDialog
      title="Lähetä laskukopio"
      :dialog-auki="lahetaLaskukopioDialogAuki"
      @close="(lahetaLaskukopioDialogAuki = false)"
    >
      <FormLaskukopioLaheta
        v-model="laskutusNayta.lahetaLaskukopioFormData"
        class="pa-4"
        :loading="lahetaLaskukopioFormLoading"
        :lasku="laskutusNayta.lasku"
        :kirjepohjat="laskutusNayta.lahetaLaskukopioLaskupohjat"
        @cancel="lahetaLaskukopioDialogAuki = false"
        @submit="lahetaLaskukopioSubmit"
        @close="(lahetaLaskukopioDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Lisää liitteitä -->
    <CardDialog
      :title="$t('common:attachmentAdd')"
      :dialog-auki="liitteetDialogAuki"
      @close="(liitteetDialogAuki = false)"
    >
      <FormLiitteet
        v-model="laskutusNayta.liitteetFormData"
        class="pa-4"
        :loading="liitteetFormLoading"
        file-input-hint="Huom. Tiedoston tulee olla tyyppiä jpeg, gif, png tai pdf. Kuvatiedostot muunnetaan pdf:ksi."
        @cancel="liitteetDialogAuki = false"
        @submit="liitteetSubmit"
        @close="(liitteetDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Hyvitä laskua -->
    <CardDialog
      title="Hyvitä laskua"
      :dialog-auki="hyvitaDialogAuki"
      @close="hyvitaDialogAuki = false"
    >
      <FormLaskuHyvita
        v-model="laskutusNayta.hyvitaFormData"
        class="pa-4"
        :lasku="laskutusNayta.lasku"
        :loading="hyvitaFormLoading"
        @cancel="hyvitaDialogAuki = false"
        @submit="hyvita"
        @close="hyvitaDialogAuki = false"
      />
    </CardDialog>

    <!-- Dialog: Mitätöi lasku -->
    <CardDialog
      title="Mitätöi lasku"
      :dialog-auki="mitatoiDialogAuki"
      @close="mitatoiDialogAuki = false"
    >
      <FormLaskuMitatoi
        v-model="laskutusNayta.mitatoiFormData"
        class="pa-4"
        :lasku="laskutusNayta.lasku"
        :loading="mitatoiFormLoading"
        @cancel="mitatoiDialogAuki = false"
        @submit="mitatoi"
        @close="mitatoiDialogAuki = false"
      />
    </CardDialog>

    <!-- Dialog: Estä perintä -->
    <CardDialog
      title="Aseta perinnän esto"
      :dialog-auki="perinnanEstoDialogAuki"
      @close="(perinnanEstoDialogAuki = false)"
    >
      <FormPerinnanEsto
        v-model="laskutusNayta.perinnanEstoFormData"
        class="pa-4"
        :loading="perinnanEstoFormLoading"
        @cancel="perinnanEstoDialogAuki = false"
        @submit="perinnanEstoSubmit"
        @close="(perinnanEstoDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Eräpäivän siirto -->
    <CardDialog
      title="Siirrä eräpäivää"
      :dialog-auki="erapaivanSiirtoDialogAuki"
      @close="(erapaivanSiirtoDialogAuki = false)"
    >
      <FormErapaivanSiirto
        v-model="laskutusNayta.erapaivanSiirtoFormData"
        class="pa-4"
        :loading="erapaivanSiirtoFormLoading"
        @cancel="erapaivanSiirtoDialogAuki = false"
        @submit="erapaivanSiirtoSubmit"
        @close="(erapaivanSiirtoDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Välilehti -->
    <MenuContext
      :onko-nakyvissa="menuContextNakyvissa"
      :position-x="menuContextX"
      :position-y="menuContextY"
      :vaihtoehdot="menuContextVaihtoehdot"
      @avaaValilehteen="avaaUudessaValilehdessa"
      @update:return-value="suljeMenuContext"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import { Lasku } from '@/class/Lasku'
import {
  OsoiteKappale,
  FormErapaivanSiirto,
  FormLaskuHyvita,
  FormLaskukopioLaheta,
  FormLaskuMitatoi,
  FormLiitteet,
  FormUusiViesti,
  FormOhisuoritus,
  FormPerinnanEsto,
  StatusText,
  TietueenPerustiedotCard,
  Timeline
} from '@/components'
import MenuContextMixin from '@/mixins/MenuContextMixin'
import TiivisNakymaMixin from '@/mixins/TiivisNakymaMixin'
import TrafiLupaMixin from '@/mixins/TrafiLupaMixin'
import {
  TableHeadersLaskurivit,
  TableHeadersLaskuKirjaukset,
  TableHeadersLisatiedot,
  TableHeadersKassaAlennukset,
  TableHeadersLaskuliite,
} from '@/utils/tableHeaders'

export default {
  name: 'LaskutusNayta',
  components: {
    OsoiteKappale,
    FormErapaivanSiirto,
    FormLaskuHyvita,
    FormLaskukopioLaheta,
    FormLaskuMitatoi,
    FormLiitteet,
    FormUusiViesti,
    FormOhisuoritus,
    FormPerinnanEsto,
    StatusText,
    TietueenPerustiedotCard,
    Timeline,
  },
  mixins: [
    MenuContextMixin,
    TiivisNakymaMixin,
    TrafiLupaMixin,
  ],
  data () {
    return {
      tietueLataamassa: false,
      tietueLatausEpaonnistunut: false,
      lasku: {
        aikajana: [],
        avoinna: null,
        esta_perinta_tarkistukset: [],
        ikoni_ja_vari: {
          ikoni: null,
          vari: null,
        },
        kirje_set: [],
        laskuliite_set: [],
        onko_erapaivan_siirto_mahdollinen: false,
        paamies: {
          nimi: '',
        },
        tapahtuma_set_laskukirjauksilla: [],
        tila: {},
      },
      erapaivanSiirtoDialogAuki: false,
      erapaivanSiirtoFormData: {},
      erapaivanSiirtoFormLoading: false,
      hyvitaDialogAuki: false,
      hyvitaFormData: {},
      hyvitaFormLoading: false,
      lahetaLaskukopioDialogAuki: false,
      lahetaLaskukopioFormData: {},
      lahetaLaskukopioFormLoading: false,
      liitteetDialogAuki: false,
      liitteetFormData: {},
      liitteetFormLoading: false,
      mitatoiDialogAuki: false,
      mitatoiFormLoading: false,
      ohisuoritusDialogAuki: false,
      ohisuoritusFormLoading: false,
      perinnanEstoDialogAuki: false,
      perinnanEstoFormLoading: false,
      poistettavaTiedosto: {},
      tableHeadersKassaAlennukset: TableHeadersKassaAlennukset || [],
      tableHeadersLaskuKirjaukset: TableHeadersLaskuKirjaukset || [],
      tableHeadersLaskuliite: TableHeadersLaskuliite || [],
      tableHeadersLaskurivit: TableHeadersLaskurivit || [],
      tableHeadersLisatiedot: TableHeadersLisatiedot || [],
      uusiMuistiinpanoDialogAuki: false,
      uusiMuistiinpanoFormLoading: false,
      uusiViestiDialogAuki: false,
      uusiViestiFormLoading: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      laskutusNayta: state => state.laskutusNayta,
    }),
    ohisuoritusEnabled () {
      return this.laskutusNayta.lasku.avoinna > 0
    },

    muistiinpanot () {
      return this.laskutusNayta.lasku.aikajana.filter(item => item.tyyppi == 'Muistiinpano')
    },

    viestit () {
      return this.laskutusNayta.lasku.aikajana.filter(item => item.tyyppi == 'PaamiesViesti')
    },

    laskurivitItems () {
      if (!this.laskutusNayta.lasku.laskusisalto_set) return []

      return this.laskutusNayta.lasku.laskusisalto_set.map(item => {
        return {
          koodi: item.koodi,
          selite: item.selite,
          alvp: item.alvp,
          ahinta: item.ahinta,
          maara: item.maara,
          veroton: item.veroton,
          vero: item.vero,
          verollinen: item.verollinen,
        }
      })
    },

    kirjauksetItems () {
      return this.laskutusNayta.lasku.tapahtuma_set_laskukirjauksilla.map(tapahtuma => {
        return {
          maksupaiva: tapahtuma.maksupaiva,
          kirjauspaiva: tapahtuma.kirjauspaiva,
          subRows: tapahtuma.kirjaus_set.map(kirjaus => {
            return {
              id: kirjaus.id,
              viesti: kirjaus.viesti,
              kirjauspaiva: kirjaus.kirjauspaiva,
              summa: kirjaus.summa,
              vastakirjauslaskut: kirjaus.vastakirjauslaskut
            }
          })
        }
      })
    },

    laskuliiteItems () {
      return [...this.laskutusNayta.lasku.laskuliite_set, ...this.laskutusNayta.lasku.kirje_set]
    },

    lisatiedotItems () {
      if (!this.laskutusNayta.lasku.laskulisatieto_set) return []

      let items = this.laskutusNayta.lasku.laskulisatieto_set.map(item => {
        if (item.key === 'rahoitetaan') {
          item.key = 'Rahoitus'

          if (item.value == 1) {
            item.value = 'Rahoitetaan'
          } else if (item.value == 2) {
            item.value = 'Rahoitettu'
          } else if (item.value == 3) {
            item.value = 'Regressi'
          }
        }

        return {
          id: item.id,
          key: item.key,
          value: item.value,
        }
      })

      if (this.laskutusNayta.lasku.vapaateksti) items.push({
        id: 'vapaateksti',
        key: 'Laskulla näkyvä teksti',
        value: this.laskutusNayta.lasku.vapaateksti,
      })

      return items
    },

    kassaAlennuksetItems () {
      if (!this.laskutusNayta.lasku.kassaalennus_set) return []

      return this.laskutusNayta.lasku.kassaalennus_set.map(item => {
        return {
          id: item.id,
          voimassa: item.voimassa,
          alennusp: item.alennusp,
          alennus: item.alennus,
        }
      })
    },

    laskuliitteetLkm () {
      return this.laskutusNayta.lasku.laskuliite_set.length + this.laskutusNayta.lasku.kirje_set.length
    },
    laskuOnMuokattavissa () {
      return [
        Lasku.TILA_LAHETTAMATTA,
        Lasku.TILA_LUONNOS
      ].includes(this.laskutusNayta.lasku.tila.value) && this.laskutusNayta.lasku.tapahtuma_set_laskukirjauksilla.length === 0
    },
    laskuOnHyvitettavissa () {
      return this.laskutusNayta.lasku.avoinna > 0 && this.laskutusNayta.lasku.tila.value !== Lasku.TILA_PERINNASSA
    },
    laskuOnMitatoitavissa () {
      return [
        Lasku.TILA_AVOINNA,
        Lasku.TILA_LAHETTAMATTA,
        Lasku.TILA_LUONNOS
      ].includes(this.laskutusNayta.lasku.tila.value)
    },
    muotoileMaksuehto () {
      return Number(this.laskutusNayta.lasku.maksuehto) ? this.laskutusNayta.lasku.maksuehto + ' pv' : this.laskutusNayta.lasku.maksuehto
    },
    routeTitle () {
      if (this.laskutusNayta.lasku.nro) {
        return `${this.$route.meta.title} ${this.laskutusNayta.lasku.nro}`
      } else {
        return this.$route.meta.title
      }
    },
    tunnustyyppi () {
      if (!this.laskutusNayta.lasku.asiakas.tyyppi) return null

      if (this.laskutusNayta.lasku.asiakas.tyyppi == 'H') {
        return 'Hetu'
      } else if (this.laskutusNayta.lasku.asiakas.tyyppi == 'Y') {
        return 'Y-tunnus'
      } else {
        return 'Tunnus'
      }
    },
  },
  watch: {
    '$route.params.id': {
      handler () {
        this.loadItem()
        this.$store.dispatch('laskutusNayta/alustaHyvitaFormData')
      }
    }
  },
  async created () {
    await this.loadItem()
    if (!this.tietueLatausEpaonnistunut) {
      this.haeLaskukopionLahetyksenLaskupohjat()
      this.$store.dispatch('laskutusNayta/alustaHyvitaFormData')
      this.$store.dispatch('laskutusNayta/alustaLaskukopioFormData')
      this.$store.dispatch('laskutusNayta/alustaMitatoiFormData')
    }
  },
  methods: {
    async loadItem (silent = false) {
      try {
        if (!silent) this.tietueLataamassa = true
        await this.$store.dispatch('laskutusNayta/loadItem', this.$route)
      } catch (e) {
        if (e.request && e.request.result && e.request.result.status === 404) {
          this.$naytaVirheilmoitus('Laskua ei löytynyt!')

          this.$router.replace({ name: 'error404' })
        } else {
          this.tietueLatausEpaonnistunut = true
          this.$oletusVirheenkasittely(e, 'Tietojen lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen.')
        }
      } finally {
        this.tietueLataamassa = false
      }
    },

    async haeLaskukopionLahetyksenLaskupohjat () {
      try {
        if (!this.$permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku_laskukopio')) return

        await this.$store.dispatch('laskutusNayta/haeLaskukopionLahetyksenLaskupohjat', this.$route)
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Laskukopion lähettämiseen tarvittavien laskupohjien haku ei onnistunut! Yritä ladata sivu hetken kuluttua uudelleen, mikäli haluat lähettää laskukopion.')
      }
    },

    avaaDialogLaskukopio () {
      this.lahetaLaskukopioDialogAuki = true
    },

    async lahetaLaskukopioSubmit () {
      try {
        this.lahetaLaskukopioFormLoading = true
        await this.$store.dispatch('laskutusNayta/lahetaLaskukopioSubmit')
        if (this.laskutusNayta.lahetaLaskukopioFormData.lahetystapa === 'manuaali') {
          this.$naytaOnnistumisilmoitus('Laskukopio tallennettu.')
        } else {
          this.$naytaOnnistumisilmoitus('Laskukopio lisätty lähetysjonoon.')
        }
        this.lahetaLaskukopioDialogAuki = false
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.lahetaLaskukopioFormLoading = false
      }
    },

    avaaDialogHyvita () {
      this.hyvitaDialogAuki = true
    },

    avaaDialogLiitteet () {
      this.$store.commit('laskutusNayta/setLiitteetFormData', {})
      this.liitteetDialogAuki = true
    },

    avaaDialogMitatoi () {
      this.$store.commit('laskutusNayta/setMitatoiFormData', {})
      this.mitatoiDialogAuki = true
    },

    avaaDialogOhisuoritus () {
      this.$store.dispatch('laskutusNayta/alustaDialogOhisuoritus')

      this.ohisuoritusDialogAuki = true
    },

    avaaDialogPerinnanEsto () {
      this.perinnanEstoDialogAuki = true
    },

    avaaDialogErapaivansiirto () {
      this.erapaivanSiirtoDialogAuki = true
    },

    async liitteetSubmit () {
      try {
        this.liitteetFormLoading = true
        const liitteetLkm = await this.$store.dispatch('laskutusNayta/liitteetSubmit')
        const onnistumisTeksti = liitteetLkm === 1 ? 'Liite onnistuneesti lisätty.' : 'Liitteet onnistuneesti lisätty.'
        this.$naytaOnnistumisilmoitus(onnistumisTeksti)
        this.liitteetDialogAuki = false
      } catch (e) {
          let virheviesti = ''

          if (e.name === this.$SallittuKokoYlitettyError.name) {
            virheviesti = this.$t('common:attachmentSizeExceeded', { filename: e.extra })
          } else {
            virheviesti = this.$t('common:attachmentSendingFailed')
          }

          this.$oletusVirheenkasittely(e, {
            virheviesti,
            tiedosto: e.extra,
          })
      } finally {
        this.liitteetFormLoading = false
      }
    },

    paivitaLasku (paivitettyLasku) {
      this.$store.commit('laskutusNayta/setLasku', paivitettyLasku)
    },

    async ohisuoritusSubmit () {
      try {
        this.ohisuoritusFormLoading = true
        await this.$store.dispatch('laskutusNayta/ohisuoritusSubmit')
        this.$naytaOnnistumisilmoitus('Ohisuoritus on kirjattu.')
        this.ohisuoritusDialogAuki = false
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.ohisuoritusFormLoading = false
      }
    },

    avaaDialogViestit () {
      this.uusiViestiDialogAuki = true
      this.$store.commit('laskutusNayta/setUusiViestiFormData', {})
    },

    async viestiSubmit () {
      try {
        this.uusiViestiFormLoading = true
        await this.$store.dispatch('laskutusNayta/viestiSubmit')
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.uusiViestiFormLoading = false
      }
    },

    async muistiinpanoSubmit () {
      try {
        this.uusiMuistiinpanoFormLoading = true
        await this.$store.dispatch('laskutusNayta/muistiinpanoSubmit')
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.uusiMuistiinpanoFormLoading = false
      }
    },

    avaaDialogMuistiinpanot () {
      this.uusiMuistiinpanoDialogAuki = true
      this.$store.commit('laskutusNayta/setUusiMuistiinpanoFormData', {})
    },

    siirryLaskunMuokkaukseen () {
      this.$store.dispatch('laskutusNayta/siirryLaskunMuokkaukseen', this.$router)
    },

    async hyvita () {
      try {
        this.hyvitaFormLoading = true
        await this.$store.dispatch('laskutusNayta/hyvita')
        this.$naytaOnnistumisilmoitus('Hyvitys kirjattu laskulle.')
        this.hyvitaDialogAuki = false
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.hyvitaFormLoading = false
      }
    },
    async mitatoi() {
      try {
        this.mitatoiFormLoading = true
        await this.$store.dispatch('laskutusNayta/mitatoi')
        this.$naytaOnnistumisilmoitus('Lasku on mitätöity.')
        this.mitatoiDialogAuki = false
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.mitatoiFormLoading = false
      }
    },
    async perinnanEstoSubmit () {
      try {
        this.perinnanEstoFormLoading = true
        await this.$store.dispatch('laskutusNayta/perinnanEstoSubmit')
        this.$naytaOnnistumisilmoitus('Perinnän esto asetettu.')
        this.perinnanEstoDialogAuki = false
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.perinnanEstoFormLoading = false
      }
    },
    async salliPerinta () {
      try {
        await this.$store.dispatch('laskutusNayta/salliPerinta')
        this.$naytaOnnistumisilmoitus('Perinnän esto poistettu.')
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
    async erapaivanSiirtoSubmit () {
      try {
        this.erapaivanSiirtoFormLoading = true
        await this.$store.dispatch('laskutusNayta/erapaivanSiirtoSubmit')
        this.$naytaOnnistumisilmoitus('Laskun eräpäivää siirretty.')
        this.erapaivanSiirtoDialogAuki = false
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.erapaivanSiirtoFormLoading = false
      }
    },
  },
}

</script>

<style lang="scss" scoped>

  .columnSet {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    &--toimeksianto {
      flex-direction: row;

      > .column {
        &:first-child {
          flex: 0 0 70%;
          background: red;
        }

        &:last-child {
          flex: 1 1 auto;
          background: blue;
        }
      }
    }
  }

</style>
