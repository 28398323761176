var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "laskutus-tuo" },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "v-toolbar--sticky pl-6",
          attrs: { color: "primary", flat: "", tabs: "" },
        },
        [
          _c(
            "v-btn",
            { attrs: { to: { name: "laskutus-selaa" }, icon: "" } },
            [_c("v-icon", [_vm._v("arrow_back")])],
            1
          ),
          _vm.$route.meta.title
            ? _c("v-toolbar-title", [
                _vm._v(" " + _vm._s(_vm.$route.meta.title) + " "),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }