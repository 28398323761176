<template>
  <div class="laskutus-uusi grey lighten-2">
    <!-- Top bar -->
    <div class="sticky">
      <v-toolbar
        class="v-toolbar--sticky pl-6"
        color="primary"
        flat
        tabs
        :dark="true"
      >
        <v-btn
          icon
          @click="$router.go(-1)"
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title v-if="$route.meta.title">
          {{ $route.meta.title }}
        </v-toolbar-title>
      </v-toolbar>
    </div>


    <div class="px-6 py-12 main-div">
      <!-- Laskun luomisen jälkeinen valikko -->
      <template v-if="luotuLasku.id">
        <v-container class="max-width--1200 px-6 py-4 mx-auto mb-12">
          <v-row
            justify="center"
            class="pa-6"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  icon="invoice"
                  size="large"
                  :hover-icon="('open-in-new')"
                  bg-color="secondary"
                  :event-listener="on"
                  @click="$router.push({ name: 'laskutus-nayta', params: { id: luotuLasku.id } })"
                />
              </template>
              <template v-slot:default>
                <span>Avaa lasku</span>
              </template>
            </v-tooltip>
          </v-row>
          <v-row
            justify="center"
            class="pa-6"
          >
            <span class="title">{{ laskuTallennettuTeksti }} <router-link :to="{ name: 'laskutus-nayta', params: { id: luotuLasku.id } }">{{ luotuLasku.nro }}</router-link>.
            </span>
          </v-row>
          <v-row
            justify="center"
            class="pa-6"
          >
            <v-btn
              large
              :to="{ name: 'laskutus-selaa' }"
              class="mr-2"
            >
              Palaa laskujen hakuun
            </v-btn>
            <v-btn
              large
              color="success"
              class="ml-2"
              @click="palautaAlkutilaan"
            >
              Luo uusi lasku
            </v-btn>
          </v-row>
        </v-container>
      </template>


      <!-- Laskun tiedot -->
      <template v-else>
        <div class="paper px-6 py-12 mb-12">
          <FormLasku
            ref="formLasku"
            v-model="lasku"
            @avaa-dialog-asiakas="avaaDialogAsiakas"
            @avaa-dialog-asiakas-muistiinpanot="avaaDialogAsiakasMuistiinpanot"
            @avaa-dialog-toimitusosoite="avaaDialogToimitusosoite"
            @pohjatVastaanotettu="kasittelePohjat"
            @muokkaus="naytaPoistumisvahvistus = true"
            @paamiesKasitteleValinta="paamiesKasitteleValinta"
          />
        </div>

        <!-- Lähetys / Maksutapa -->
        <div class="paper px-9 py-3 mb-6">
          <v-row>
            <v-col
              md="6"
              class="pa-6"
            >
              <template v-if="naytaMaksutapa">
                <h2 class="headline mb-6">
                  Maksutapa
                </h2>
                <v-radio-group
                  v-model="lasku.maksutapa"
                  row
                  class="mt-0"
                  :error-messages="maksutapaErrors"
                >
                  <v-radio
                    v-for="maksutapa in maksutavat"
                    :key="maksutapa.index"
                    :label="maksutapa.label"
                    :value="maksutapa.value"
                  />
                </v-radio-group>
              </template>
              <template v-else>
                <h2 class="headline mb-6">
                  Lähetys
                </h2>
                <h3 class="subtitle-1">
                  Lähetystapa
                </h3>
                <v-switch
                  v-model="lasku.luonnos"
                  label="Tallenna laskuluonnos (ei lähetystä)"
                  :disabled="onLahetelasku"
                />
                <div v-if="!lasku.luonnos">
                  <v-radio-group
                    v-model="lasku.lahetystapa"
                    row
                    class="mt-0"
                    :error-messages="lahetystapaErrors"
                  >
                    <v-radio
                      v-for="lahetystapa in lahetystavatHintoineen"
                      :key="lahetystapa.index"
                      :label="lahetystapa.label"
                      :value="lahetystapa.value"
                      :disabled="onkoLahetystapaDisabloitu(lahetystapa.value, lasku.asiakas)"
                    />
                  </v-radio-group>

                  <h3 class="subtitle-1">
                    Lähetyspäivä
                  </h3>
                  <v-menu
                    v-model="lahetyspaivaMenu"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="$date(lasku.haluttu_lahetyspaiva)"
                        placeholder="Tänään"
                        persistent-placeholder
                        readonly
                        :disabled="(!lasku.kirjepohja_id)"
                        v-on="on"
                      />
                    </template>
                    <template v-slot:default>
                      <v-date-picker
                        v-model="lasku.haluttu_lahetyspaiva"
                        locale="fi-FI"
                        first-day-of-week="1"
                        no-title
                        :disabled="(!lasku.kirjepohja_id)"
                        @change="lahetyspaivaMenu = false"
                      />
                    </template>
                  </v-menu>
                  <v-card
                    v-if="lasku.paamies.id && !lasku.kirjepohja_id"
                    class="pa-4"
                    color="warning"
                  >
                    <span class="subtitle-1">Huom.! Laskulle ei ole määritetty laskupohjaa. Sitä ei lähetetä.</span>
                  </v-card>
                </div>
              </template>
            </v-col>
            <v-col
              md="6"
              class="pa-6"
            >
              <h2 class="headline mb-6 ml-0">
                Liitteet
              </h2>

              <DataTableLaskuliite
                v-if="lasku.laskuliite_set.length"
                :items="lasku.laskuliite_set"
                :lasku="lasku"
              />
              <v-file-input
                v-model="lasku.tiedostot"
                chips
                counter="5"
                multiple
                :error-messages="liiteErrors"
                :rules="[$validationRules.required, $validationRules.smallerThanOrEqualTo(5, 5)]"
                title="title"
                placeholder="Lisää liitteitä..."
                hint="Huom.! Tiedoston tulee olla tyyppiä jpeg, gif, png tai pdf. Kuvatiedostot muunnetaan pdf:ksi."
                persistent-placeholder
                :validate-on-blur="(true)"
              />
            </v-col>
          </v-row>
        </div>

        <!-- Tallennus ja esikatselu -->
        <v-row
          justify="center"
          class="max-width--1200 px-6 py-4 mx-auto mb-12"
        >
          <v-btn
            large
            class="mx-5"
            @click="haeEsikatseluPdf"
          >
            Esikatselu
          </v-btn>
          <v-btn
            large
            color="success"
            class="mx-5"
            @click="luoLasku"
          >
            {{ luontiPainikkeenTeksti }}
          </v-btn>
        </v-row>
      </template>
    </div>


    <!-- Dialog: Uusi asiakas / muokkaa asiakasta -->
    <CardDialog
      :title="lasku.asiakas.id ? 'Muokkaa asiakasta' :'Uusi asiakas'"
      :dialog-auki="asiakasDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="1024"
      @close="(asiakasDialogAuki = false)"
    >
      <FormAsiakas
        :kaare-objekti="lasku"
        :kenttien-valinnat="kenttienValinnat"
        :on-auki="asiakasDialogAuki"
        @close="(asiakasDialogAuki = false)"
        @asiakasTallennettu="paivitaAsiakas"
      />
    </CardDialog>

    <!-- Dialog: Asiakkaaseen liittyvät muistiinpanot -->
    <CardDialog
      title="Asiakkaaseen liittyvät muistiinpanot"
      :dialog-auki="asiakasMuistiinpanotDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4 pb-12"
      width="1024"
      @close="(asiakasMuistiinpanotDialogAuki = false)"
    >
      <AsiakasMuistiinpanotDatatable
        :items="muistiinpanoItems"
      />
    </CardDialog>

    <!-- Dialog: Toimitusosoite -->
    <CardDialog
      title="Toimitusosoite"
      :dialog-auki="toimitusosoiteDialogAuki"
      @close="(toimitusosoiteDialogAuki = false)"
    >
      <FormToimitusosoite
        class="pa-4"
        :lasku="lasku"
        :kenttien-valinnat="kenttienValinnat"
        :on-auki="toimitusosoiteDialogAuki"
        @close="(toimitusosoiteDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Esikatselu -->
    <CardDialog
      content-class="px-8 pb-0"
      class="px-0 py-0 full-height"
      :dialog-auki="esikatseluDialogAuki"
      title="Esikatselu"
      width="1200"
      @close="(esikatseluDialogAuki = false)"
    >
      <div
        v-show="esikatseluPdfData"
        class="esikatselu"
      >
        <object
          :data="esikatseluPdfData"
          height="100%"
          width="100%"
        />
      </div>
      <v-card
        v-if="esikatseluVaroitus"
        class="pa-4"
        color="warning"
      >
        <span class="subtitle-1">{{ esikatseluVaroitus }}</span>
      </v-card>
      <v-row
        justify="end"
        class="max-width--1200 px-0 py-4 mx-auto mb-0"
      >
        <v-btn
          large
          class="mr-4"
          @click="(esikatseluDialogAuki = false)"
        >
          Sulje
        </v-btn>
        <v-btn
          large
          color="success"
          class="mx-0"
          @click="luoLasku"
        >
          Luo lasku
        </v-btn>
      </v-row>
    </CardDialog>
    <!-- <template :v-if="process.env.VUE_APP_DEV_MODE== 1"><pre>{{ lasku }}</pre></template> -->
  </div>
</template>

<script>

import _ from 'lodash'
import { Asiakas, AsiakasPostiosoite } from '@/class/Asiakas'
import { Lasku } from '@/class/Lasku'
import {
  AsiakasMuistiinpanotDatatable,
  FormLasku,
  FormAsiakas,
  FormToimitusosoite,
} from '@/components'
import { onkoLahetystapaDisabloitu, paatteleTiedostonMimetype } from '@/utils/misc'
import { TableHeadersAsiakasMuistiinpanotLaskunLuonnille } from '@/utils/tableHeaders'
import PoistumisvahvistusMixin from '@/mixins/PoistumisvahvistusMixin'
import { MaksimiUploadTiedostoKoko } from '@/utils/constants'


export default {
  name: 'LaskutusUusi',
  components: {
    AsiakasMuistiinpanotDatatable,
    FormLasku,
    FormAsiakas,
    FormToimitusosoite,
  },
  mixins: [PoistumisvahvistusMixin],
  data () {
    return {
      asiakasDialogAuki: false,
      asiakasMuistiinpanotDialogAuki: false,
      esikatseluDialogAuki: false,
      esikatseluPdfData: null,
      esikatseluVaroitus: '',
      kenttienValinnat: {},
      kuittipohjat: [],
      lahetepohjat: [],
      lahetyspaivaMenu: false,
      lahetystapaErrors: [],
      lahetystavat: Lasku.LAHETYSTAVAT,
      lahetystavatHintoineen: [],
      lasku: new Lasku(true),
      liiteErrors: [],
      luotuLasku: {},
      maksutapaErrors: [],
      maksutavat: [
        {
          value: 'kateinen',
          label: 'Käteinen'
        },
        {
          value: 'pankkikortti',
          label: 'Pankkikortti'
        },
      ],
      tableHeadersAsiakasMuistiinpanot: TableHeadersAsiakasMuistiinpanotLaskunLuonnille,
      toimitusosoiteDialogAuki: false,
    }
  },
  computed: {
    laskuTallennettuTeksti () {
      let laskuTeksti = this.lasku.luonnos ? 'Luonnos' : 'Lasku'
      laskuTeksti += ' tallennettu laskunumerolla'
      return laskuTeksti
    },
    luontiPainikkeenTeksti () {
      return this.lasku.luonnos ? 'Tallenna luonnos' : 'Luo lasku'
    },
    muistiinpanoItems () {
      if (!this.lasku.asiakas.muistiinpano_set) return []

      const muistiinpanot = this.lasku.asiakas.muistiinpano_set.filter(item => {
        return !item.paamiesviesti
      })

      return muistiinpanot.map(item => {
        return {
          lahettaja: item.lahettaja.username,
          aika: item.aika,
          lasku: item.lasku,
          sisalto: item.data,
        }
      })
    },
    naytaMaksutapa () {
      return this.kuittipohjat.includes(this.lasku.kirjepohja_id)
    },
    onLahetelasku () {
      return this.lahetepohjat.includes(this.lasku.kirjepohja_id)
    },
    warningStyle () {
      return `color: ${this.$vuetify.theme.warning}`
    },
  },
  watch: {
    lasku: {
      deep: true,
      handler () {
        if (this.odotusaikaYlitetty) this.$emit('muokkaus')
      }
    },
    'lasku.luonnos': {
      handler () {
        if (this.lasku.luonnos) {
          this.lasku.lahetystapa = 'luonnos'
        } else {
          this.lasku.lahetystapa = ''
        }
      }
    },
    'lasku.asiakas.id': {
      async handler (id) {
        await this.haeAsiakkaanKenttienValinnat(id)
        this.lasku.toimitusosoite = new AsiakasPostiosoite()
      }
    },
    'lasku.kirjepohja_id': {
      handler () {
        if (this.onLahetelasku) {
          this.lasku.luonnos = true
        }
      }
    },
    'lasku.lahetystapa': {
      handler (val) {
        if (val) {
          this.lahetystapaErrors = []
        }
      }
    },
    'lasku.tiedostot': {
      handler (val) {
        if (val) {
          this.liiteErrors = []
        }
      }
    }
  },
  created () {
    this.haeAsiakkaanKenttienValinnat()
  },
  mounted () {
    this.alustaLahetystavatHintoineen()
  },
  methods: {
    paamiesKasitteleValinta () {
      this.alustaLahetystavatHintoineen()
    },
    alustaLahetystavatHintoineen () {
      this.lahetystavatHintoineen = []
      try {
        if (!this.lasku.paamies?.porrashinnasto_set?.length) {
          // Ei aseteta hintoja jos hinnastoa ei löydy
          this.lahetystavatHintoineen = Object.assign([], Lasku.LAHETYSTAVAT)
          return
        }

        Lasku.LAHETYSTAVAT.map((lahetystapa => {
          const lt = Object.assign({}, lahetystapa)
          lt.label = this.$t('common:invoiceSend.sendMethods.' + lahetystapa.value)
          const hinnasto = _.find(this.lasku.paamies.porrashinnasto_set, ['koodi', lahetystapa.koodi])
          const hinta = hinnasto ? this.$formatSum(hinnasto.ahinta) : this.$formatSum(0)
          lt.label = lahetystapa.label + ' ' + hinta
          this.lahetystavatHintoineen.push(lt)
        }))
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Laskun lähetystapojen alustus epäonnistui')
      }
    },
    async luoLasku () {
      if (!(await this.validoiLasku())) return

      this.esikatseluDialogAuki = false

      try {
        await this.lahetaLasku()
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Laskun tallennus epäonnistui')

        return
      }

      try {
        await this.lahetaLiitteet()
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          'Lasku on tallennettu, mutta liitteiden tallennus epäonnistui! Yritä liitteiden tallennusta uudelleen laskunäkymän kautta.'
        )

        return
      }

      this.$naytaOnnistumisilmoitus('Lasku tallennettu!')
    },
    async lahetaLasku () {
      const request = await this.$doRequestWithTimeout(
        this.$api.Laskut,
        {
          method: 'POST',
          url: '',
          body: this.lasku.getPostData(),
        },
        'doSingleRequest',
      )

      if (!request.success) throw new this.$HttpError(request)

      this.luotuLasku = request.result.body
      this.naytaPoistumisvahvistus = false
    },
    async lahetaLiitteet () {
      const formData = new FormData()

      for (var tiedosto of this.lasku.tiedostot) {
        formData.append(tiedosto.name, tiedosto)
      }

      const request = await this.$doRequestWithTimeout(
        this.$api.Lasku,
        {
          method: 'POST',
          url: 'toiminnot/tuo_liite/',
          body: formData,
          params: {
            id: this.luotuLasku.id
          }
        },
        'doSingleRequest',
      )

      if (!request.success) throw new this.$HttpError(request)
    },
    async validoiLasku () {
      let lomakeValidi = true

      if (!this.lasku.lahetystapa && !this.naytaMaksutapa && !this.onLahetelasku) {
        this.lahetystapaErrors = ['Valitse lähetystapa tai luonnoksena tallentaminen!']
        lomakeValidi = false
      }

      if (this.naytaMaksutapa && !this.lasku.maksutapa) {
        this.maksutapaErrors = ['Valitse maksutapa!']
        lomakeValidi = false
      }

      if (this.lasku.tiedostot.length > 5) {
        this.liiteErrors = [this.$t('common:attachmentTooMany')]
        lomakeValidi = false
      }

      let liianSuurenTiedostonNimi = ''
      const sallittuTiedostoKokoYlitetty = this.lasku.tiedostot.some(tiedosto => {
        liianSuurenTiedostonNimi = tiedosto.name
        return tiedosto.size > MaksimiUploadTiedostoKoko
      })
      if (sallittuTiedostoKokoYlitetty) {
        this.liiteErrors = [this.$t('common:attachmentSizeExceeded', { filename: liianSuurenTiedostonNimi })]
        lomakeValidi = false
      }

      for (const tiedosto of this.lasku.tiedostot) {
        const mimeType = await paatteleTiedostonMimetype(tiedosto)
        if (
          mimeType !== 'tuntematon' &&
          !['image/jpeg', 'image/gif', 'image/png', 'application/pdf'].includes(mimeType)
        ) {
          this.liiteErrors = ['Tiedoston tulee olla tyyppiä jpeg, gif, png tai pdf']
          lomakeValidi = false
        }
      }

      if (!this.$refs.formLasku.validate()) {
        this.$naytaVirheilmoitus('Tarkista kenttien sisältö!')
        lomakeValidi = false
      }

      return lomakeValidi
    },
    avaaDialogAsiakas () {
      this.asiakasDialogAuki = true
    },
    avaaDialogAsiakasMuistiinpanot () {
      this.asiakasMuistiinpanotDialogAuki = true
    },
    avaaDialogToimitusosoite () {
      this.toimitusosoiteDialogAuki = true
    },
    onkoLahetystapaDisabloitu (lahetystapa, asiakas) {
      return onkoLahetystapaDisabloitu (lahetystapa, asiakas)
    },
    async haeEsikatseluPdf () {
      if (!(await this.validoiLasku())) return

      try {
        const request = await this.$doRequestWithTimeout(
        this.$api.Laskut,
          {
            method: 'POST',
            url: 'uusi/esikatselu/',
            body: this.lasku.getPostData()
          },
          'doSingleRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        const responseData = request.result.body

        if (typeof responseData === 'string' && responseData.includes('Varoitus')) {
          this.esikatseluVaroitus = responseData
          this.esikatseluDialogAuki = true
        } else {
          const blob = new Blob([responseData], { type: 'application/pdf' })
          this.esikatseluPdfData = window.URL.createObjectURL(blob)
          this.esikatseluDialogAuki = true
        }
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Esikatselun muodostaminen epäonnistui. Yritä hetken kuluttua uudelleen.')
      }
    },
    async haeAsiakkaanKenttienValinnat (asiakas_id = '') {
      this.kenttienValinnat = await Asiakas.haeAsiakkaanKenttienValinnat(asiakas_id)
    },
    kasittelePohjat (pohjat) {
      this.kuittipohjat = pohjat.kuittipohjat
      this.lahetepohjat = pohjat.lahetepohjat
    },
    palautaAlkutilaan () {
      this.luotuLasku = {}
      this.lasku = new Lasku(true)
    },
    paivitaAsiakas (asiakas) {
      this.lasku.asiakas = asiakas
      this.$refs.formLasku.paivitaAsiakkaanOletusvalinnat()
    },
  },
}

</script>

<style lang="scss" scoped>

.full-height {
  height: 100%;
}

.esikatselu {
  height: 60vh;
}

.main-div {
  min-height: calc(100vh - 64px);
}

</style>
