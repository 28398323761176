var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "asetukset" },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "v-toolbar--sticky",
          attrs: { color: "primary", flat: "", tabs: "" },
          scopedSlots: _vm._u([
            {
              key: "extension",
              fn: function () {
                return [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "background-color": "primary",
                        "slider-color": "grey lighten-5",
                        centered: "",
                      },
                      model: {
                        value: _vm.selectedTab,
                        callback: function ($$v) {
                          _vm.selectedTab = $$v
                        },
                        expression: "selectedTab",
                      },
                    },
                    _vm._l(_vm.tabs, function (item) {
                      return _c(
                        "v-tab",
                        {
                          key: item.index,
                          attrs: { href: "#" + item.id, ripple: false },
                        },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.$route.meta.title
            ? _c("v-toolbar-title", [
                _vm._v(" " + _vm._s(_vm.$route.meta.title) + " "),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container mt-4" },
        [
          !_vm.paamiehet || !_vm.paamiehet.length
            ? _c("Spinner", {
                attrs: {
                  "div-class": "text-center mt-12",
                  justify: "center",
                  teksti: "Ladataan päämiestietoja",
                },
              })
            : [
                !_vm.user.vainYksiPaamies
                  ? _c(
                      "v-card",
                      {
                        staticClass: "pa-12 mb-8",
                        attrs: { raised: "", rounded: "" },
                      },
                      [
                        _c("CardTitle", {
                          attrs: {
                            color: "black--text",
                            text: "Päämies",
                            center: true,
                          },
                        }),
                        _c("v-autocomplete", {
                          attrs: {
                            items: _vm.paamiehet,
                            "search-input": _vm.paamiesSearchQuery,
                            "hide-no-data": "",
                            "item-text": _vm.getPaamiesNimi,
                            "item-value": "id",
                            placeholder: "Kirjoita hakusana",
                            "persistent-placeholder": "",
                            "return-object": "",
                            outlined: "",
                            "hide-details": "",
                          },
                          on: {
                            "update:searchInput": function ($event) {
                              _vm.paamiesSearchQuery = $event
                            },
                            "update:search-input": function ($event) {
                              _vm.paamiesSearchQuery = $event
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            _vm._s(item.nimi) +
                                              " (" +
                                              _vm._s(item.id) +
                                              ")"
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            322820903
                          ),
                          model: {
                            value: _vm.valittavaPaamies,
                            callback: function ($$v) {
                              _vm.valittavaPaamies = $$v
                            },
                            expression: "valittavaPaamies",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.lataamassa
                  ? _c("Spinner", {
                      attrs: {
                        "div-class": "text-center mt-12",
                        justify: "center",
                        teksti: _vm.$t("common:loadingData"),
                      },
                    })
                  : [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-12",
                          attrs: {
                            raised: "",
                            rounded: "",
                            "min-height": "256",
                          },
                        },
                        [
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.selectedTab,
                                callback: function ($$v) {
                                  _vm.selectedTab = $$v
                                },
                                expression: "selectedTab",
                              },
                            },
                            [
                              _c("FormAsetuksetYhteystiedot", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.selectedTab === "yrityksen_tiedot",
                                    expression:
                                      "selectedTab === 'yrityksen_tiedot'",
                                  },
                                ],
                              }),
                              _vm.palvelusopimuksetKaytossa
                                ? _c("PalvelusopimusKayttajatili", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.selectedTab === "kayttajatili",
                                        expression:
                                          "selectedTab === 'kayttajatili'",
                                      },
                                    ],
                                  })
                                : _vm._e(),
                              _c("Laskutusasetukset", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.selectedTab === "laskutusasetukset",
                                    expression:
                                      "selectedTab === 'laskutusasetukset'",
                                  },
                                ],
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.selectedTab === "yleiset",
                                      expression: "selectedTab === 'yleiset'",
                                    },
                                  ],
                                },
                                [
                                  _c("h2", { staticClass: "my-4" }, [
                                    _vm._v("Kieli (2. vaihe)"),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.selectedTab === "kayttajahallinta",
                                      expression:
                                        "selectedTab === 'kayttajahallinta'",
                                    },
                                  ],
                                },
                                [
                                  _c("h2", { staticClass: "my-4" }, [
                                    _vm._v("Käyttäjät (2. vaihe)"),
                                  ]),
                                  _c("v-skeleton-loader", {
                                    attrs: {
                                      boilerplate: "",
                                      height: 400,
                                      type: "table: table-heading, table-thead, table-tbody, table-tfoot",
                                    },
                                  }),
                                  _c("v-btn", { attrs: { color: "success" } }, [
                                    _vm._v(" Lisää käyttäjä "),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.selectedTab === "ohjeet",
                                      expression: "selectedTab === 'ohjeet'",
                                    },
                                  ],
                                },
                                [
                                  _c("h2", { staticClass: "my-4" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "black--text",
                                        on: {
                                          click: function () {
                                            return (_vm.UKKAuki = true)
                                          },
                                        },
                                      },
                                      [_vm._v("Usein kysytyt kysymykset (UKK)")]
                                    ),
                                  ]),
                                  _c("h2", { staticClass: "my-4" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "black--text",
                                        on: {
                                          click: function () {
                                            return (_vm.tietosuojaselosteAuki = true)
                                          },
                                        },
                                      },
                                      [_vm._v("Tietosuojaseloste")]
                                    ),
                                  ]),
                                  _c("h2", { staticClass: "my-4" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "black--text",
                                        on: {
                                          click: function () {
                                            return (_vm.palveluehdotAuki = true)
                                          },
                                        },
                                      },
                                      [_vm._v("Palveluehdot")]
                                    ),
                                  ]),
                                  _c("v-divider", { staticClass: "my-6" }),
                                  _c("h2", { staticClass: "my-4" }, [
                                    _vm._v("Ohjevideot (2. vaihe)"),
                                  ]),
                                  _c("v-skeleton-loader", {
                                    attrs: {
                                      boilerplate: "",
                                      height: "200",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
              ],
        ],
        2
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            "dialog-auki": _vm.UKKAuki,
            title: _vm.$t("frequentlyAskedQuestions") + " " + _vm.$t("FAQ"),
            width: "70%",
          },
          on: {
            close: function ($event) {
              _vm.UKKAuki = false
            },
          },
        },
        [_c("p", [_vm._v("Ei sulla mitään kysyttävää ole.")])]
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            "dialog-auki": _vm.palveluehdotAuki,
            title: _vm.$t("uuvaCloudTermsOfService"),
            width: "70%",
          },
          on: {
            close: function ($event) {
              _vm.palveluehdotAuki = false
            },
          },
        },
        [
          _c("iframe", {
            staticClass: "teksti-iframe",
            attrs: {
              src: "/palveluehdot.html",
              width: "100%",
              sandbox: "allow-same-origin allow-scripts",
            },
          }),
        ]
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            "dialog-auki": _vm.tietosuojaselosteAuki,
            title: _vm.$t("privacyPolicy"),
            width: "70%",
          },
          on: {
            close: function ($event) {
              _vm.tietosuojaselosteAuki = false
            },
          },
        },
        [
          _c("iframe", {
            staticClass: "teksti-iframe",
            attrs: {
              src: "/tietosuojaseloste.html",
              width: "100%",
              sandbox: "allow-same-origin allow-scripts",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }