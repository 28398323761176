<template>
  <div class="asetukset">
    <v-toolbar class="v-toolbar--sticky" color="primary" flat tabs>
      <v-toolbar-title v-if="$route.meta.title">
        {{ $route.meta.title }}
      </v-toolbar-title>

      <template v-slot:extension>
        <v-tabs
          v-model="selectedTab"
          background-color="primary"
          slider-color="grey lighten-5"
          centered
        >
          <v-tab
            v-for="item in tabs"
            :key="item.index"
            :href="`#${item.id}`"
            :ripple="false"
          >
            {{ item.label }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <div class="container mt-4">
      <Spinner
        v-if="!paamiehet || !paamiehet.length"
        div-class="text-center mt-12"
        justify="center"
        teksti="Ladataan päämiestietoja"
      />
      <template v-else>
        <v-card v-if="!user.vainYksiPaamies" raised rounded class="pa-12 mb-8">
          <CardTitle color="black--text" text="Päämies" :center="true" />
          <v-autocomplete
            v-model="valittavaPaamies"
            :items="paamiehet"
            :search-input.sync="paamiesSearchQuery"
            hide-no-data
            :item-text="getPaamiesNimi"
            item-value="id"
            placeholder="Kirjoita hakusana"
            persistent-placeholder
            return-object
            outlined
            hide-details
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title
                  >{{ item.nimi }} ({{ item.id }})</v-list-item-title
                >
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card>
        <Spinner
          v-if="lataamassa"
          div-class="text-center mt-12"
          justify="center"
          :teksti="$t('common:loadingData')"
        />
        <template v-else>
          <v-card raised rounded min-height="256" class="pa-12">
            <v-tabs-items v-model="selectedTab">
              <!--
              FIXME v-tab-item-elementtien käyttö aiheuttaa kaikkien sisällä olevien komponenttien
              uudelleenlatauksen aina tabia vaihdettaessa
              <v-tab-item
                v-for="item in tabs"
                :key="item.index"
                :value="`${item.id}`"
              >
              -->
              <!-- Yrityksen tiedot -->
              <FormAsetuksetYhteystiedot
                v-show="selectedTab === 'yrityksen_tiedot'"
              />

              <!-- Palvelusopimukseen liittyvät käyttäjätilin tiedot -->
              <PalvelusopimusKayttajatili
                v-if="palvelusopimuksetKaytossa"
                v-show="selectedTab === 'kayttajatili'"
              />

              <!-- Laskutuksen tiedot -->
              <Laskutusasetukset v-show="selectedTab === 'laskutusasetukset'" />

              <!-- Yleiset (kieli tms.) -->
              <div v-show="selectedTab === 'yleiset'">
                <h2 class="my-4">Kieli (2. vaihe)</h2>
              </div>

              <!-- Käyttäjähallinta -->
              <div v-show="selectedTab === 'kayttajahallinta'">
                <h2 class="my-4">Käyttäjät (2. vaihe)</h2>
                <v-skeleton-loader
                  boilerplate
                  :height="400"
                  type="table: table-heading, table-thead, table-tbody, table-tfoot"
                />
                <v-btn color="success"> Lisää käyttäjä </v-btn>
              </div>

              <!-- Ohjeet -->
              <div v-show="selectedTab === 'ohjeet'">
                <h2 class="my-4">
                  <a class="black--text" @click="() => (UKKAuki = true)"
                    >Usein kysytyt kysymykset (UKK)</a
                  >
                </h2>
                <h2 class="my-4">
                  <a
                    class="black--text"
                    @click="() => (tietosuojaselosteAuki = true)"
                    >Tietosuojaseloste</a
                  >
                </h2>
                <h2 class="my-4">
                  <a
                    class="black--text"
                    @click="() => (palveluehdotAuki = true)"
                    >Palveluehdot</a
                  >
                </h2>
                <v-divider class="my-6" />
                <h2 class="my-4">Ohjevideot (2. vaihe)</h2>
                <v-skeleton-loader boilerplate height="200" type="image" />
              </div>
              <!--
              </v-tab-item>
              -->
            </v-tabs-items>
          </v-card>
        </template>
      </template>
    </div>

    <CardDialog
      :dialog-auki="UKKAuki"
      :title="`${$t('frequentlyAskedQuestions')} ${$t('FAQ')}`"
      width="70%"
      @close="UKKAuki = false"
    >
      <p>Ei sulla mitään kysyttävää ole.</p>
      <!-- <iframe
        src="/ukk.html"
        width="100%"
        class="teksti-iframe"
        sandbox="allow-same-origin allow-scripts"
      /> -->
    </CardDialog>

    <CardDialog
      :dialog-auki="palveluehdotAuki"
      :title="$t('uuvaCloudTermsOfService')"
      width="70%"
      @close="palveluehdotAuki = false"
    >
      <iframe
        src="/palveluehdot.html"
        width="100%"
        class="teksti-iframe"
        sandbox="allow-same-origin allow-scripts"
      />
    </CardDialog>

    <CardDialog
      :dialog-auki="tietosuojaselosteAuki"
      :title="$t('privacyPolicy')"
      width="70%"
      @close="tietosuojaselosteAuki = false"
    >
      <iframe
        src="/tietosuojaseloste.html"
        width="100%"
        class="teksti-iframe"
        sandbox="allow-same-origin allow-scripts"
      />
    </CardDialog>
  </div>
</template>

<script>
import { Paamies } from "@/class/Paamies";
import {
  FormAsetuksetYhteystiedot,
  PalvelusopimusKayttajatili,
  Laskutusasetukset,
} from "@/components";
import { mapState } from "vuex";
import store from "@/store";
import { onkoModuuliKaytossa } from "@/utils/misc";

export default {
  name: "Asetukset",
  components: {
    FormAsetuksetYhteystiedot,
    Laskutusasetukset,
    PalvelusopimusKayttajatili,
  },
  data() {
    return {
      lataamassa: true,
      paamiehet: [],
      paamiesSearchQuery: "",
      palveluehdotAuki: false,
      selectedTab: "kayttajatiedot",
      tietosuojaselosteAuki: false,
      UKKAuki: false,
      valittavaPaamies: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      palvelusopimukset: (state) => state.asetukset.palvelusopimukset,
    }),
    valittuPaamies() {
      return this.user.valittuPaamies;
    },
    palvelusopimuksetKaytossa() {
      return (
        onkoModuuliKaytossa("palveluhakemus") &&
        this.$permission.checkSinglePerm(
          "onlinerestapi.onlinerestapi_palvelusopimus"
        )
      );
    },
    tabs() {
      let tabs = [
        {
          id: "yrityksen_tiedot",
          label: "Yrityksen tiedot",
        },
      ];
      if (this.palvelusopimuksetKaytossa) {
        tabs.push({
          id: "kayttajatili",
          label: "Käyttäjätili",
        });
      }
      if (
        this.$permission.checkSinglePerm("onlinerestapi.onlinerestapi_lasku")
      ) {
        tabs.push({
          id: "laskutusasetukset",
          label: "Laskutusasetukset",
        });
      }
      // Ominaisuudet ovat kesken. Tarkoituksena piilottaa välilehti muilta paitsi pääkäyttäjiltä ja
      // henkilökunnalta, kunnes ominaisuudet ovat valmiita.
      if (
        this.$permission.checkSinglePerm("onlinerestapi.onlinerestapi_beta")
      ) {
        tabs.push(
          {
            id: "yleiset",
            label: "Yleiset",
          },
          {
            id: "kayttajahallinta",
            label: "Käyttäjähallinta",
          }
        );
      }
      tabs.push({
        id: "ohjeet",
        label: "Ohjeet",
      });
      return tabs;
    },
  },
  watch: {
    valittavaPaamies: {
      handler(val, oldVal) {
        this.lataamassa = true;
        if (!val || val.id === oldVal.id) return;

        store.commit("user/setValittuPaamies", val);

        Promise.all([
          this.haePaamiehentiedot(val.id),
          this.haePaamiesosoitteet(),
          this.haeTilaukset(),
        ]).then(() => {
          this.lataamassa = false;
        });
      },
    },
  },
  created() {
    if (this.palvelusopimuksetKaytossa) {
      try {
        this.$store.dispatch("user/haePalvelusopimukset");
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t("common:settings.contractsFetchFailed")
        );
      }
    }

    this.haePaamiehet();
  },
  methods: {
    getPaamiesNimi(paamies) {
      if (this.$store.state.user.vainYksiPaamies) {
        return paamies.nimi;
      } else {
        return paamies.nimi + " (" + paamies.id + ")";
      }
    },
    async haePaamiehet() {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.UserPaamies,
          { url: "" }
        );

        if (!request.success) throw new this.$HttpError(request);

        this.paamiehet = request.result.body;

        // Täydennetään päämies autocompleten hakukenttään ja laukaistaan tarkempien tietojen haku
        if (this.valittuPaamies.id) {
          this.valittavaPaamies = this.paamiehet.find(
            (paamies) => paamies.id === this.valittuPaamies.id
          );
        } else if (this.paamiehet.length) {
          this.valittavaPaamies = this.paamiehet[0];
        }
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          "Tietojen lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen."
        );
      }
    },
    async haePaamiehentiedot(paamiesId) {
      await Paamies.haePaamiesTiedotLuontitoiminnoille(paamiesId);
    },
    async haePaamiesosoitteet() {
      try {
        await this.$store.dispatch("asetukset/haePaamiesosoitteet");
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t("common:settings.addressFetchFailed")
        );
      }
    },
    async haeTilaukset() {
      if (!this.palvelusopimuksetKaytossa) {
        return;
      }
      try {
        await this.$store.dispatch("asetukset/haeTilaukset");
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t("common:settings.subscriptionsFetchFailed")
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
