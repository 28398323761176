export default {
  data () {
    return {
      naytaPoistumisvahvistus: false,
      i18nextFunction: function(){},
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.naytaPoistumisvahvistus && to.name !== 'kirjaudu') {
      this.$swal({
        text: this.$t('common:beforeRouteLeave.text'),
        showCancelButton: true,
        focusCancel: true,
        cancelButtonText: this.$t('common:beforeRouteLeave.cancelButtonText'),
        confirmButtonText: this.$t('common:beforeRouteLeave.confirmButtonText'),
      }).then(result => {
        if (!result.value) return
        next()
      })
    } else {
      next()
    }
  }
}
