var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "laskutus-asiakas-nayta" },
    [
      _c(
        "ViewTopBarBase",
        { attrs: { title: _vm.routeTitle } },
        [
          _c("v-menu", {
            attrs: { bottom: "", left: "", "nudge-bottom": "40" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        { attrs: { color: "success", depressed: "" } },
                        on
                      ),
                      [
                        _vm._v(" " + _vm._s(_vm.$t("common:actions")) + " "),
                        _c("v-icon", { attrs: { right: "" } }, [
                          _vm._v(" more_vert "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function () {
                  return [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function ($event) {
                                _vm.asiakasDialogAuki = true
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("edit")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("common:editCustomer")) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          { on: { click: _vm.avaaDialogMuistiinpanot } },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("anitta-icon-note")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common:writeMemo")) + " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm.tietueLataamassa
        ? _c("Spinner")
        : _vm.tietueLatausEpaonnistunut
        ? _c("CardPahoittelu")
        : [
            _c("v-container", { staticClass: "pa-2", attrs: { fluid: "" } }, [
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  _c("TietueenPerustiedotCard", {
                    attrs: {
                      paamies: _vm.laskutusAsiakasNayta.asiakas.paamies,
                      vastapuoli: _vm.laskutusAsiakasNayta.asiakas,
                      "ei-vastapuoli-linkkia": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "col2",
                        fn: function () {
                          return [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-0 font-weight-bold grey--text darken-2",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("common:customerData.customerInfo")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "DefList",
                              { attrs: { bordered: true } },
                              [
                                _c("DefListItem", {
                                  attrs: {
                                    "label-class": "defList__label--emphasized",
                                    "value-class": "font-weight-bold",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("common:customerNo")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.laskutusAsiakasNayta.asiakas
                                                  .nro
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$sovellusIdOtsake) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.laskutusAsiakasNayta.asiakas
                                                  .id
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _vm.laskutusAsiakasNayta.asiakas.tunnus
                                  ? _c("DefListItem", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.tunnustyyppi) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.laskutusAsiakasNayta
                                                        .asiakas.tunnus
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        636522152
                                      ),
                                    })
                                  : _vm._e(),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("common:language")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.laskutusAsiakasNayta
                                                    .asiakas.kieli
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _vm.laskutusAsiakasNayta.asiakas.kotipaikka
                                  ? _c("DefListItem", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("common:homeTown")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.laskutusAsiakasNayta
                                                        .asiakas.kotipaikka
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        422105342
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "col3",
                        fn: function () {
                          return [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-0 font-weight-bold grey--text darken-2",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("common:contactInformation")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "DefList",
                              { attrs: { bordered: true } },
                              [
                                _c("DefListItem", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("common:phone")) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm.laskutusAsiakasNayta.asiakas
                                            .puhelin
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.laskutusAsiakasNayta
                                                      .asiakas.puhelin.numero
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _c("DefListItem", {
                                  attrs: {
                                    "value-class": "text-wrap break-all",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("common:email")) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.asiakasKaare.asiakas.emailosoite_set
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._l(
                                                  _vm.asiakasKaare.asiakas.emailosoite_set.slice(
                                                    0,
                                                    4
                                                  ),
                                                  function (email, idx) {
                                                    return [
                                                      email.email
                                                        ? _c(
                                                            "p",
                                                            {
                                                              key: idx,
                                                              staticClass:
                                                                "mb-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    email.email
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  }
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "common:eInvoiceAddress"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.laskutusAsiakasNayta.asiakas
                                        .verkkolaskuosoite &&
                                      _vm.laskutusAsiakasNayta.asiakas
                                        .verkkolaskuosoite.verkkolaskuosoite
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.laskutusAsiakasNayta
                                                        .asiakas
                                                        .verkkolaskuosoite
                                                        .verkkolaskuosoite
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm.laskutusAsiakasNayta.asiakas
                                  .verkkolaskuosoite &&
                                _vm.laskutusAsiakasNayta.asiakas
                                  .verkkolaskuosoite.operaattori
                                  ? _c("DefListItem", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common:eInvoiceOperator"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.laskutusAsiakasNayta
                                                        .asiakas
                                                        .verkkolaskuosoite
                                                        .operaattori
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        458844304
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "col4",
                        fn: function () {
                          return [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-0 font-weight-bold grey--text darken-2",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("common:invoiceSendInfo")) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "DefList",
                              { attrs: { bordered: true } },
                              [
                                _vm.laskutusAsiakasNayta.asiakas.lahetystapa
                                  ? _c("DefListItem", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common:sendMethod"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.laskutusAsiakasNayta
                                                        .asiakas
                                                        .lahetystapa_display
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        516044975
                                      ),
                                    })
                                  : _vm._e(),
                                _vm.laskutusAsiakasNayta.asiakas.toimitustapa
                                  ? _c("DefListItem", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common:deliveryMethod"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.laskutusAsiakasNayta
                                                        .asiakas
                                                        .oletustoimitustapa
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1866888964
                                      ),
                                    })
                                  : _vm._e(),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("common:termsOfPayment")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.laskutusAsiakasNayta.asiakas
                                                  .oletusmaksuehto
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("common:ourReference")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.laskutusAsiakasNayta.asiakas
                                        .oletusviitteemme
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.laskutusAsiakasNayta
                                                        .asiakas
                                                        .oletusviitteemme
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("common:yourReference")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.laskutusAsiakasNayta.asiakas
                                        .oletusviitteenne
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.laskutusAsiakasNayta
                                                        .asiakas
                                                        .oletusviitteenne
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm.laskutusAsiakasNayta.asiakas
                                  .oletusvapaateksti
                                  ? _c("DefListItem", {
                                      attrs: {
                                        "label-class": "text-wrap",
                                        "value-class": "text-wrap",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common:invoiceText"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _c("PerustietoTooltip", {
                                                  attrs: {
                                                    teksti:
                                                      _vm.laskutusAsiakasNayta
                                                        .asiakas
                                                        .oletusvapaateksti,
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1717001743
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "8" } },
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                "background-color": "primary",
                                "slider-color": "grey lighten-5",
                                centered: "",
                              },
                              model: {
                                value: _vm.selectedTab,
                                callback: function ($$v) {
                                  _vm.selectedTab = $$v
                                },
                                expression: "selectedTab",
                              },
                            },
                            _vm._l(_vm.tabs, function (item) {
                              return _c(
                                "v-tab",
                                {
                                  key: item.index,
                                  attrs: { href: "#" + item.id, ripple: false },
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.selectedTab,
                                callback: function ($$v) {
                                  _vm.selectedTab = $$v
                                },
                                expression: "selectedTab",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.selectedTab === "laskut",
                                      expression: "selectedTab === 'laskut'",
                                    },
                                  ],
                                  staticClass: "pb-4",
                                },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "pa-6" },
                                    [
                                      _c("CardTitle", {
                                        attrs: {
                                          text: _vm.$t("common:invoices"),
                                        },
                                      }),
                                      _c("v-data-table", {
                                        attrs: {
                                          items: _vm.laskuItems,
                                          headers:
                                            _vm.tableHeadersAsiakasLaskut,
                                          "hide-default-footer":
                                            _vm.laskuItems.length <= 5,
                                          "items-per-page": 5,
                                          loading: _vm.lataaLaskuja,
                                          "no-data-text":
                                            _vm.$t("common:noInvoices"),
                                          dense: "",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "TableRow",
                                                  {
                                                    attrs: {
                                                      "custom-contextmenu": "",
                                                    },
                                                    on: {
                                                      contextmenu: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return _vm.avaaMenuContext(
                                                          $event,
                                                          item,
                                                          "laskutus-nayta"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap",
                                                      },
                                                      [
                                                        _c("v-tooltip", {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    item.ikoni_ja_vari
                                                                      ? _c(
                                                                          "StatusIcon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: item
                                                                                  .ikoni_ja_vari
                                                                                  .ikoni,
                                                                                size: "small",
                                                                                "hover-icon":
                                                                                  "open-in-new",
                                                                                "bg-color":
                                                                                  item
                                                                                    .ikoni_ja_vari
                                                                                    .vari,
                                                                                "event-listener":
                                                                                  on,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.$router.push(
                                                                                    {
                                                                                      name: "laskutus-nayta",
                                                                                      params:
                                                                                        {
                                                                                          id: String(
                                                                                            item.id
                                                                                          ),
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "default",
                                                                fn: function () {
                                                                  return [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "common:openInvoiceData"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap",
                                                      },
                                                      [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              to: {
                                                                name: "laskutus-nayta",
                                                                params: {
                                                                  id: item.id,
                                                                },
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.nro
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "max-width",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.tila) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.sopimusItems.length
                                                      ? _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.sopimus
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$date(
                                                                item.laskupaiva
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$date(
                                                                item.erapaiva
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatSumToFixed2"
                                                              )(item.summa)
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatSumToFixed2"
                                                              )(item.veroton)
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatSumToFixed2"
                                                              )(item.avoinna)
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.selectedTab === "tapahtumat",
                                      expression:
                                        "selectedTab === 'tapahtumat'",
                                    },
                                  ],
                                  staticClass: "py-4",
                                },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "pa-6" },
                                    [
                                      _c("CardTitle", {
                                        attrs: { text: "Tapahtumat" },
                                      }),
                                      _c("v-data-table", {
                                        attrs: {
                                          items: _vm.kirjauksetItems,
                                          headers: _vm.tableHeadersKirjaukset,
                                          "hide-default-footer":
                                            _vm.kirjauksetItems.length <= 5,
                                          "no-data-text": _vm.$t(
                                            "common:noTransactions"
                                          ),
                                          dense: _vm.user.tiivisNakyma,
                                          "items-per-page": 5,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "TableRow",
                                                  {
                                                    attrs: {
                                                      clickable: true,
                                                      data: item,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.toggleOpenRow(
                                                          item.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap",
                                                      },
                                                      [
                                                        _vm.kirjauksetExpanded.get(
                                                          item.id
                                                        )
                                                          ? _c("v-icon", [
                                                              _vm._v(
                                                                " expand_more "
                                                              ),
                                                            ])
                                                          : !_vm.kirjauksetExpanded.get(
                                                              item.id
                                                            ) &&
                                                            item.kirjaukset &&
                                                            item.kirjaukset
                                                              .length > 0
                                                          ? _c("v-icon", [
                                                              _vm._v(
                                                                " chevron_right "
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.laskunumero
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "max-width",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.lahde) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$date(
                                                                item.maksupaiva
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$date(
                                                                item.kirjauspaiva
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.viite) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.viesti
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatSumToFixed2"
                                                              )(item.summa)
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm.tableKirjauksetOpenRows.includes(
                                                  item.id
                                                ) && item.kirjaukset
                                                  ? _vm._l(
                                                      item.kirjaukset,
                                                      function (kirjaus) {
                                                        return _c(
                                                          "TableRow",
                                                          {
                                                            key: kirjaus.id,
                                                            attrs: {
                                                              "sub-row": true,
                                                            },
                                                          },
                                                          [
                                                            _c("td", {
                                                              staticClass:
                                                                "text-no-wrap",
                                                            }),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-no-wrap",
                                                              },
                                                              [
                                                                kirjaus.toimeksianto_id >
                                                                0
                                                                  ? _c(
                                                                      "RouterLinkEhdollinen",
                                                                      {
                                                                        attrs: {
                                                                          ehto: Boolean(
                                                                            kirjaus.toimeksianto_id
                                                                          ),
                                                                          to: {
                                                                            name: "perinta-nayta",
                                                                            params:
                                                                              {
                                                                                id: kirjaus.toimeksianto_id,
                                                                              },
                                                                          },
                                                                          "custom-contextmenu":
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          contextmenu:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.avaaMenuContext(
                                                                                $event,
                                                                                {
                                                                                  id: kirjaus.toimeksianto_id,
                                                                                },
                                                                                "perinta-nayta"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Toimeksianto " +
                                                                            _vm._s(
                                                                              kirjaus.toimeksianto_id
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "max-width",
                                                              },
                                                              [
                                                                kirjaus.lahde &&
                                                                kirjaus.lasku_id >
                                                                  0
                                                                  ? _c(
                                                                      "RouterLinkEhdollinen",
                                                                      {
                                                                        attrs: {
                                                                          ehto: Boolean(
                                                                            kirjaus.lasku_id
                                                                          ),
                                                                          to: {
                                                                            name: "laskutus-nayta",
                                                                            params:
                                                                              {
                                                                                id: kirjaus.lasku_id,
                                                                              },
                                                                          },
                                                                          "custom-contextmenu":
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          contextmenu:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.avaaMenuContext(
                                                                                $event,
                                                                                {
                                                                                  id: kirjaus.lasku_id,
                                                                                },
                                                                                "laskutus-nayta"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              kirjaus.lahde
                                                                            ) +
                                                                            " / Lasku " +
                                                                            _vm._s(
                                                                              kirjaus.laskunumero
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : kirjaus.lahde
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          kirjaus.lahde
                                                                        )
                                                                      ),
                                                                    ])
                                                                  : kirjaus.lasku_id >
                                                                    0
                                                                  ? _c(
                                                                      "RouterLinkEhdollinen",
                                                                      {
                                                                        attrs: {
                                                                          ehto: Boolean(
                                                                            kirjaus.lasku_id
                                                                          ),
                                                                          to: {
                                                                            name: "laskutus-nayta",
                                                                            params:
                                                                              {
                                                                                id: kirjaus.lasku_id,
                                                                              },
                                                                          },
                                                                          "custom-contextmenu":
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          contextmenu:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.avaaMenuContext(
                                                                                $event,
                                                                                {
                                                                                  id: kirjaus.lasku_id,
                                                                                },
                                                                                "laskutus-nayta"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Lasku " +
                                                                            _vm._s(
                                                                              kirjaus.laskunumero
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          kirjaus.laskunumero
                                                                        )
                                                                      ),
                                                                    ]),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-no-wrap text-right",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$date(
                                                                        kirjaus.maksupaiva
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-no-wrap text-right",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$date(
                                                                        kirjaus.kirjauspaiva
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c("td", {
                                                              staticClass:
                                                                "text-no-wrap",
                                                            }),
                                                            _c("td", {
                                                              staticClass:
                                                                "text-no-wrap",
                                                            }),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-no-wrap text-right",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "formatSumToFixed2"
                                                                      )(
                                                                        kirjaus.summa
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.sopimusItems.length
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.selectedTab === "sopimukset",
                                          expression:
                                            "selectedTab === 'sopimukset'",
                                        },
                                      ],
                                      staticClass: "py-4",
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "pa-6" },
                                        [
                                          _c("CardTitle", {
                                            attrs: {
                                              text: _vm.$t("common:contracts"),
                                            },
                                          }),
                                          _c("v-data-table", {
                                            attrs: {
                                              items: _vm.sopimusItems,
                                              headers:
                                                _vm.tableHeadersAsiakasSopimukset,
                                              "hide-default-footer":
                                                _vm.sopimusItems.length <= 5,
                                              "items-per-page": 5,
                                              "no-data-text":
                                                _vm.$t("common:noContracts"),
                                              dense: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("TableRow", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap",
                                                          },
                                                          [
                                                            _c(
                                                              "RouterLinkEhdollinen",
                                                              {
                                                                attrs: {
                                                                  ehto: _vm.$permission.checkSinglePerm(
                                                                    "onlinerestapi.onlinerestapi_sopimus"
                                                                  ),
                                                                  to: {
                                                                    name: "sopimus-nayta",
                                                                    params: {
                                                                      id: item.id,
                                                                    },
                                                                  },
                                                                  "custom-contextmenu":
                                                                    "",
                                                                },
                                                                on: {
                                                                  contextmenu:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.avaaMenuContext(
                                                                        $event,
                                                                        {
                                                                          id: item.id,
                                                                        },
                                                                        "sopimus-nayta"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.nro
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap ",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.tila
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.voimassa
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "max-width",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.tyyppi
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "max-width",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.laji
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatSumToFixed2"
                                                                  )(
                                                                    item.avoinna
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2035313592
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.muistiinpanoItems.length
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.selectedTab === "muistiinpanot",
                                          expression:
                                            "selectedTab === 'muistiinpanot'",
                                        },
                                      ],
                                      staticClass: "py-4",
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "pa-6" },
                                        [
                                          _c("CardTitle", {
                                            attrs: {
                                              text: _vm.$t("common:notes"),
                                            },
                                          }),
                                          _c("v-data-table", {
                                            attrs: {
                                              items: _vm.muistiinpanoItems,
                                              headers:
                                                _vm.tableHeadersAsiakasMuistiinpanot,
                                              "hide-default-footer":
                                                _vm.muistiinpanoItems.length <=
                                                5,
                                              "items-per-page": 5,
                                              "no-data-text":
                                                _vm.$t("common:noNotes"),
                                              dense: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("TableRow", [
                                                        _c("td", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.lahettaja
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$date(
                                                                    item.aika,
                                                                    "D.M.YYYY HH:mm"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            item.lasku
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    on: {
                                                                      contextmenu:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.avaaMenuContext(
                                                                            $event,
                                                                            {
                                                                              id: item
                                                                                .lasku
                                                                                .id,
                                                                            },
                                                                            "laskutus-nayta"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "router-link",
                                                                      {
                                                                        attrs: {
                                                                          to: {
                                                                            name: "laskutus-nayta",
                                                                            params:
                                                                              {
                                                                                id: item
                                                                                  .lasku
                                                                                  .id,
                                                                              },
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "common:invoice"
                                                                              )
                                                                            ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              item
                                                                                .lasku
                                                                                .nro
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-wrap max-width",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.sisalto
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("td", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row",
                                                            },
                                                            [
                                                              _c("v-tooltip", {
                                                                attrs: {
                                                                  top: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              _vm._g(
                                                                                {},
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        text: "",
                                                                                        icon: "",
                                                                                        small:
                                                                                          "",
                                                                                        disabled:
                                                                                          !item.muokattavissa,
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.avaaDialogMuistiinpanoMuokkaa(
                                                                                            item
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            small:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " edit "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      item.muokattavissa
                                                                        ? {
                                                                            key: "default",
                                                                            fn: function () {
                                                                              return [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "common:editNote"
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                            proxy: true,
                                                                          }
                                                                        : {
                                                                            key: "default",
                                                                            fn: function () {
                                                                              return [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "common:customerData.noteNotEditable"
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                            proxy: true,
                                                                          },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }),
                                                              _c("v-tooltip", {
                                                                attrs: {
                                                                  top: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              _vm._g(
                                                                                {},
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        text: "",
                                                                                        icon: "",
                                                                                        small:
                                                                                          "",
                                                                                        disabled:
                                                                                          !item.muokattavissa,
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.poistaMuistiinpano(
                                                                                            item.id
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            small:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " delete "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      item.muokattavissa
                                                                        ? {
                                                                            key: "default",
                                                                            fn: function () {
                                                                              return [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "common:deleteNote"
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                            proxy: true,
                                                                          }
                                                                        : {
                                                                            key: "default",
                                                                            fn: function () {
                                                                              return [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "common:customerData.noteNotDeletable"
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                            proxy: true,
                                                                          },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              272046190
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.viestiItems.length
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.selectedTab === "viestit",
                                          expression:
                                            "selectedTab === 'viestit'",
                                        },
                                      ],
                                      staticClass: "py-4",
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "pa-6" },
                                        [
                                          _c("CardTitle", {
                                            attrs: {
                                              text: _vm.$t("common:messages"),
                                            },
                                          }),
                                          _c("v-data-table", {
                                            attrs: {
                                              items: _vm.viestiItems,
                                              headers:
                                                _vm.tableHeadersAsiakasViestit,
                                              "hide-default-footer":
                                                _vm.viestiItems.length <= 5,
                                              "items-per-page": 5,
                                              "no-data-text":
                                                _vm.$t("common:noMessages"),
                                              dense: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("TableRow", [
                                                        _c("td", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.lahettaja
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$date(
                                                                    item.aika,
                                                                    "D.M.YYYY HH:mm"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            item.lasku
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    on: {
                                                                      contextmenu:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.avaaMenuContext(
                                                                            $event,
                                                                            {
                                                                              id: item
                                                                                .lasku
                                                                                .id,
                                                                            },
                                                                            "laskutus-nayta"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "router-link",
                                                                      {
                                                                        attrs: {
                                                                          to: {
                                                                            name: "laskutus-nayta",
                                                                            params:
                                                                              {
                                                                                id: item
                                                                                  .lasku
                                                                                  .id,
                                                                              },
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "common:invoice"
                                                                              )
                                                                            ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              item
                                                                                .lasku
                                                                                .nro
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-wrap max-width",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                class:
                                                                  item.luettu ===
                                                                  "–"
                                                                    ? "font-weight-bold"
                                                                    : "",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.sisalto
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.luettu
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1154050771
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "4" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pa-6" },
                            [
                              _c("CardTitle", {
                                attrs: { text: "Muut tiedot" },
                              }),
                              _c("AsiakasLaskutuksenYhteenveto", {
                                attrs: {
                                  "title-class": "mt-4",
                                  asiakas: _vm.laskutusAsiakasNayta.asiakas,
                                },
                              }),
                              _vm.laskutusAsiakasNayta.asiakas.iban
                                ? [
                                    _c("h3", { staticClass: "mt-4 mb-2" }, [
                                      _vm._v(" Maksutiedot "),
                                    ]),
                                    _c(
                                      "DefList",
                                      { staticClass: "pa-0" },
                                      [
                                        _c("DefListItem", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [_vm._v(" IBAN ")]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: "value",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm
                                                            .laskutusAsiakasNayta
                                                            .asiakas.iban
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            33728056
                                          ),
                                        }),
                                        _c("DefListItem", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [_vm._v(" BIC ")]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: "value",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm
                                                            .laskutusAsiakasNayta
                                                            .asiakas.bic
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            3236265784
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.asiakasrekisteriItems.length
                                ? [
                                    _c("h3", { staticClass: "my-2" }, [
                                      _vm._v(" Asiakasrekisterit "),
                                    ]),
                                    _c("v-data-table", {
                                      attrs: {
                                        items: _vm.asiakasrekisteriItems,
                                        headers:
                                          _vm.tableHeadersAsiakasAsiakasrekisteri,
                                        "hide-default-footer":
                                          _vm.asiakasrekisteriItems.length <= 5,
                                        "items-per-page": 5,
                                        "no-data-text":
                                          "Ei asiakasrekistereitä",
                                        dense: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c("TableRow", [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.nro) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "text-wrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.nimi) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "text-wrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.ryhma) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4248417748
                                      ),
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
      _c("CardDialog", {
        attrs: {
          title: "Asiakaskohtaiset muistiinpanot",
          "dialog-auki": _vm.muistiinpanotDialogAuki,
          "scroll-to-bottom": true,
          "content-class": "px-4 py-2",
        },
        on: {
          close: function ($event) {
            _vm.muistiinpanotDialogAuki = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _vm.muistiinpanotAikajana.length
                  ? _c("Timeline", {
                      staticClass: "px-4",
                      attrs: {
                        items: _vm.muistiinpanotAikajana,
                        "show-title": false,
                        "show-item-titles": false,
                        "show-dates": true,
                        "enable-zoom": false,
                        "enable-direction-toggle": false,
                        "salli-muistiinpanojen-muokkaus": false,
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("FormUusiViesti", {
                  attrs: {
                    loading: _vm.uusiMuistiinpanoFormLoading,
                    "focus-on-update": true,
                    icon: "note",
                    "msg-input-placeholder": "Uusi muistiinpano",
                    "submit-btn-tooltip-text": "Kirjoita muistiinpano",
                    painikkeet: "",
                    "peruuta-teksti": _vm.$t("common:close"),
                    "submit-teksti": _vm.$t("common:save"),
                  },
                  on: {
                    submit: _vm.muistiinpanoSubmit,
                    close: function ($event) {
                      _vm.muistiinpanotDialogAuki = false
                    },
                  },
                  model: {
                    value: _vm.laskutusAsiakasNayta.uusiMuistiinpanoFormData,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.laskutusAsiakasNayta,
                        "uusiMuistiinpanoFormData",
                        $$v
                      )
                    },
                    expression: "laskutusAsiakasNayta.uusiMuistiinpanoFormData",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Muokkaa asiakasta",
            "dialog-auki": _vm.asiakasDialogAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4",
            width: "1024",
          },
          on: {
            close: function ($event) {
              _vm.asiakasDialogAuki = false
            },
          },
        },
        [
          _c("FormAsiakas", {
            attrs: {
              "kaare-objekti": _vm.asiakasKaare,
              "kenttien-valinnat": _vm.laskutusAsiakasNayta.kenttienValinnat,
              "on-auki": _vm.asiakasDialogAuki,
            },
            on: {
              close: function ($event) {
                _vm.asiakasDialogAuki = false
              },
              asiakasTallennettu: _vm.kasittelePaivitys,
            },
          }),
        ],
        1
      ),
      _c("CardDialog", {
        attrs: {
          title: "Muokkaa muistiinpanoa",
          "dialog-auki": _vm.muistiinpanoMuokkaaDialogAuki,
          "scroll-to-bottom": true,
          "content-class": "px-4 py-2",
        },
        on: {
          close: function ($event) {
            _vm.muistiinpanoMuokkaaDialogAuki = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "footer",
            fn: function () {
              return [
                _c("FormUusiViesti", {
                  attrs: {
                    loading: _vm.muistiinpanoMuokkaaFormLoading,
                    "focus-on-update": true,
                    icon: "note",
                    "submit-btn-tooltip-text": "Tallenna",
                  },
                  on: { submit: _vm.muistiinpanoMuokkaaSubmit },
                  model: {
                    value: _vm.laskutusAsiakasNayta.muistiinpanoMuokkaaFormData,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.laskutusAsiakasNayta,
                        "muistiinpanoMuokkaaFormData",
                        $$v
                      )
                    },
                    expression:
                      "laskutusAsiakasNayta.muistiinpanoMuokkaaFormData",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("MenuContext", {
        attrs: {
          "onko-nakyvissa": _vm.menuContextNakyvissa,
          "position-x": _vm.menuContextX,
          "position-y": _vm.menuContextY,
          vaihtoehdot: _vm.menuContextVaihtoehdot,
        },
        on: {
          avaaValilehteen: _vm.avaaUudessaValilehdessa,
          "update:return-value": _vm.suljeMenuContext,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }