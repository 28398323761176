<template>
  <div class="laskutus-selaa">
    <!-- Off-canvas -->
    <v-navigation-drawer
      ref="offCanvas"
      v-model="showOffCanvas"
      width="450"
      app
      right
      temporary
    >
      <Spinner v-if="loadingOffCanvas" />
      <OffCanvasLasku
        v-else
        v-model="selectedItem.item"
        @close-button-click="setShowOffCanvas"
        @messageSubmit="refresh"
        @msg-mark-as-unread="refresh"
        @msg-mark-as-read="refresh"
      />
    </v-navigation-drawer>

    <!-- Top bar -->
    <ViewSelaaTopBarBase
      pikahaun-placeholder-teksti="Hae (laskunumero, ID, viite)"
      :vuex-module-name="vuexModuleName"
      @open-search-panel="openSearchPanel"
      @close-search-panel="closeSearchPanel"
    />

    <!-- Tabs -->
    <ViewSelaaTabs
      :vuex-module-name="vuexModuleName"
    />

    <!-- Advanced search -->
    <TransitionSlidePanel>
      <div
        v-show="searchPanelOpen"
        class="pa-1 primary darken-1 shadow--inset"
      >
        <InfoValittuValilehti
          :current-tab="currentTab"
        />
        <FormSearchLasku v-model="searchTerms" />
      </div>
    </TransitionSlidePanel>

    <!-- Toolbar -->
    <ViewSelaaToolbar
      :vuex-module-name="vuexModuleName"
      item-partitiivi="laskua"
      :lataa-csv-btn-config="{ csvData, csvLabels, csvTitle }"
      :uusi-btn-config="{
        toName: 'laskutus-uusi',
        itemName: 'lasku',
        permission: onkoLupaLuodaLasku,
      }"
    />

    <!-- Show items as table -->
    <v-data-table
      class="v-data-table--striped"
      :headers="tableHeaders"
      :items="items"
      :hide-default-footer="items.length < 10"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      :multi-sort="true"
      :server-items-length="itemsTotal"
      :options.sync="pagination"
      :loading="loading"
      loading-text=""
      no-data-text=""
      :dense="tiivisNakyma"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>
      <template v-slot:item="{ item }">
        <TableRow
          :clickable="(true)"
          :data="item"
          :selected-item="selectedItem.item"
          :fade="loading"
          :yellow="item.onko_myohassa"
          :custom-contextmenu="(true)"
          @click="setSelectedItem({ item })"
          @contextmenu.prevent="avaaMenuContext($event, item)"
        >
          <td class="text-no-wrap">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  v-if="item.ikoni_ja_vari"
                  :icon="item.ikoni_ja_vari.ikoni"
                  :size="tiivisNakyma ? 'small' : 'medium'"
                  :hover-icon="('open-in-new')"
                  :bg-color="item.ikoni_ja_vari.vari"
                  :event-listener="on"
                  @click="$router.push({ name: 'laskutus-nayta', params: { id: item.id } })"
                />
              </template>
              <template v-slot:default>
                <span>Avaa lasku</span>
              </template>
            </v-tooltip>
          </td>
          <td class="text-no-wrap">
            {{ item.nro }}
          </td>
          <td class="text-no-wrap">
            {{ item.id }}
          </td>
          <td class="max-width">
            <template v-if="eiOikeuttaNahdaAsiakastaValue(item)">
              <span
                class="font-italic"
              >Ei voida näyttää tietosuojasyistä</span>
            </template>
            <template v-else>
              {{ item.asiakas.nimi }} <template v-if="item.asiakas.nro">
                ({{ item.asiakas.nro }})
              </template>
            </template>
          </td>
          <td class="text-no-wrap">
            {{ item.tila.selite }}
          </td>
          <td class="text-no-wrap">
            {{ item.paamies.nimi }} <template v-if="item.paamies.id">
              ({{ item.paamies.id }})
            </template>
          </td>
          <td class="text-no-wrap text-right">
            {{ $date(item.laskupaiva) }}
          </td>
          <td class="text-no-wrap text-right">
            {{ $date(item.erapaiva) }}
          </td>
          <td class="text-no-wrap text-right">
            {{ item.summa | formatSumToFixed2 }}
          </td>
          <td class="text-no-wrap text-right">
            {{ item.veroton | formatSumToFixed2 }}
          </td>
          <td class="text-no-wrap text-right">
            {{ item.avoinna | formatSumToFixed2 }}
          </td>
        </TableRow>
      </template>
    </v-data-table>

    <TyhjaTaulukkoOtsikko v-if="tyhjaTaulukko"
      :otsikko="$t('youHaveNoInvoices')"
    >
    </TyhjaTaulukkoOtsikko>
    <ToolbarTyhjaTaulukko v-if="tyhjaTaulukko"
      ikoni="library_add"
      taustavari="success"
      :teksti="$t('newInvoice')"
      :infoteksti="$t('noInvoiceAddInfo')"
      :naytaAina="true"
      :uusi-btn-config="{
        toName: 'laskutus-uusi',
        permission: onkoLupaLuodaLasku,
      }"
    >
    </ToolbarTyhjaTaulukko>
    <!-- Pagination -->
    <v-toolbar
      v-if="(pagesTotal > 1)"
      class="my-6 py-2 transparent"
      flat
    >
      <v-spacer />
      <Pagination
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
      />
      <v-spacer />
    </v-toolbar>

    <!-- Välilehti-dialog -->
    <MenuContext
      :onko-nakyvissa="menuContextNakyvissa"
      :position-x="menuContextX"
      :position-y="menuContextY"
      :vaihtoehdot="menuContextVaihtoehdot"
      @avaaValilehteen="avaaUudessaValilehdessa"
      @update:return-value="suljeMenuContext"
    />
  </div>
</template>

<script>

import {
  FormSearchLasku,
  InfoValittuValilehti,
  OffCanvasLasku,
  Pagination,
  ToolbarTyhjaTaulukko,
  TyhjaTaulukkoOtsikko,
  TransitionSlidePanel,
  ViewSelaaTabs,
  ViewSelaaToolbar,
  ViewSelaaTopBarBase,
} from '@/components'
import TrafiLupaMixin from '@/mixins/TrafiLupaMixin'
import ViewSelaaMixin from '@/mixins/ViewSelaaMixin'
import { TableHeadersLaskut } from '@/utils/tableHeaders'

export default {
  name: 'LaskutusSelaa',
  components: {
    FormSearchLasku,
    InfoValittuValilehti,
    OffCanvasLasku,
    Pagination,
    TransitionSlidePanel,
    ViewSelaaTabs,
    ViewSelaaToolbar,
    ViewSelaaTopBarBase,
    ToolbarTyhjaTaulukko,
    TyhjaTaulukkoOtsikko,
  },
  mixins: [
    TrafiLupaMixin,
    ViewSelaaMixin,
  ],
  data () {
    return {
      tableHeaders: TableHeadersLaskut,
      menuContextValilehtiRouteName: 'laskutus-nayta'
    }
  },
  computed: {
    onkoLupaLuodaLasku () {
      return (
        !(
          this.$store.state.user.vainYksiPaamies &&
          this.$store.state.user.valittuPaamies.esta_laskun_luonti
        )
      )
    },
    csvLabels () {
      return {
          nro: { title: 'Laskunumero' },
          id: { title:  this.$sovellusIdOtsake },
          asiakas: { title: 'Asiakas' },
          tila: { title: 'Tila' },
          ...(!this.vainYksiPaamies && { paamies: { title: 'Päämies' } }),
          laskupaiva: { title: 'Laskupäivä' },
          erapaiva: { title: 'Eräpäivä' },
          summa: { title: 'Summa' },
          veroton: { title: 'Veroton' },
          avoinna: { title: 'Avoinna' },
      }
    },
    csvData () {
      if (!Array.isArray(this.items)) return []

      return this.items.map(item => {
        return {
            nro: item.nro,
            id: item.id,
            asiakas: this.eiOikeuttaNahdaAsiakastaValue(item)? 'Ei voida näyttää tietosuojasyistä' : item.asiakas.nimi,
            tila: item.tila.selite,
            ...(!this.vainYksiPaamies && { paamies: `${item.paamies.nimi} (${item.paamies.id}) (${item.paamies.piilota_asiakas_ja_velallinen})` }),
            laskupaiva: item.laskupaiva,
            erapaiva: item.erapaiva,
            summa: this.$formatSumToFixed2(item.summa),
            veroton: this.$formatSumToFixed2(item.veroton),
            avoinna: this.$formatSumToFixed2(item.avoinna),
        }
      })
    },
    csvTitle () {
      return 'Laskut_haku-' + this.$dateFnsFormat(new Date(), 'YYYY-MM-DD-HHmm-ss')
    },
  },
  methods: {
    lisaaViewKohtaisetHaunSuodattimet (query) {
      if (this.searchTerms.laskunumero) query.append('nro', this.searchTerms.laskunumero)
      if (this.searchTerms.viite) query.append('viite', this.searchTerms.viite)
      if (this.searchTerms.viitteemme) query.append('viitteemme', this.searchTerms.viitteemme)
      if (this.searchTerms.viitteenne) query.append('viitteenne', this.searchTerms.viitteenne)
      if (this.searchTerms.pmViite) query.append('pm_viite', this.searchTerms.pmViite)
      if (this.searchTerms.sopimus) query.append('sopimus', this.searchTerms.sopimus)
      if (this.searchTerms.asiakas) query.append('asiakas', this.searchTerms.asiakas)
      if (this.searchTerms.paamies) query.append('paamies', this.searchTerms.paamies)
      if (this.searchTerms.laskupaivaAlkaen) query.append('laskupaiva_after', this.searchTerms.laskupaivaAlkaen)
      if (this.searchTerms.laskupaivaPaattyen) query.append('laskupaiva_before', this.searchTerms.laskupaivaPaattyen)
      if (this.searchTerms.erapaivaAlkaen) query.append('erapaiva_after', this.searchTerms.erapaivaAlkaen)
      if (this.searchTerms.erapaivaPaattyen) query.append('erapaiva_before', this.searchTerms.erapaivaPaattyen)

      if (typeof this.searchTerms.tila === 'object') {
        query = this.kasitteleMoniosuodatin(query, this.searchTerms.tila)
      }
      if (typeof this.searchTerms.asiakasTyyppi === 'object') {
        query = this.kasitteleMoniosuodatin(query, this.searchTerms.asiakasTyyppi)
      }
      if (this.searchTerms.rahoitus) query.append('rahoitus', this.searchTerms.rahoitus)

      return query
    },
  },
}

</script>

<style lang="scss" scoped>

</style>
