var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "laskutus-muokkaa grey lighten-2" },
    [
      _c(
        "div",
        { staticClass: "sticky" },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "v-toolbar--sticky pl-6",
              attrs: { color: "primary", flat: "", tabs: "", dark: true },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("arrow_back")])],
                1
              ),
              _vm.$route.meta.title
                ? _c("v-toolbar-title", [
                    _vm._v(" " + _vm._s(_vm.$route.meta.title) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "px-6 py-12 main-div" },
        [
          _vm.muokattuLasku.id
            ? [
                _c(
                  "v-container",
                  { staticClass: "max-width--1200 px-6 py-4 mx-auto mb-12" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "pa-6", attrs: { justify: "center" } },
                      [
                        _c("v-tooltip", {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c("StatusIcon", {
                                      attrs: {
                                        icon: "invoice",
                                        size: "large",
                                        "hover-icon": "open-in-new",
                                        "bg-color": "secondary",
                                        "event-listener": on,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "laskutus-nayta",
                                            params: {
                                              id: _vm.muokattuLasku.id,
                                            },
                                          })
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [_c("span", [_vm._v("Avaa lasku")])]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2199794932
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "pa-6", attrs: { justify: "center" } },
                      [
                        _c(
                          "span",
                          { staticClass: "title" },
                          [
                            _vm._v("Muutokset tallennettu laskulle "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "laskutus-nayta",
                                    params: { id: _vm.muokattuLasku.id },
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.muokattuLasku.nro))]
                            ),
                            _vm._v(". "),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "v-row",
                      { staticClass: "pa-6", attrs: { justify: "center" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              large: "",
                              to: { name: "laskutus-selaa" },
                            },
                          },
                          [_vm._v(" Palaa laskujen hakuun ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              large: "",
                              color: "success",
                              to: { name: "laskutus-uusi" },
                            },
                          },
                          [_vm._v(" Luo uusi lasku ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "paper px-6 py-12 mb-12" },
                  [
                    _c("FormLasku", {
                      ref: "formLasku",
                      attrs: {
                        "muokkaamassa-laskua": this.$route.params
                          .muokattavaLasku
                          ? true
                          : false,
                      },
                      on: {
                        "avaa-dialog-asiakas": _vm.avaaDialogAsiakas,
                        "avaa-dialog-asiakas-muistiinpanot":
                          _vm.avaaDialogAsiakasMuistiinpanot,
                        "avaa-dialog-toimitusosoite":
                          _vm.avaaDialogToimitusosoite,
                        pohjatVastaanotettu: _vm.kasittelePohjat,
                        muokkaus: function ($event) {
                          _vm.naytaPoistumisvahvistus = true
                        },
                        paamiesKasitteleValinta: _vm.paamiesKasitteleValinta,
                      },
                      model: {
                        value: _vm.lasku,
                        callback: function ($$v) {
                          _vm.lasku = $$v
                        },
                        expression: "lasku",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "paper px-9 py-3 mb-6" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "pa-6", attrs: { md: "6" } },
                          [
                            _vm.naytaMaksutapa
                              ? [
                                  _c("h2", { staticClass: "headline mb-6" }, [
                                    _vm._v(" Maksutapa "),
                                  ]),
                                  _c(
                                    "v-radio-group",
                                    {
                                      staticClass: "mt-0",
                                      attrs: {
                                        row: "",
                                        "error-messages": _vm.maksutapaErrors,
                                      },
                                      model: {
                                        value: _vm.lasku.maksutapa,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.lasku, "maksutapa", $$v)
                                        },
                                        expression: "lasku.maksutapa",
                                      },
                                    },
                                    _vm._l(
                                      _vm.maksutavat,
                                      function (maksutapa) {
                                        return _c("v-radio", {
                                          key: maksutapa.index,
                                          attrs: {
                                            label: maksutapa.label,
                                            value: maksutapa.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              : [
                                  _c("h2", { staticClass: "headline mb-6" }, [
                                    _vm._v(" Lähetys "),
                                  ]),
                                  _c("h3", { staticClass: "subtitle-1" }, [
                                    _vm._v(" Lähetystapa "),
                                  ]),
                                  _c("v-switch", {
                                    attrs: {
                                      label:
                                        "Tallenna laskuluonnos (ei lähetystä)",
                                      disabled: _vm.onLahetelasku,
                                    },
                                    model: {
                                      value: _vm.lasku.luonnos,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.lasku, "luonnos", $$v)
                                      },
                                      expression: "lasku.luonnos",
                                    },
                                  }),
                                  !_vm.lasku.luonnos
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mt-0",
                                              attrs: {
                                                row: "",
                                                "error-messages":
                                                  _vm.lahetystapaErrors,
                                              },
                                              model: {
                                                value: _vm.lasku.lahetystapa,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.lasku,
                                                    "lahetystapa",
                                                    $$v
                                                  )
                                                },
                                                expression: "lasku.lahetystapa",
                                              },
                                            },
                                            _vm._l(
                                              _vm.lahetystavatHintoineen,
                                              function (lahetystapa) {
                                                return _c("v-radio", {
                                                  key: lahetystapa.index,
                                                  attrs: {
                                                    label: lahetystapa.label,
                                                    value: lahetystapa.value,
                                                    disabled:
                                                      _vm.onkoLahetystapaDisabloitu(
                                                        lahetystapa.value,
                                                        _vm.lasku.asiakas
                                                      ),
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                          _c(
                                            "h3",
                                            { staticClass: "subtitle-1" },
                                            [_vm._v(" Lähetyspäivä ")]
                                          ),
                                          _c("v-menu", {
                                            attrs: {
                                              "close-on-content-click": false,
                                              "max-width": "290",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-text-field",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              value: _vm.$date(
                                                                _vm.lasku
                                                                  .haluttu_lahetyspaiva
                                                              ),
                                                              placeholder:
                                                                "Tänään",
                                                              "persistent-placeholder":
                                                                "",
                                                              readonly: "",
                                                              disabled:
                                                                !_vm.lasku
                                                                  .kirjepohja_id,
                                                            },
                                                          },
                                                          on
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "default",
                                                  fn: function () {
                                                    return [
                                                      _c("v-date-picker", {
                                                        attrs: {
                                                          locale: "fi-FI",
                                                          "first-day-of-week":
                                                            "1",
                                                          "no-title": "",
                                                          disabled:
                                                            !_vm.lasku
                                                              .kirjepohja_id,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            _vm.lahetyspaivaMenu = false
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.lasku
                                                              .haluttu_lahetyspaiva,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.lasku,
                                                              "haluttu_lahetyspaiva",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "lasku.haluttu_lahetyspaiva",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              1179402015
                                            ),
                                            model: {
                                              value: _vm.lahetyspaivaMenu,
                                              callback: function ($$v) {
                                                _vm.lahetyspaivaMenu = $$v
                                              },
                                              expression: "lahetyspaivaMenu",
                                            },
                                          }),
                                          _vm.lasku.paamies.id &&
                                          !_vm.lasku.kirjepohja_id
                                            ? _c(
                                                "v-card",
                                                {
                                                  staticClass: "pa-4",
                                                  attrs: { color: "warning" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "subtitle-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Huom.! Laskulle ei ole määritetty laskupohjaa. Sitä ei lähetetä."
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                          ],
                          2
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pa-6", attrs: { md: "6" } },
                          [
                            _c("h2", { staticClass: "headline mb-6 ml-0" }, [
                              _vm._v(" Liitteet "),
                            ]),
                            _vm.lasku.laskuliite_set.length
                              ? _c("DataTableLaskuliite", {
                                  attrs: {
                                    items: _vm.lasku.laskuliite_set,
                                    lasku: _vm.lasku,
                                  },
                                })
                              : _vm._e(),
                            _c("v-file-input", {
                              attrs: {
                                chips: "",
                                counter: "5",
                                multiple: "",
                                "error-messages": _vm.liiteErrors,
                                rules: [
                                  _vm.$validationRules.required,
                                  _vm.$validationRules.smallerThanOrEqualTo(
                                    5,
                                    5
                                  ),
                                ],
                                title: "title",
                                placeholder: "Lisää liitteitä...",
                                "persistent-placeholder": "",
                                hint: "Huom.! Tiedoston tulee olla tyyppiä jpeg, gif, png tai pdf. Kuvatiedostot muunnetaan pdf:ksi.",
                                "validate-on-blur": true,
                              },
                              model: {
                                value: _vm.lasku.tiedostot,
                                callback: function ($$v) {
                                  _vm.$set(_vm.lasku, "tiedostot", $$v)
                                },
                                expression: "lasku.tiedostot",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "max-width--1200 px-6 py-4 mx-auto mb-12",
                    attrs: { justify: "center" },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mx-5",
                        attrs: { large: "" },
                        on: { click: _vm.haeEsikatseluPdf },
                      },
                      [_vm._v(" Esikatselu ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mx-5",
                        attrs: { large: "", color: "success" },
                        on: { click: _vm.tallennaLaskunMuokkaus },
                      },
                      [_vm._v(" Tallenna muutokset ")]
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: _vm.lasku.asiakas.id ? "Muokkaa asiakasta" : "Uusi asiakas",
            "dialog-auki": _vm.asiakasDialogAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4",
            width: "1024",
          },
          on: {
            close: function ($event) {
              _vm.asiakasDialogAuki = false
            },
          },
        },
        [
          _c("FormAsiakas", {
            attrs: {
              "kaare-objekti": _vm.lasku,
              "kenttien-valinnat": _vm.kenttienValinnat,
              "on-auki": _vm.asiakasDialogAuki,
            },
            on: {
              close: function ($event) {
                _vm.asiakasDialogAuki = false
              },
              asiakasTallennettu: _vm.paivitaAsiakas,
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Asiakkaaseen liittyvät muistiinpanot",
            "dialog-auki": _vm.asiakasMuistiinpanotDialogAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4 pb-12",
            width: "1024",
          },
          on: {
            close: function ($event) {
              _vm.asiakasMuistiinpanotDialogAuki = false
            },
          },
        },
        [
          _c("AsiakasMuistiinpanotDatatable", {
            attrs: { items: _vm.muistiinpanoItems },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Toimitusosoite",
            "dialog-auki": _vm.toimitusosoiteDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.toimitusosoiteDialogAuki = false
            },
          },
        },
        [
          _c("FormToimitusosoite", {
            staticClass: "pa-4",
            attrs: {
              lasku: _vm.lasku,
              "kenttien-valinnat": _vm.kenttienValinnat,
              "on-auki": _vm.toimitusosoiteDialogAuki,
            },
            on: {
              close: function ($event) {
                _vm.toimitusosoiteDialogAuki = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          staticClass: "px-0 py-0 full-height",
          attrs: {
            "content-class": "px-8 pb-0",
            "dialog-auki": _vm.esikatseluDialogAuki,
            title: "Esikatselu",
            width: "1200",
          },
          on: {
            close: function ($event) {
              _vm.esikatseluDialogAuki = false
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.esikatseluPdfData,
                  expression: "esikatseluPdfData",
                },
              ],
              staticClass: "esikatselu",
            },
            [
              _c("object", {
                attrs: {
                  data: _vm.esikatseluPdfData,
                  height: "100%",
                  width: "100%",
                },
              }),
            ]
          ),
          _vm.esikatseluVaroitus
            ? _c(
                "v-card",
                { staticClass: "pa-4", attrs: { color: "warning" } },
                [
                  _c("span", { staticClass: "subtitle-1" }, [
                    _vm._v(_vm._s(_vm.esikatseluVaroitus)),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "v-row",
            {
              staticClass: "max-width--1200 px-0 py-4 mx-auto mb-0",
              attrs: { justify: "end" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { large: "" },
                  on: {
                    click: function ($event) {
                      _vm.esikatseluDialogAuki = false
                    },
                  },
                },
                [_vm._v(" Sulje ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-0",
                  attrs: { large: "", color: "success" },
                  on: { click: _vm.tallennaLaskunMuokkaus },
                },
                [_vm._v(" Tallenna muutokset ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }