<template>
  <div class="laskutus-asiakas-selaa">
    <!-- Off-canvas -->
    <v-navigation-drawer
      ref="offCanvas"
      v-model="showOffCanvas"
      width="450"
      app
      right
      temporary
    >
      <Spinner v-if="loadingOffCanvas" />
      <OffCanvasAsiakas
        v-else
        v-model="selectedItem.item"
        @close-button-click="setShowOffCanvas"
        @messageSubmit="refresh"
        @msg-mark-as-unread="refresh"
        @msg-mark-as-read="refresh"
      />
    </v-navigation-drawer>

    <!-- Top bar -->
    <ViewSelaaTopBarBase
      ref="topBar"
      pikahaun-placeholder-teksti="Hae (asiakasnumero, ID, tunnus)"
      :vuex-module-name="vuexModuleName"
      @open-search-panel="openSearchPanel"
      @close-search-panel="closeSearchPanel"
    />

    <!-- Tabs -->
    <ViewSelaaTabs
      :vuex-module-name="vuexModuleName"
    />

    <!-- Advanced search -->
    <TransitionSlidePanel>
      <div
        v-show="searchPanelOpen"
        class="pa-1 primary darken-1 shadow--inset"
      >
        <InfoValittuValilehti
          :current-tab="currentTab"
        />
        <FormSearchVastapuoli v-model="searchTerms" />
      </div>
    </TransitionSlidePanel>

    <!-- Toolbar -->
    <ViewSelaaToolbar
      :vuex-module-name="vuexModuleName"
      item-partitiivi="asiakasta"
      :lataa-csv-btn-config="{ csvData, csvLabels, csvTitle }"
      :uusi-btn-config="{ click: avaaDialogAsiakas, itemName: 'asiakas' }"
      :tuo-aineistoa-btn-config="{click: () => { tuoCSVDialogAuki = true }, text: 'Tuo asiakkaita'}"
    />

    <!-- Show items as table -->
    <v-data-table
      class="v-data-table--striped"
      :headers="tableHeaders"
      :items="items"
      :hide-default-footer="items.length < 10"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      :multi-sort="true"
      :server-items-length="itemsTotal"
      :options.sync="pagination"
      :loading="loading"
      loading-text=""
      no-data-text=""
      :dense="tiivisNakyma"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>
      <template v-slot:item="{ item }">
        <TableRow
          :clickable="true"
          :data="item"
          :fade="loading"
          :custom-contextmenu="true"
          @contextmenu.prevent="avaaMenuContext($event, item)"
          @click="eiOikeuttaAvataAsiakasta(item) ? () => {} : setSelectedItem({ item })"
        >
          <td class="text-no-wrap">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  v-if="item.ikoni_ja_vari"
                  :icon="item.ikoni_ja_vari.ikoni"
                  :size="tiivisNakyma ? 'small' : 'medium'"
                  :hover-icon="maaritaHoverIcon(item)"
                  :bg-color="item.ikoni_ja_vari.vari"
                  :event-listener="on"
                  @click="maaritaClickHandler(item)"
                />
              </template>
              <template v-slot:default>
                <span>{{ maaritaStatusIconTooltipTeksti(item) }}</span>
              </template>
            </v-tooltip>
          </td>
          <td
            v-if="eiOikeuttaAvataAsiakasta(item) && !trafiLupaNaytaVainNimi"
            class="max-width font-italic"
          >
            Ei voida näyttää tietosuojasyistä
          </td>
          <td
            v-else
            class="max-width"
          >
            {{ item.nimi }}
          </td>
          <td class="text-no-wrap">
            {{ item.nro }}
          </td>
          <td class="text-no-wrap">
            {{ item.id }}
          </td>
          <td
            v-if="eiOikeuttaAvataAsiakasta(item)"
            class="text-no-wrap font-italic"
          >
            Ei voida näyttää tietosuojasyistä
          </td>
          <td
            v-else
            class="text-no-wrap"
          >
            {{ item.tunnus }}
          </td>
          <td class="text-no-wrap">
            {{ item.tyyppi }}
          </td>
          <td class="text-no-wrap">
            {{ item.paamies.nimi }} ({{ item.paamies.id }})
          </td>
        </TableRow>
      </template>
    </v-data-table>

    <TyhjaTaulukkoOtsikko v-if="tyhjaTaulukko"
      :otsikko="$t('youHaveNoCustomers')"
    >
    </TyhjaTaulukkoOtsikko>
    <ToolbarTyhjaTaulukko v-if="tyhjaTaulukko"
      ikoni="library_add"
      taustavari="success"
      :teksti="$t('newCustomer')"
      :infoteksti="$t('noCustomerAddInfo')"
      :naytaAina="true"
      @click="avaaDialogAsiakas"
    >
    </ToolbarTyhjaTaulukko>
    <!-- Pagination -->
    <v-toolbar
      v-if="(pagesTotal > 1)"
      class="py-6 transparent"
      flat
    >
      <v-spacer />
      <Pagination
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
      />
      <v-spacer />
    </v-toolbar>

    <!-- Dialog: Uusi asiakas -->
    <CardDialog
      title="Uusi asiakas"
      :dialog-auki="asiakasDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="1024"
      @close="(asiakasDialogAuki = false)"
    >
      <FormAsiakas
        :kenttien-valinnat="kenttienValinnat"
        :on-auki="asiakasDialogAuki"
        @close="(asiakasDialogAuki = false)"
        @asiakasTallennettu="siirryAsiakasnakymaan"
      />
      <v-divider class="mt-8 mb-8" />
    </CardDialog>

    <!-- Välilehti-dialog -->
    <MenuContext
      :onko-nakyvissa="menuContextNakyvissa"
      :position-x="menuContextX"
      :position-y="menuContextY"
      :vaihtoehdot="menuContextVaihtoehdot"
      @avaaValilehteen="avaaUudessaValilehdessa"
      @update:return-value="suljeMenuContext"
    />

    <!-- Dialog: Tuo csv-tiedostosta -->
    <CardDialog
      title="Tuo CSV-tiedostosta"
      :dialog-auki="tuoCSVDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="600"
      :tooltip-info-teksti="AsiakasCSVInfo"
      @close="tuoCSVDialogAuki = false"
    >
      <FormAsiakasCSV
        :on-auki="tuoCSVDialogAuki"
        @close="tuoCSVDialogAuki = false"
        @asiakkaita-tallennettu="resetoiJaAsetaSearchQuery('')"
      />
    </CardDialog>
  </div>
</template>

<script>

import { Asiakas } from '@/class/Asiakas'
import {
  FormAsiakas,
  FormAsiakasCSV,
  FormSearchVastapuoli,
  InfoValittuValilehti,
  OffCanvasAsiakas,
  Pagination,
  ToolbarTyhjaTaulukko,
  TyhjaTaulukkoOtsikko,
  TransitionSlidePanel,
  ViewSelaaTabs,
  ViewSelaaToolbar,
  ViewSelaaTopBarBase,
} from '@/components'
import TrafiLupaMixin from '@/mixins/TrafiLupaMixin'
import ViewSelaaMixin from '@/mixins/ViewSelaaMixin'
import { TableHeadersAsiakas } from '@/utils/tableHeaders'


export default {
  name: 'LaskutusAsiakasSelaa',
  components: {
    FormAsiakas,
    FormAsiakasCSV,
    FormSearchVastapuoli,
    InfoValittuValilehti,
    OffCanvasAsiakas,
    Pagination,
    TransitionSlidePanel,
    ViewSelaaTabs,
    ViewSelaaToolbar,
    ViewSelaaTopBarBase,
    ToolbarTyhjaTaulukko,
    TyhjaTaulukkoOtsikko,
  },
  mixins: [
    TrafiLupaMixin,
    ViewSelaaMixin,
  ],
  data () {
    return {
      asiakasDialogAuki: false,
      kenttienValinnat: {},
      tableHeaders: TableHeadersAsiakas,
      menuContextValilehtiRouteName: 'laskutus-asiakas-nayta',
      csvLomake: { csv: null },
      lataaTiedostoa: false,
      naytaKentat: false,
      tuoCSVDialogAuki: false,
    }
  },
  computed: {
    AsiakasCSVInfo () {
      return this.$t('infotekstit.asiakasCSVInfoTeksti')
    },
    csvLabels () {
      return {
        nimi: { title: 'Asiakkaan nimi' },
        nro: { title: 'Asiakasnumero'  },
        id: { title: this.$sovellusIdOtsake },
        tunnus: { title: 'Tunnus' },
        tyyppi: { title: 'Tyyppi' },
        ...(!this.vainYksiPaamies && { paamies: { title: 'Päämies' } }),
      }
    },
    csvData () {
      if (!Array.isArray(this.items)) return []

      return this.items.map(item => {
        return {
          nimi: this.eiOikeuttaAvataAsiakasta(item) && !this.$trafiLupaNaytaVainNimi ? 'Ei voida näyttää tietosuojasyistä' : item.nimi,
          nro: item.nro,
          id: item.id,
          tunnus: this.eiOikeuttaAvataAsiakasta(item) ? 'Ei voida näyttää tietosuojasyistä' : item.tunnus,
          tyyppi: item.tyyppi,
          ...(!this.vainYksiPaamies && { paamies: `${item.paamies.nimi} (${item.paamies.id})` }),
        }
      })
    },
    csvTitle () {
      return 'Asiakkaat_haku-' + this.$dateFnsFormat(new Date(), 'YYYY-MM-DD-HHmm-ss')
    },
  },
  watch: {
    asiakasDialogAuki (val) {
      if (val) {
        this.handleKeyPressDisabloitu = true
      } else {
        this.handleKeyPressDisabloitu = false
      }
    },
  },
  created () {
    this.haeAsiakkaanKenttienValinnat()
     if (this.$route.params.uusiAsiakas) {
      this.avaaDialogAsiakas()
    }
  },
  methods: {
    lisaaViewKohtaisetHaunSuodattimet (query) {
      if (this.searchTerms.nimi) query.append('nimi', this.searchTerms.nimi)
      if (this.searchTerms.nro) query.append('nro', this.searchTerms.nro)
      if (this.searchTerms.paamies) query.append('paamies', this.searchTerms.paamies)
      if (this.searchTerms.tunnus) query.append('tunnus', this.searchTerms.tunnus)
      if (this.searchTerms.email) query.append('email', this.searchTerms.email)
      if (this.searchTerms.puh) query.append('puh', this.searchTerms.puh)

      if (typeof this.searchTerms.tyyppi === 'object') {
        query = this.kasitteleMoniosuodatin(query, this.searchTerms.tyyppi)
      }

      return query
    },
    async haeAsiakkaanKenttienValinnat (asiakas_id = '') {
      this.kenttienValinnat = await Asiakas.haeAsiakkaanKenttienValinnat(asiakas_id)
    },
    siirryAsiakasnakymaan (asiakas) {
      this.asiakasDialogAuki = false
      this.$router.push({ name: 'laskutus-asiakas-nayta', params: { id: asiakas.id } })
    },
    avaaDialogAsiakas () {
      this.asiakasDialogAuki = true
    },
    maaritaHoverIcon (asiakas) {
      let hoverIcon = 'open-in-new'
      if (this.eiOikeuttaAvataAsiakasta(asiakas)) {
        hoverIcon = ''
      }
      return hoverIcon
    },
    maaritaClickHandler (asiakas) {
      if (this.eiOikeuttaAvataAsiakasta(asiakas)) {
        return () => {}
      } else {
        return this.$router.push({ name: 'laskutus-asiakas-nayta', params: { id: asiakas.id } })
      }
    },
    maaritaStatusIconTooltipTeksti (asiakas) {
      if (this.eiOikeuttaAvataAsiakasta(asiakas)) {
        return 'Ei voida näyttää asiakkaan tietoja'
      } else {
        return 'Avaa asiakkaan tiedot'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
