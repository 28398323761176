<template>
  <div class="laskutus-asiakas-nayta">
    <ViewTopBarBase
      :title="routeTitle"
    >
      <v-menu
        bottom
        left
        nudge-bottom="40"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="success"
            depressed
            v-on="on"
          >
            {{ $t('common:actions') }}
            <v-icon right>
              more_vert
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <v-list>
            <v-list-item
              @click="asiakasDialogAuki = true"
            >
              <v-list-item-avatar>
                <v-icon>edit</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t('common:editCustomer') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="avaaDialogMuistiinpanot"
            >
              <v-list-item-avatar>
                <v-icon>anitta-icon-note</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t('common:writeMemo') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </ViewTopBarBase>

    <Spinner v-if="tietueLataamassa" />
    <CardPahoittelu v-else-if="tietueLatausEpaonnistunut" />
    <template v-else>
      <v-container
        class="pa-2"
        fluid
      >
        <!-- Tiedot -->
        <div class="pa-4">
          <TietueenPerustiedotCard
            :paamies="laskutusAsiakasNayta.asiakas.paamies"
            :vastapuoli="laskutusAsiakasNayta.asiakas"
            ei-vastapuoli-linkkia
          >
            <template v-slot:col2>
              <p class="mb-0 font-weight-bold grey--text darken-2">
                {{ $t('common:customerData.customerInfo') }}
              </p>
              <DefList :bordered="(true)">
                <DefListItem
                  label-class="defList__label--emphasized"
                  value-class="font-weight-bold"
                >
                  <template v-slot:label>
                    {{ $t('common:customerNo') }}
                  </template>
                  <template v-slot:value>
                    {{ laskutusAsiakasNayta.asiakas.nro }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    {{ $sovellusIdOtsake }}
                  </template>
                  <template v-slot:value>
                    {{ laskutusAsiakasNayta.asiakas.id }}
                  </template>
                </DefListItem>
                <DefListItem v-if="laskutusAsiakasNayta.asiakas.tunnus">
                  <template v-slot:label>
                    {{ tunnustyyppi }}
                  </template>
                  <template v-slot:value>
                    {{ laskutusAsiakasNayta.asiakas.tunnus }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    {{ $t('common:language') }}
                  </template>
                  <template v-slot:value>
                    {{ $t( laskutusAsiakasNayta.asiakas.kieli ) }}
                  </template>
                </DefListItem>
                <DefListItem v-if="laskutusAsiakasNayta.asiakas.kotipaikka">
                  <template v-slot:label>
                    {{ $t('common:homeTown') }}
                  </template>
                  <template v-slot:value>
                    {{ laskutusAsiakasNayta.asiakas.kotipaikka }}
                  </template>
                </DefListItem>
              </DefList>
            </template>
            <template v-slot:col3>
              <p class="mb-0 font-weight-bold grey--text darken-2">
                {{ $t('common:contactInformation') }}
              </p>
              <DefList :bordered="(true)">
                <DefListItem>
                  <template v-slot:label>
                    {{ $t('common:phone') }}
                  </template>
                  <template v-slot:value>
                    <span v-if="laskutusAsiakasNayta.asiakas.puhelin">{{ laskutusAsiakasNayta.asiakas.puhelin.numero }}</span>
                  </template>
                </DefListItem>
                <DefListItem
                  value-class="text-wrap break-all"
                >
                  <template v-slot:label>
                    {{ $t('common:email') }}
                  </template>
                  <!-- Pitää käyttää asiakasKaare koska vain se sisältää emailosoite_set. Näytetään maksimissaan neljä osoitetta. -->
                  <template
                    v-if="asiakasKaare.asiakas.emailosoite_set"
                    v-slot:value
                  >
                    <template
                      v-for="(email, idx) in asiakasKaare.asiakas.emailosoite_set.slice(0, 4)"
                    >
                      <p
                        v-if="email.email"
                        :key="idx"
                        class="mb-0"
                      >
                        {{ email.email }}
                      </p>
                    </template>
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    {{ $t('common:eInvoiceAddress') }}
                  </template>
                  <template
                    v-if="laskutusAsiakasNayta.asiakas.verkkolaskuosoite && laskutusAsiakasNayta.asiakas.verkkolaskuosoite.verkkolaskuosoite"
                    v-slot:value
                  >
                    {{ laskutusAsiakasNayta.asiakas.verkkolaskuosoite.verkkolaskuosoite }}
                  </template>
                </DefListItem>
                <DefListItem v-if="laskutusAsiakasNayta.asiakas.verkkolaskuosoite && laskutusAsiakasNayta.asiakas.verkkolaskuosoite.operaattori">
                  <template v-slot:label>
                    {{ $t('common:eInvoiceOperator') }}
                  </template>
                  <template v-slot:value>
                    {{ laskutusAsiakasNayta.asiakas.verkkolaskuosoite.operaattori }}
                  </template>
                </DefListItem>
              </DefList>
            </template>
            <template v-slot:col4>
              <p class="mb-0 font-weight-bold grey--text darken-2">
                {{ $t('common:invoiceSendInfo') }}
              </p>
              <DefList :bordered="(true)">
                <DefListItem v-if="laskutusAsiakasNayta.asiakas.lahetystapa">
                  <template v-slot:label>
                    {{ $t('common:sendMethod') }}
                  </template>
                  <template v-slot:value>
                    {{ laskutusAsiakasNayta.asiakas.lahetystapa_display }}
                  </template>
                </DefListItem>
                <DefListItem v-if="laskutusAsiakasNayta.asiakas.toimitustapa">
                  <template v-slot:label>
                    {{ $t('common:deliveryMethod') }}
                  </template>
                  <template v-slot:value>
                    {{ laskutusAsiakasNayta.asiakas.oletustoimitustapa }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    {{ $t('common:termsOfPayment') }}
                  </template>
                  <template v-slot:value>
                    {{ laskutusAsiakasNayta.asiakas.oletusmaksuehto }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    {{ $t('common:ourReference') }}
                  </template>
                  <template
                    v-if="laskutusAsiakasNayta.asiakas.oletusviitteemme"
                    v-slot:value
                  >
                    {{ laskutusAsiakasNayta.asiakas.oletusviitteemme }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    {{ $t('common:yourReference') }}
                  </template>
                  <template
                    v-if="laskutusAsiakasNayta.asiakas.oletusviitteenne"
                    v-slot:value
                  >
                    {{ laskutusAsiakasNayta.asiakas.oletusviitteenne }}
                  </template>
                </DefListItem>
                <DefListItem
                  v-if="laskutusAsiakasNayta.asiakas.oletusvapaateksti"
                  label-class="text-wrap"
                  value-class="text-wrap"
                >
                  <template v-slot:label>
                    {{ $t('common:invoiceText') }}
                  </template>
                  <template v-slot:value>
                    <PerustietoTooltip :teksti="laskutusAsiakasNayta.asiakas.oletusvapaateksti" />
                  </template>
                </DefListItem>
              </DefList>
            </template>
          </TietueenPerustiedotCard>
        </div>
        <div class="pa-4">
          <v-row>
            <!-- Left column -->
            <v-col
              cols="12"
              lg="8"
            >
              <v-tabs
                v-model="selectedTab"
                background-color="primary"
                slider-color="grey lighten-5"
                centered
              >
                <v-tab
                  v-for="item in tabs"
                  :key="item.index"
                  :href="`#${item.id}`"
                  :ripple="false"
                >
                  {{ item.label }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="selectedTab">

              <!-- Laskut -->
              <div class="pb-4" v-show="selectedTab === 'laskut'">
                <v-card class="pa-6">
                  <CardTitle :text="$t('common:invoices')" />
                  <v-data-table
                    :items="laskuItems"
                    :headers="tableHeadersAsiakasLaskut"
                    :hide-default-footer="laskuItems.length <= 5"
                    :items-per-page="5"
                    :loading="lataaLaskuja"
                    :no-data-text="$t('common:noInvoices')"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <TableRow
                        custom-contextmenu
                        @contextmenu.prevent="avaaMenuContext($event, item, 'laskutus-nayta')"
                      >
                        <td class="text-no-wrap">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <StatusIcon
                                v-if="item.ikoni_ja_vari"
                                :icon="item.ikoni_ja_vari.ikoni"
                                size="small"
                                :hover-icon="('open-in-new')"
                                :bg-color="item.ikoni_ja_vari.vari"
                                :event-listener="on"
                                @click="$router.push({ name: 'laskutus-nayta', params: { id: String(item.id) } })"
                              />
                            </template>
                            <template v-slot:default>
                              <span>{{ $t('common:openInvoiceData') }}</span>
                            </template>
                          </v-tooltip>
                        </td>
                        <td class="text-no-wrap">
                          <router-link
                            :to="{ name: 'laskutus-nayta', params: { id: item.id } }"
                          >
                            {{ item.nro }}
                          </router-link>
                        </td>
                        <td class="max-width">
                          {{ item.tila }}
                        </td>
                        <td
                          v-if="sopimusItems.length"
                          class="text-no-wrap"
                        >
                          {{ item.sopimus }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $date(item.laskupaiva) }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $date(item.erapaiva) }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.summa | formatSumToFixed2 }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.veroton | formatSumToFixed2 }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.avoinna | formatSumToFixed2 }}
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>

              <div class="py-4" v-show="selectedTab === 'tapahtumat'">
                <v-card class="pa-6">
                  <CardTitle text="Tapahtumat" />

                  <v-data-table
                    :items="kirjauksetItems"
                    :headers="tableHeadersKirjaukset"
                    :hide-default-footer="kirjauksetItems.length <= 5"
                    :no-data-text="$t('common:noTransactions')"
                    :dense="user.tiivisNakyma"
                    :items-per-page="5"
                  >
                    <template v-slot:item="{ item }">
                      <TableRow
                        :clickable="(true)"
                        :data="item"
                        @click="toggleOpenRow(item.id)"
                      >
                        <td class="text-no-wrap">
                          <v-icon v-if="kirjauksetExpanded.get(item.id)">
                            expand_more
                          </v-icon>
                          <v-icon v-else-if="!kirjauksetExpanded.get(item.id) && item.kirjaukset && item.kirjaukset.length > 0">
                            chevron_right
                          </v-icon>
                        </td>
                        <td class="text-no-wrap">
                          {{ item.laskunumero }}
                        </td>
                        <td class="max-width">
                          {{ item.lahde }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $date(item.maksupaiva) }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $date(item.kirjauspaiva) }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.viite }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.viesti }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.summa | formatSumToFixed2 }}
                        </td>
                      </TableRow>
                      <template v-if="tableKirjauksetOpenRows.includes(item.id) && item.kirjaukset">
                        <TableRow
                          v-for="kirjaus in item.kirjaukset"
                          :key="kirjaus.id"
                          :sub-row="(true)"
                        >
                          <td class="text-no-wrap">
                          </td>
                          <td class="text-no-wrap">
                            <RouterLinkEhdollinen
                              v-if="kirjaus.toimeksianto_id > 0"
                              :ehto="Boolean(kirjaus.toimeksianto_id)"
                             :to="{ name: 'perinta-nayta', params: { id: kirjaus.toimeksianto_id} }"
                              custom-contextmenu
                              @contextmenu.prevent="avaaMenuContext($event, { id: kirjaus.toimeksianto_id }, 'perinta-nayta')"
                            >
                              Toimeksianto {{ kirjaus.toimeksianto_id }}
                            </RouterLinkEhdollinen>
                          </td>
                          <td class="max-width">
                            <RouterLinkEhdollinen
                              v-if="kirjaus.lahde && kirjaus.lasku_id > 0"
                              :ehto="Boolean(kirjaus.lasku_id)"
                             :to="{ name: 'laskutus-nayta', params: { id: kirjaus.lasku_id} }"
                              custom-contextmenu
                              @contextmenu.prevent="avaaMenuContext($event, { id: kirjaus.lasku_id }, 'laskutus-nayta')"
                            >
                              {{ kirjaus.lahde }} / Lasku {{ kirjaus.laskunumero }}
                            </RouterLinkEhdollinen>
                            <span v-else-if="kirjaus.lahde">{{ kirjaus.lahde }}</span>
                            <RouterLinkEhdollinen
                              v-else-if="kirjaus.lasku_id > 0"
                              :ehto="Boolean(kirjaus.lasku_id)"
                             :to="{ name: 'laskutus-nayta', params: { id: kirjaus.lasku_id} }"
                              custom-contextmenu
                              @contextmenu.prevent="avaaMenuContext($event, { id: kirjaus.lasku_id }, 'laskutus-nayta')"
                            >
                              Lasku {{ kirjaus.laskunumero }}
                            </RouterLinkEhdollinen>
                            <span v-else>{{ kirjaus.laskunumero }}</span>
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ $date(kirjaus.maksupaiva) }}
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ $date(kirjaus.kirjauspaiva) }}
                          </td>
                          <td class="text-no-wrap">
                          </td>
                          <td class="text-no-wrap">
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ kirjaus.summa | formatSumToFixed2 }}
                          </td>
                        </TableRow>
                      </template>
                    </template>
                  </v-data-table>
                </v-card>
              </div>

              <!-- Sopimukset -->
              <div
                v-if="sopimusItems.length"
                v-show="selectedTab === 'sopimukset'"
                class="py-4"
              >
                <v-card class="pa-6">
                  <CardTitle :text="$t('common:contracts')" />

                  <v-data-table
                    :items="sopimusItems"
                    :headers="tableHeadersAsiakasSopimukset"
                    :hide-default-footer="sopimusItems.length <= 5"
                    :items-per-page="5"
                    :no-data-text="$t('common:noContracts')"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <TableRow>
                        <td class="text-no-wrap">
                          <RouterLinkEhdollinen
                            :ehto="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_sopimus')"
                            :to="{ name: 'sopimus-nayta', params: { id: item.id } }"
                            custom-contextmenu
                            @contextmenu.prevent="avaaMenuContext($event, { id: item.id }, 'sopimus-nayta')"
                          >
                            {{ item.nro }}
                          </RouterLinkEhdollinen>
                        </td>
                        <td class="text-no-wrap ">
                          {{ item.tila }}
                        </td>
                        <td class="text-no-wrap">
                          {{ item.voimassa }}
                        </td>
                        <td class="max-width">
                          {{ item.tyyppi }}
                        </td>
                        <td class="max-width">
                          {{ item.laji }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.avoinna | formatSumToFixed2 }}
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>

              <!-- Muistiinpanot -->
              <div
                v-if="muistiinpanoItems.length"
                v-show="selectedTab === 'muistiinpanot'"
                class="py-4"
              >
                <v-card class="pa-6">
                  <CardTitle :text="$t('common:notes')" />

                  <v-data-table
                    :items="muistiinpanoItems"
                    :headers="tableHeadersAsiakasMuistiinpanot"
                    :hide-default-footer="muistiinpanoItems.length <= 5"
                    :items-per-page="5"
                    :no-data-text="$t('common:noNotes')"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <TableRow>
                        <td>
                          {{ item.lahettaja }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $date(item.aika, 'D.M.YYYY HH:mm') }}
                        </td>
                        <td class="text-no-wrap text-right">
                          <span
                            v-if="item.lasku"
                            @contextmenu.prevent="avaaMenuContext($event, { id: item.lasku.id }, 'laskutus-nayta')"
                          >
                            <router-link
                              :to="{ name: 'laskutus-nayta', params: { id: item.lasku.id } }"
                            >
                              {{ $t('common:invoice') }} {{ item.lasku.nro }}
                            </router-link>
                          </span>
                        </td>
                        <td class="text-wrap max-width">
                          {{ item.sisalto }}
                        </td>
                        <td>
                          <div class="d-flex flex-row">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <div v-on="on">
                                  <v-btn
                                    text
                                    icon
                                    small
                                    :disabled="!item.muokattavissa"
                                    @click="avaaDialogMuistiinpanoMuokkaa(item)"
                                  >
                                    <v-icon small>
                                      edit
                                    </v-icon>
                                  </v-btn>
                                </div>
                              </template>
                              <template
                                v-if="item.muokattavissa"
                                v-slot:default
                              >
                                <span>{{ $t('common:editNote') }}</span>
                              </template>
                              <template
                                v-else
                                v-slot:default
                              >
                                <span>{{ $t('common:customerData.noteNotEditable') }}</span>
                              </template>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <div v-on="on">
                                  <v-btn
                                    text
                                    icon
                                    small
                                    :disabled="!item.muokattavissa"
                                    @click="poistaMuistiinpano(item.id)"
                                  >
                                    <v-icon small>
                                      delete
                                    </v-icon>
                                  </v-btn>
                                </div>
                              </template>
                              <template
                                v-if="item.muokattavissa"
                                v-slot:default
                              >
                                <span>{{ $t('common:deleteNote') }}</span>
                              </template>
                              <template
                                v-else
                                v-slot:default
                              >
                                <span>{{ $t('common:customerData.noteNotDeletable') }}</span>
                              </template>
                            </v-tooltip>
                          </div>
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>

              <!-- Viestit -->
              <div
                v-if="viestiItems.length"
                v-show="selectedTab === 'viestit'"
                class="py-4"
              >
                <v-card class="pa-6">
                  <CardTitle :text="$t('common:messages')" />

                  <v-data-table
                    :items="viestiItems"
                    :headers="tableHeadersAsiakasViestit"
                    :hide-default-footer="viestiItems.length <= 5"
                    :items-per-page="5"
                    :no-data-text="$t('common:noMessages')"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <TableRow>
                        <td>
                          {{ item.lahettaja }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $date(item.aika, 'D.M.YYYY HH:mm') }}
                        </td>
                        <td class="text-no-wrap text-right">
                          <span
                            v-if="item.lasku"
                            @contextmenu.prevent="avaaMenuContext($event, { id: item.lasku.id }, 'laskutus-nayta')"
                          >
                            <router-link :to="{ name: 'laskutus-nayta', params: { id: item.lasku.id } }">
                              {{ $t('common:invoice') }} {{ item.lasku.nro }}
                            </router-link>
                          </span>
                        </td>
                        <td class="text-wrap max-width">
                          <span :class="item.luettu === '–' ? 'font-weight-bold' : ''">{{ item.sisalto }}</span>
                        </td>
                        <td class="text-no-wrap">
                          {{ item.luettu }}
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>
            </v-tabs-items>
            </v-col>

            <!-- Right column -->
            <v-col
              cols="12"
              lg="4"
            >
              <!-- Muut tiedot -->
              <v-card class="pa-6">
                <CardTitle text="Muut tiedot" />

                <AsiakasLaskutuksenYhteenveto
                  title-class="mt-4"
                  :asiakas="laskutusAsiakasNayta.asiakas"
                />

                <!-- Maksutiedot -->
                <template v-if="laskutusAsiakasNayta.asiakas.iban">
                  <h3 class="mt-4 mb-2">
                    Maksutiedot
                  </h3>
                  <DefList class="pa-0">
                    <DefListItem>
                      <template v-slot:label>
                        IBAN
                      </template>
                      <template v-slot:value>
                        {{ laskutusAsiakasNayta.asiakas.iban }}
                      </template>
                    </DefListItem>
                    <DefListItem>
                      <template v-slot:label>
                        BIC
                      </template>
                      <template v-slot:value>
                        {{ laskutusAsiakasNayta.asiakas.bic }}
                      </template>
                    </DefListItem>
                  </DefList>
                </template>

                <!-- Asiakasrekisterit -->
                <template v-if="asiakasrekisteriItems.length">
                  <h3 class="my-2">
                    Asiakasrekisterit
                  </h3>
                  <v-data-table
                    :items="asiakasrekisteriItems"
                    :headers="tableHeadersAsiakasAsiakasrekisteri"
                    :hide-default-footer="asiakasrekisteriItems.length <= 5"
                    :items-per-page="5"
                    no-data-text="Ei asiakasrekistereitä"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <TableRow>
                        <td class="text-no-wrap">
                          {{ item.nro }}
                        </td>
                        <td class="text-wrap">
                          {{ item.nimi }}
                        </td>
                        <td class="text-wrap">
                          {{ item.ryhma }}
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </template>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </template>

    <!-- Dialog: Muistiinpanot -->
    <CardDialog
      title="Asiakaskohtaiset muistiinpanot"
      :dialog-auki="muistiinpanotDialogAuki"
      :scroll-to-bottom="(true)"
      content-class="px-4 py-2"
      @close="(muistiinpanotDialogAuki = false)"
    >
      <template v-slot:default>
        <Timeline
          v-if="muistiinpanotAikajana.length"
          class="px-4"
          :items="muistiinpanotAikajana"
          :show-title="(false)"
          :show-item-titles="(false)"
          :show-dates="(true)"
          :enable-zoom="(false)"
          :enable-direction-toggle="(false)"
          :salli-muistiinpanojen-muokkaus="false"
        />
      </template>
      <template v-slot:footer>
        <FormUusiViesti
          v-model="laskutusAsiakasNayta.uusiMuistiinpanoFormData"
          :loading="uusiMuistiinpanoFormLoading"
          :focus-on-update="(true)"
          icon="note"
          msg-input-placeholder="Uusi muistiinpano"
          submit-btn-tooltip-text="Kirjoita muistiinpano"
          painikkeet
          @submit="muistiinpanoSubmit"
          @close="(muistiinpanotDialogAuki = false)"
          :peruuta-teksti="$t('common:close')"
          :submit-teksti="$t('common:save')"
        />
      </template>
    </CardDialog>

    <!-- Dialog: Muokkaa asiakasta -->
    <CardDialog
      title="Muokkaa asiakasta"
      :dialog-auki="asiakasDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="1024"
      @close="(asiakasDialogAuki = false)"
    >
      <FormAsiakas
        :kaare-objekti="asiakasKaare"
        :kenttien-valinnat="laskutusAsiakasNayta.kenttienValinnat"
        :on-auki="asiakasDialogAuki"
        @close="(asiakasDialogAuki = false)"
        @asiakasTallennettu="kasittelePaivitys"
      />
    </CardDialog>

    <!-- Dialog: Muokkaa muistiinpanoa -->
    <CardDialog
      title="Muokkaa muistiinpanoa"
      :dialog-auki="muistiinpanoMuokkaaDialogAuki"
      :scroll-to-bottom="(true)"
      content-class="px-4 py-2"
      @close="(muistiinpanoMuokkaaDialogAuki = false)"
    >
      <template v-slot:footer>
        <FormUusiViesti
          v-model="laskutusAsiakasNayta.muistiinpanoMuokkaaFormData"
          :loading="muistiinpanoMuokkaaFormLoading"
          :focus-on-update="(true)"
          icon="note"
          submit-btn-tooltip-text="Tallenna"
          @submit="muistiinpanoMuokkaaSubmit"
        />
      </template>
    </CardDialog>

    <!-- Dialog: Välilehti -->
    <MenuContext
      :onko-nakyvissa="menuContextNakyvissa"
      :position-x="menuContextX"
      :position-y="menuContextY"
      :vaihtoehdot="menuContextVaihtoehdot"
      @avaaValilehteen="avaaUudessaValilehdessa"
      @update:return-value="suljeMenuContext"
    />
  </div>
</template>

<script>

import { Decimal } from 'decimal.js'
import { mapState } from 'vuex'
import { Asiakas } from '@/class/Asiakas'
import {
  AsiakasLaskutuksenYhteenveto,
  FormAsiakas,
  FormUusiViesti,
  TietueenPerustiedotCard,
  Timeline,
} from '@/components'
import MenuContextMixin from '@/mixins/MenuContextMixin'
import TrafiLupaMixin from '@/mixins/TrafiLupaMixin'
import {
  TableHeadersAsiakasAsiakasrekisteri,
  TableHeadersVastapuoliLaskut,
  TableHeadersAsiakasLaskutSopimuksella,
  TableHeadersVastapuoliMuistiinpanot,
  TableHeadersAsiakasSopimukset,
  TableHeadersVastapuoliKirjaukset,
  TableHeadersVastapuoliViestit,
  TableHeadersAsiakasTapahtumat,
  TableHeadersKirjauksetAsiakas,
} from '@/utils/tableHeaders'

export default {
  name: 'AsiakasNayta',
  components: {
    AsiakasLaskutuksenYhteenveto,
    FormAsiakas,
    FormUusiViesti,
    TietueenPerustiedotCard,
    Timeline,
  },
  mixins: [
    MenuContextMixin,
    TrafiLupaMixin,
  ],
  data () {
    return {
      asiakasDialogAuki: false,
      laskuItems: [],
      kirjauksetItems: [],
      tapahtumaItems: [],
      lataaLaskuja: false,
      tietueLataamassa: false,
      tietueLatausEpaonnistunut: false,
      menuContextValilehtiRouteName: 'laskutus-nayta',
      muistiinpanoMuokkaaDialogAuki: false,
      muistiinpanoMuokkaaFormLoading: false,
      muistiinpanotDialogAuki: false,
      tableHeadersAsiakasAsiakasrekisteri: TableHeadersAsiakasAsiakasrekisteri,
      tableHeadersAsiakasMuistiinpanot: TableHeadersVastapuoliMuistiinpanot,
      tableHeadersAsiakasSopimukset: TableHeadersAsiakasSopimukset,
      tableHeadersAsiakasViestit: TableHeadersVastapuoliViestit,
      tableHeadersAsiakasTapahtumat: TableHeadersAsiakasTapahtumat,
      tableHeadersKirjaukset: TableHeadersKirjauksetAsiakas || [],
      tableKirjauksetOpenRows: [],
      uusiMuistiinpanoFormLoading: false,
      laskuNroId: new Map(),
      kirjauksetExpanded: new Map(),
      selectedTab: "laskut",
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      laskutusAsiakasNayta: state => state.laskutusAsiakasNayta,
    }),
    asiakasKaare () {
      return {
        asiakas: new Asiakas(this.laskutusAsiakasNayta.asiakas)
      }
    },
    asiakasrekisteriItems () {
      if (!this.laskutusAsiakasNayta.asiakas.asiakasryhmaasiakasrekisteri_set) return []

      return this.laskutusAsiakasNayta.asiakas.asiakasryhmaasiakasrekisteri_set.map(item => {
        return {
          nro: item.asiakasrekisteri.nro,
          nimi: item.asiakasrekisteri.nimi,
          ryhma: item.ryhma,
        }
      })
    },
    muistiinpanotAikajana () {
      return this.laskutusAsiakasNayta.asiakas.muistiinpanot_aikajana ? this.laskutusAsiakasNayta.asiakas.muistiinpanot_aikajana : []
    },
    muistiinpanoItems () {
      if (!this.laskutusAsiakasNayta.asiakas.muistiinpano_set) return []

      const muistiinpanot = this.laskutusAsiakasNayta.asiakas.muistiinpano_set.filter(item => {
        return !item.paamiesviesti
      })

      return muistiinpanot.map(item => {
        return {
          id: item.id,
          lahettaja: item.lahettaja.username,
          aika: item.aika,
          lasku: item.lasku,
          toimeksianto: item.toimeksianto,
          sisalto: item.data,
          muokattavissa: !item.lasku && !item.toimeksianto
        }
      })
    },
    routeTitle () {
      if (this.laskutusAsiakasNayta.asiakas.nimi) {
        return this.laskutusAsiakasNayta.asiakas.nimi
      } else {
        return this.$route.meta.title
      }
    },
    sopimusItems () {
      if (!this.laskutusAsiakasNayta.asiakas.sopimus_set) return []

      return this.laskutusAsiakasNayta.asiakas.sopimus_set.map(item => {
        let voimassa = `${this.$date(item.voimassa.alkamispaiva, 'D.M.YYYY')} – `
        voimassa += item.voimassa.loppumispaiva ? this.$date(item.voimassaloppumispaiva, 'D.M.YYYY') : 'Toistaiseksi'
        item.tila = item.tila.selite

        return {
          ...item,
          voimassa,
        }
      })
    },
    tableHeadersAsiakasLaskut () {
      let headers = TableHeadersVastapuoliLaskut
      if (this.laskuItems.some(lasku => lasku.sopimus)) headers = TableHeadersAsiakasLaskutSopimuksella
      return headers
    },
    tunnustyyppi () {
      if (!this.laskutusAsiakasNayta.asiakas.tyyppi) return null

      if (this.laskutusAsiakasNayta.asiakas.tyyppi == 'H') {
        return 'Hetu'
      } else if (this.laskutusAsiakasNayta.asiakas.tyyppi == 'Y') {
        return 'Y-tunnus'
      } else {
        return 'Tunnus'
      }
    },
    vapaatekstiOnPitka () {
      return this.laskutusAsiakasNayta.asiakas.oletusvapaateksti.length > 100
    },
    viestiItems () {
      if (!this.laskutusAsiakasNayta.asiakas.muistiinpano_set) return []

      const viestit = this.laskutusAsiakasNayta.asiakas.muistiinpano_set.filter(item => {
        return item.paamiesviesti
      })

      return viestit.map(item => {
        return {
          lahettaja: item.lahettaja.username,
          aika: item.aika,
          lasku: item.lasku,
          sisalto: item.data,
          luettu: item.luettu ? this.$date(item.aika, 'D.M.YYYY HH:mm') : '–'
        }
      })
    },
    tabs() {
      let tabs = [
        {
          id: "laskut",
          label: this.$t('invoices')
        }
      ]
      tabs.push({
        id: "tapahtumat",
        label: this.$t('transactionsTrans')
      })
      tabs.push({
        id: "sopimukset",
        label: this.$t('common:contracts')
      })
      tabs.push({
        id: "muistiinpanot",
        label: this.$t('common:notes')
      })
      tabs.push({
        id: "viestit",
        label: this.$t('common:messages')
      })
      return tabs
    },
  },
  async mounted () {
    await this.loadItem()
    await this.haeAsiakkaanKenttienValinnat()
    await this.haeAsiakkaanLaskut()
    this.setKirjauksetItems()
  },
  methods: {
    showKirjauksetKulu () {
      return this.kirjauksetItems.some(item => {
        return item.kulu || item.kirjaukset.some(kirjaus => kirjaus.kulu)
      })
    },

    showKirjauksetViivkorko () {
      return this.kirjauksetItems.some(item => {
        return item.viivkorko || item.kirjaukset.some(kirjaus => kirjaus.viivkorko)
      })
    },
    kasittelePaivitys (asiakas) {
      this.$store.commit('laskutusAsiakasNayta/setAsiakas', asiakas)

      this.$naytaOnnistumisilmoitus('Asiakkaan tiedot päivitetty!')
    },
    async loadItem (silent = false) {
      try {
        if (!silent) this.tietueLataamassa = true
        await this.$store.dispatch('laskutusAsiakasNayta/loadItem', this.$route)
      } catch (e) {
        if (e.request && e.request.result && e.request.result.status === 404) {
          this.$naytaVirheilmoitus('Asiakasta ei löytynyt!')

          this.$router.replace({ name: 'error404' })
        } else {
          this.tietueLatausEpaonnistunut = false
          this.$oletusVirheenkasittely(e, 'Tietojen lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen.')
        }
      } finally {
        this.tietueLataamassa = false
      }
    },
    async muistiinpanoMuokkaaSubmit () {
      try {
        this.muistiinpanoMuokkaaFormLoading = true
        await this.$store.dispatch('laskutusAsiakasNayta/muistiinpanoMuokkaaSubmit', this.$route)
        this.muistiinpanoMuokkaaDialogAuki = false

        this.$naytaOnnistumisilmoitus('Muistiinpanon muokkaus tallennettu!')
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.muistiinpanoMuokkaaFormLoading = false
      }
    },
    async muistiinpanoSubmit () {
      try {
        this.uusiMuistiinpanoFormLoading = true
        await this.$store.dispatch('laskutusAsiakasNayta/muistiinpanoSubmit')
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.uusiMuistiinpanoFormLoading = false
      }
    },
    avaaDialogMuistiinpanoMuokkaa (muistiinpano) {
      this.muistiinpanoMuokkaaDialogAuki = true
      this.$store.dispatch('laskutusAsiakasNayta/alustaDialogMuistiinpanoMuokkaa', muistiinpano)
    },
    avaaDialogMuistiinpanot () {
      this.muistiinpanotDialogAuki = true
      this.$store.commit('laskutusAsiakasNayta/setUusiMuistiinpanoFormData', {})
    },
    async poistaMuistiinpano (id) {
      const result = await this.$swal({
        text: 'Haluatko varmasti poistaa muistiinpanon?',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: 'Poista muistiinpano',
        cancelButtonText: 'Peruuta',
      })

      if (!result.value) return

      try {
        this.$store.dispatch('laskutusAsiakasNayta/poistaMuistiinpano', { sroute: this.$route, id: id })
        this.$naytaOnnistumisilmoitus('Muistiinpano poistettu!')
      } catch(e) {
        this.$oletusVirheenkasittely(e)
      }
    },
    async haeAsiakkaanKenttienValinnat (asiakas_id = '') {
      this.$store.dispatch('laskutusAsiakasNayta/haeAsiakkaanKenttienValinnat', asiakas_id)
    },
    async haeAsiakkaanLaskut() {
      this.lataaLaskuja = true
      const response = await this.$doRequestWithTimeout(
        this.$api.AsiakasLaskut,
        {
          method: 'GET',
          params: {
            id: this.laskutusAsiakasNayta.asiakas.id,
            psize: 100,
          },
        },
      )
      try {
        if (!response.success) throw new this.$HttpError(response)

        this.laskuItems = response.result.body.results.map(lasku => ({
          ...lasku,
          tila: lasku.tila.selite,
        }))
      } catch(e) {
        this.$oletusVirheenkasittely(e, 'Asiakkaan laskujen hakeminen epäonnistui.')
      } finally {
        this.lataaLaskuja = false
      }
    },
    setKirjauksetItems () {
      let kirjausItem = {}
      let tapahtumatMap = new Map(), tapahtumaItem = {}
      this.laskuItems.forEach(lasku => {
        lasku.tapahtuma_set_laskukirjauksilla.forEach(tapahtuma => {
        let tapahtumaSumma = new Decimal(0.00)

        kirjausItem = {
          id: tapahtuma.id,
          laskunumero: '',
          lahde: tapahtuma.tilityyppi,
          maksupaiva: tapahtuma.maksupaiva,
          kirjauspaiva: tapahtuma.kirjauspaiva,
          viite: tapahtuma.viite,
          viesti: tapahtuma.viesti,
          kirjaukset: tapahtuma.kirjaus_set.map(kirjaus => {
              tapahtumaSumma = tapahtumaSumma.plus(kirjaus.summa)
              return {
                id: kirjaus.id,
                laskunumero: lasku.nro,
                lasku_id: lasku.id,
                toimeksianto_id: lasku.toimeksianto,
                lahde: "",
                maksupaiva: tapahtuma.maksupaiva,
                kirjauspaiva: kirjaus.kirjauspaiva,
                summa: kirjaus.summa,
              }
          }),
          summa: tapahtumaSumma,
        }
        tapahtumaItem = tapahtumatMap.get(tapahtuma.id)
        if (tapahtumaItem && tapahtumaItem.kirjaukset && kirjausItem && kirjausItem.kirjaukset) {
          tapahtumaItem.kirjaukset.push(...kirjausItem.kirjaukset)
          tapahtumaItem.summa = tapahtumaItem.summa.plus(kirjausItem.summa)
        } else {
          tapahtumaItem = kirjausItem
        }
        tapahtumatMap.set(tapahtuma.id, tapahtumaItem)
      })
      })
      if (tapahtumatMap && tapahtumatMap.values()) {
        for (const [key, tapahtumaMapItem] of tapahtumatMap) {
          this.kirjauksetItems.push(tapahtumaMapItem)
        }
      } else {
        this.kirjauksetItems = []
      }
    },
    toggleOpenRow (id = null) {
      if (!id) return

      const index = this.tableKirjauksetOpenRows.indexOf(id)

      if (index > -1) {
        this.kirjauksetExpanded.set(id, false)
        this.tableKirjauksetOpenRows.splice(index, 1)
      } else {
        this.kirjauksetExpanded.set(id, true)
        this.tableKirjauksetOpenRows.push(id)
      }
    }
  }
}

</script>

<style lang="scss" scoped>
</style>
