<template>
  <div class="laskutus-tuo">
    <v-toolbar
      class="v-toolbar--sticky pl-6"
      color="primary"
      flat
      tabs
    >
      <v-btn :to="{ name: 'laskutus-selaa' }" icon>
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title v-if="$route.meta.title">
        {{$route.meta.title}}
      </v-toolbar-title>
    </v-toolbar>
  </div>
</template>

<script>

export default {
  name: 'Laskutus-tuo',
}

</script>

<style lang="scss" scoped>

</style>
